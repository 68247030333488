import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const spanish_list = {
  1: { frequency: 1, spanish: "y", english: "and" },
  2: {
    frequency: 2,
    spanish: "de",
    english: "of • from • by • with • at • about • than",
  },
  3: { frequency: 3, spanish: "que", english: "than • what • that" },
  4: { frequency: 4, spanish: "no", english: "not • no" },
  5: { frequency: 5, spanish: "a", english: "to • at • of • into • by" },
  6: { frequency: 6, spanish: "la", english: "the • la" },
  7: { frequency: 7, spanish: "el", english: "the" },
  8: { frequency: 8, spanish: "es", english: "is" },
  9: {
    frequency: 9,
    spanish: "en",
    english: "in • on • at • to • into • for • by",
  },
  10: { frequency: 10, spanish: "lo", english: "the • it • him" },
  11: { frequency: 11, spanish: "un", english: "a • an • one" },
  12: { frequency: 12, spanish: "qué", english: "what" },
  13: { frequency: 13, spanish: "me", english: "me • myself" },
  14: { frequency: 14, spanish: "una", english: "a • an • one" },
  15: { frequency: 15, spanish: "te", english: "you • thee • yourself" },
  16: { frequency: 16, spanish: "se", english: "is" },
  17: { frequency: 17, spanish: "los", english: "the" },
  18: { frequency: 18, spanish: "con", english: "with • by" },
  19: { frequency: 19, spanish: "mi", english: "my • myself • mi" },
  20: { frequency: 20, spanish: "está", english: "this • this one" },
  21: { frequency: 21, spanish: "si", english: "if • whether" },
  22: {
    frequency: 22,
    spanish: "sí",
    english: "yes • yeah • yea • himself • herself",
  },
  23: { frequency: 23, spanish: "pero", english: "but" },
  24: { frequency: 24, spanish: "las", english: "the" },
  25: {
    frequency: 25,
    spanish: "bien",
    english: "nice • well • properly • nicely • good • right",
  },
  26: { frequency: 26, spanish: "yo", english: "I • self" },
  27: {
    frequency: 27,
    spanish: "su",
    english: "your • its • their • her • his",
  },
  28: { frequency: 28, spanish: "eso", english: "that • it" },
  29: { frequency: 29, spanish: "aquí", english: "here" },
  30: { frequency: 30, spanish: "del", english: "of the" },
  31: { frequency: 31, spanish: "al", english: "to the • at the" },
  32: { frequency: 32, spanish: "le", english: "you • him • it • her" },
  33: { frequency: 33, spanish: "tu", english: "your • thy" },
  34: {
    frequency: 34,
    spanish: "más",
    english: "other • else • more • plus • most",
  },
  35: {
    frequency: 35,
    spanish: "todo",
    english: "entire • every • whole • all",
  },
  36: { frequency: 36, spanish: "ya", english: "already • anymore" },
  37: {
    frequency: 37,
    spanish: "muy",
    english: "very • highly • too • much • most",
  },
  38: { frequency: 38, spanish: "esto", english: "this" },
  39: { frequency: 39, spanish: "ahora", english: "now" },
  40: { frequency: 40, spanish: "esta", english: "this • this one" },
  41: {
    frequency: 41,
    spanish: "hay",
    english: "there • there are • there is",
  },
  42: { frequency: 42, spanish: "estoy", english: "am" },
  43: {
    frequency: 43,
    spanish: "algo",
    english: "somewhat • rather • something • some • anything",
  },
  44: { frequency: 44, spanish: "tú", english: "you • thou" },
  45: { frequency: 45, spanish: "así", english: "so • thus • thereby" },
  46: {
    frequency: 46,
    spanish: "nada",
    english: "nothing • any • none • nothingness",
  },
  47: { frequency: 47, spanish: "nos", english: "us" },
  48: {
    frequency: 48,
    spanish: "cuando",
    english: "when • as • if • whenever",
  },
  49: { frequency: 49, spanish: "cómo", english: "how" },
  50: { frequency: 50, spanish: "él", english: "he" },
  51: { frequency: 51, spanish: "estás", english: "these" },
  52: {
    frequency: 52,
    spanish: "sólo",
    english: "only • alone • just • merely",
  },
  53: { frequency: 53, spanish: "o", english: "or" },
  54: { frequency: 54, spanish: "este", english: "this • this one • east" },
  55: { frequency: 55, spanish: "gracias", english: "thanks" },
  56: {
    frequency: 56,
    spanish: "bueno",
    english: "good • right • nice • kind",
  },
  57: { frequency: 57, spanish: "soy", english: "am" },
  58: { frequency: 58, spanish: "era", english: "era" },
  59: {
    frequency: 59,
    spanish: "todos",
    english: "every • all • everyone • everybody",
  },
  60: { frequency: 60, spanish: "ella", english: "she • her • it" },
  61: { frequency: 61, spanish: "eres", english: "you are" },
  62: { frequency: 62, spanish: "usted", english: "you" },
  63: { frequency: 63, spanish: "ese", english: "that" },
  64: { frequency: 64, spanish: "quién", english: "who • whom" },
  65: { frequency: 65, spanish: "porque", english: "because" },
  66: {
    frequency: 66,
    spanish: "tan",
    english: "so • such • that • this • very",
  },
  67: { frequency: 67, spanish: "hola", english: "Hello" },
  68: { frequency: 68, spanish: "dónde", english: "where" },
  69: { frequency: 69, spanish: "nunca", english: "never • ever" },
  70: {
    frequency: 70,
    spanish: "sus",
    english: "their • its • your • her • his",
  },
  71: { frequency: 71, spanish: "dos", english: "two" },
  72: { frequency: 72, spanish: "verdad", english: "truth" },
  73: { frequency: 73, spanish: "entonces", english: "then • now" },
  74: { frequency: 74, spanish: "tiempo", english: "time • weather • period" },
  75: { frequency: 75, spanish: "mí", english: "me • to me" },
  76: { frequency: 76, spanish: "esa", english: "that" },
  77: { frequency: 77, spanish: "hace", english: "ago" },
  78: { frequency: 78, spanish: "dios", english: "god • deity" },
  79: { frequency: 79, spanish: "también", english: "also • too • as well" },
  80: { frequency: 80, spanish: "vida", english: "life • living • lifetime" },
  81: { frequency: 81, spanish: "sin", english: "without" },
  82: { frequency: 82, spanish: "sr", english: "Mr ." },
  83: { frequency: 83, spanish: "siempre", english: "always • forever • ever" },
  84: { frequency: 84, spanish: "oh", english: "oh" },
  85: { frequency: 85, spanish: "ti", english: "you • thee" },
  86: { frequency: 86, spanish: "ahí", english: "there" },
  87: { frequency: 87, spanish: "ni", english: "or • nor • neither" },
  88: {
    frequency: 88,
    spanish: "sobre",
    english: "on • about • upon • above • concerning • onto • in",
  },
  89: { frequency: 89, spanish: "años", english: "us" },
  90: { frequency: 90, spanish: "uno", english: "one • you" },
  91: { frequency: 91, spanish: "día", english: "day" },
  92: { frequency: 92, spanish: "noche", english: "night • nighttime" },
  93: {
    frequency: 93,
    spanish: "alguien",
    english: "someone • anyone • somebody",
  },
  94: {
    frequency: 94,
    spanish: "antes",
    english: "before • above • previously • rather • sooner • formerly",
  },
  95: { frequency: 95, spanish: "mis", english: "my" },
  96: { frequency: 96, spanish: "poco", english: "short • few • little • bit" },
  97: { frequency: 97, spanish: "otra", english: "other" },
  98: {
    frequency: 98,
    spanish: "solo",
    english: "single • alone • lonely • one • all alone • solo",
  },
  99: {
    frequency: 99,
    spanish: "nadie",
    english: "nobody • no one • anyone • anybody • none",
  },
  100: { frequency: 100, spanish: "nosotros", english: "us • we" },
  101: { frequency: 101, spanish: "padre", english: "father • parent" },
  102: { frequency: 102, spanish: "gente", english: "people • folk" },
  103: { frequency: 103, spanish: "dinero", english: "money" },
  104: { frequency: 104, spanish: "hecho", english: "fact" },
  105: { frequency: 105, spanish: "les", english: "them" },
  106: { frequency: 106, spanish: "mismo", english: "same • very • one" },
  107: { frequency: 107, spanish: "ellos", english: "them • they" },
  108: { frequency: 108, spanish: "mañana", english: "morning • tomorrow" },
  109: {
    frequency: 109,
    spanish: "otro",
    english: "another one • other • another",
  },
  110: {
    frequency: 110,
    spanish: "después",
    english: "after • later • afterwards • afterward • then • next",
  },
  111: { frequency: 111, spanish: "desde", english: "from • since • from the" },
  112: { frequency: 112, spanish: "mundo", english: "world" },
  113: { frequency: 113, spanish: "tal", english: "such" },
  114: { frequency: 114, spanish: "donde", english: "wherein • as • where" },
  115: { frequency: 115, spanish: "fuera", english: "outside • out • away" },
  116: { frequency: 116, spanish: "hijo", english: "son • child" },
  117: { frequency: 117, spanish: "mujer", english: "woman" },
  118: {
    frequency: 118,
    spanish: "amigo",
    english: "friend • buddy • friendly",
  },
  119: { frequency: 119, spanish: "días", english: "day" },
  120: { frequency: 120, spanish: "madre", english: "mother • parent" },
  121: { frequency: 121, spanish: "allí", english: "there" },
  122: { frequency: 122, spanish: "tus", english: "your" },
  123: { frequency: 123, spanish: "gran", english: "great • large • big" },
  124: { frequency: 124, spanish: "tenía", english: "had" },
  125: {
    frequency: 125,
    spanish: "mamá",
    english: "mom • mum • mama • mummy • momma • mamma • mother",
  },
  126: { frequency: 126, spanish: "papá", english: "dad • daddy • papa" },
  127: { frequency: 127, spanish: "hoy", english: "today" },
  128: {
    frequency: 128,
    spanish: "buena",
    english: "good • right • nice • kind",
  },
  129: {
    frequency: 129,
    spanish: "nuevo",
    english: "new • fresh • novel • further",
  },
  130: { frequency: 130, spanish: "luego", english: "then • later" },
  131: { frequency: 131, spanish: "tres", english: "three" },
  132: { frequency: 132, spanish: "dije", english: "said" },
  133: { frequency: 133, spanish: "nuestro", english: "our" },
  134: {
    frequency: 134,
    spanish: "menos",
    english: "fewer • least • less • minus • but • except",
  },
  135: { frequency: 135, spanish: "buen", english: "good" },
  136: { frequency: 136, spanish: "conmigo", english: "with me" },
  137: {
    frequency: 137,
    spanish: "todas",
    english: "all • whole • entire • every",
  },
  138: {
    frequency: 138,
    spanish: "toda",
    english: "all • whole • entire • every",
  },
  139: { frequency: 139, spanish: "visto", english: "viewed" },
  140: { frequency: 140, spanish: "estas", english: "these" },
  141: { frequency: 141, spanish: "eh", english: "Hey!" },
  142: {
    frequency: 142,
    spanish: "tarde",
    english: "afternoon • evening • late",
  },
  143: { frequency: 143, spanish: "aún", english: "even • yet • still" },
  144: { frequency: 144, spanish: "nuestra", english: "our" },
  145: { frequency: 145, spanish: "tanto", english: "so • such" },
  146: { frequency: 146, spanish: "cada", english: "each • every" },
  147: { frequency: 147, spanish: "hora", english: "hour • time" },
  148: { frequency: 148, spanish: "veces", english: "times" },
  149: { frequency: 149, spanish: "contigo", english: "with you" },
  150: {
    frequency: 150,
    spanish: "buenas",
    english: "good • right • nice • kind",
  },
  151: { frequency: 151, spanish: "dicho", english: "saying" },
  152: { frequency: 152, spanish: "quien", english: "whoever" },
  153: {
    frequency: 153,
    spanish: "demasiado",
    english: "too • overly • too much",
  },
  154: { frequency: 154, spanish: "oye", english: "hears" },
  155: { frequency: 155, spanish: "ah", english: "ah" },
  156: { frequency: 156, spanish: "hombres", english: "men" },
  157: {
    frequency: 157,
    spanish: "entre",
    english: "between • among • amongst • within",
  },
  158: { frequency: 158, spanish: "adiós", english: "adieu" },
  159: { frequency: 159, spanish: "unos", english: "about • few • some" },
  160: { frequency: 160, spanish: "cierto", english: "certain • sure" },
  161: { frequency: 161, spanish: "alguna", english: "any" },
  162: { frequency: 162, spanish: "esos", english: "those" },
  163: { frequency: 163, spanish: "pues", english: "since • then • well" },
  164: { frequency: 164, spanish: "chica", english: "girl • gal" },
  165: {
    frequency: 165,
    spanish: "realmente",
    english: "really • truly • actually • real",
  },
  166: { frequency: 166, spanish: "estos", english: "these" },
  167: { frequency: 167, spanish: "amigos", english: "friend • buddy" },
  168: { frequency: 168, spanish: "ustedes", english: "you" },
  169: { frequency: 169, spanish: "quizá", english: "maybe" },
  170: {
    frequency: 170,
    spanish: "serio",
    english: "serious • earnest • severe",
  },
  171: { frequency: 171, spanish: "hermano", english: "brother • sibling" },
  172: { frequency: 172, spanish: "familia", english: "family • household" },
  173: { frequency: 173, spanish: "vale", english: "voucher" },
  174: { frequency: 174, spanish: "algún", english: "some • any" },
  175: { frequency: 175, spanish: "muchas", english: "many" },
  176: {
    frequency: 176,
    spanish: "señora",
    english: "lady • madam • madame • Mrs .",
  },
  177: { frequency: 177, spanish: "noches", english: "night • nighttime" },
  178: { frequency: 178, spanish: "ud", english: "you" },
  179: { frequency: 179, spanish: "todavía", english: "still • yet • as yet" },
  180: {
    frequency: 180,
    spanish: "suerte",
    english: "luck • fortune • chance",
  },
  181: {
    frequency: 181,
    spanish: "buenos",
    english: "good • right • nice • kind",
  },
  182: {
    frequency: 182,
    spanish: "sería",
    english: "good • right • nice • kind",
  },
  183: { frequency: 183, spanish: "miedo", english: "while • whereas • as" },
  184: { frequency: 184, spanish: "contra", english: "against" },
  185: { frequency: 185, spanish: "puerta", english: "against • to" },
  186: {
    frequency: 186,
    spanish: "pronto",
    english: "door • doorway • gate • gateway",
  },
  187: {
    frequency: 187,
    spanish: "nueva",
    english: "new • fresh • novel • further",
  },
  188: { frequency: 188, spanish: "cualquier", english: "any • whatever" },
  189: { frequency: 189, spanish: "esas", english: "those" },
  190: { frequency: 190, spanish: "os", english: "you • yourselves" },
  191: {
    frequency: 191,
    spanish: "chico",
    english: "boy • kid • child • lad • small",
  },
  192: { frequency: 192, spanish: "cuánto", english: "How much?" },
  193: {
    frequency: 193,
    spanish: "niños",
    english: "child • kid • boy • baby • infant",
  },
  194: { frequency: 194, spanish: "primero", english: "first" },
  195: { frequency: 195, spanish: "hacia", english: "towards • to • toward" },
  196: {
    frequency: 196,
    spanish: "dentro",
    english: "in • indoors • within • inside",
  },
  197: { frequency: 197, spanish: "ciudad", english: "city • town" },
  198: {
    frequency: 198,
    spanish: "historia",
    english: "history • story • tale",
  },
  199: { frequency: 199, spanish: "año", english: "year" },
  200: { frequency: 200, spanish: "durante", english: "during" },
  201: { frequency: 201, spanish: "feliz", english: "happy" },
  202: { frequency: 202, spanish: "ojos", english: "eyes" },
  203: { frequency: 203, spanish: "guerra", english: "war • warfare" },
  204: { frequency: 204, spanish: "caso", english: "case • instance" },
  205: { frequency: 205, spanish: "chicos", english: "girl • gal" },
  206: { frequency: 206, spanish: "esposa", english: "wife" },
  207: { frequency: 207, spanish: "cuál", english: "What? • Which?" },
  208: { frequency: 208, spanish: "allá", english: "there" },
  209: {
    frequency: 209,
    spanish: "mas",
    english: "other • else • more • plus • most",
  },
  210: { frequency: 210, spanish: "loco", english: "crazy • mad • madman" },
  211: { frequency: 211, spanish: "supuesto", english: "supposed" },
  212: { frequency: 212, spanish: "minutos", english: "minute" },
  213: { frequency: 213, spanish: "semana", english: "week" },
  214: { frequency: 214, spanish: "manos", english: "hands" },
  215: { frequency: 215, spanish: "problemas", english: "problems" },
  216: { frequency: 216, spanish: "juntos", english: "together" },
  217: {
    frequency: 217,
    spanish: "importante",
    english: "important • significant • large • big • main • leading",
  },
  218: {
    frequency: 218,
    spanish: "niño",
    english: "child • kid • boy • baby • infant",
  },
  219: { frequency: 219, spanish: "hija", english: "daughter" },
  220: { frequency: 220, spanish: "otros", english: "others" },
  221: { frequency: 221, spanish: "sra", english: "Mrs" },
  222: { frequency: 222, spanish: "personas", english: "people" },
  223: { frequency: 223, spanish: "mujeres", english: "women • ladies" },
  224: {
    frequency: 224,
    spanish: "grande",
    english: "large • big • great • high",
  },
  225: { frequency: 225, spanish: "ningún", english: "any" },
  226: { frequency: 226, spanish: "nuestros", english: "our" },
  227: { frequency: 227, spanish: "cinco", english: "five" },
  228: { frequency: 228, spanish: "habla", english: "speech • language" },
  229: { frequency: 229, spanish: "cuándo", english: "When?" },
  230: {
    frequency: 230,
    spanish: "quizás",
    english: "perhaps • maybe • possibly",
  },
  231: { frequency: 231, spanish: "escucha", english: "listening" },
  232: { frequency: 232, spanish: "persona", english: "person" },
  233: { frequency: 233, spanish: "horas", english: "hours" },
  234: { frequency: 234, spanish: "tío", english: "uncle • guy • chap" },
  235: {
    frequency: 235,
    spanish: "aunque",
    english: "although • though • even though • even if • while • albeit",
  },
  236: {
    frequency: 236,
    spanish: "único",
    english: "only • alone • unique • single • one • sole",
  },
  237: { frequency: 237, spanish: "siquiera", english: "at least" },
  238: { frequency: 238, spanish: "ninguna", english: "any • no • neither" },
  239: {
    frequency: 239,
    spanish: "pequeño",
    english: "small • little • short • slight",
  },
  240: { frequency: 240, spanish: "auto", english: "car • auto" },
  241: { frequency: 241, spanish: "cuatro", english: "four" },
  242: { frequency: 242, spanish: "muchos", english: "numerous • many • much" },
  243: {
    frequency: 243,
    spanish: "capitán",
    english: "captain • skipper • commander",
  },
  244: {
    frequency: 244,
    spanish: "suficiente",
    english:
      "adequate • sufficient • plenty • ample • enough • sufficiently • plenty of",
  },
  245: { frequency: 245, spanish: "haya", english: "beech" },
  246: { frequency: 246, spanish: "primer", english: "first" },
  247: { frequency: 247, spanish: "genial", english: "brilliant • genial" },
  248: {
    frequency: 248,
    spanish: "justo",
    english: "right • righteous • just",
  },
  249: { frequency: 249, spanish: "misma", english: "same • very • one" },
  250: { frequency: 250, spanish: "algunos", english: "few • some • a few" },
  251: {
    frequency: 251,
    spanish: "fuerte",
    english: "strong • hard • sharp • loud • heavy • powerful • high • fort",
  },
  252: { frequency: 252, spanish: "srta", english: "Ms ." },
  253: { frequency: 253, spanish: "basta", english: "Enough!" },
  254: {
    frequency: 254,
    spanish: "bastante",
    english: "quite • really • genuinely • indeed",
  },
  255: { frequency: 255, spanish: "amo", english: "owner" },
  256: {
    frequency: 256,
    spanish: "difícil",
    english: "difficult • hard • tough • tricky",
  },
  257: { frequency: 257, spanish: "éste", english: "this" },
  258: { frequency: 258, spanish: "vino", english: "wine • vino" },
  259: { frequency: 259, spanish: "hermana", english: "sister" },
  260: { frequency: 260, spanish: "hijos", english: "offspring" },
  261: { frequency: 261, spanish: "unas", english: "some" },
  262: { frequency: 262, spanish: "escuela", english: "school" },
  263: {
    frequency: 263,
    spanish: "pueblo",
    english: "people • village • town • the people",
  },
  264: { frequency: 264, spanish: "sangre", english: "blood • bloodstream" },
  265: { frequency: 265, spanish: "meses", english: "months" },
  266: {
    frequency: 266,
    spanish: "realidad",
    english: "reality • fact • actuality",
  },
  267: { frequency: 267, spanish: "cuerpo", english: "body • corps" },
  268: { frequency: 268, spanish: "ok", english: "okay" },
  269: { frequency: 269, spanish: "paz", english: "peace" },
  270: { frequency: 270, spanish: "tenido", english: "had" },
  271: { frequency: 271, spanish: "sola", english: "alone" },
  272: { frequency: 272, spanish: "ido", english: "gone • left" },
  273: { frequency: 273, spanish: "querida", english: "mistress" },
  274: {
    frequency: 274,
    spanish: "malo",
    english: "bad • wrong • evil • poor • wicked • naughty",
  },
  275: { frequency: 275, spanish: "chicas", english: "girl • gal" },
  276: {
    frequency: 276,
    spanish: "comida",
    english: "food • meal • lunch • dinner • eating • cuisine • dining",
  },
  277: { frequency: 277, spanish: "fácil", english: "easy • simple" },
  278: {
    frequency: 278,
    spanish: "posible",
    english: "possible • potential • likely",
  },
  279: { frequency: 279, spanish: "incluso", english: "even • including" },
  280: { frequency: 280, spanish: "tampoco", english: "neither" },
  281: { frequency: 281, spanish: "cama", english: "bed" },
  282: {
    frequency: 282,
    spanish: "lejos",
    english: "away • far • far away • afar",
  },
  283: { frequency: 283, spanish: "ay", english: "Oh! • Alas! • Ouch!" },
  284: { frequency: 284, spanish: "trata", english: "is" },
  285: { frequency: 285, spanish: "cuanto", english: "that • when" },
  286: { frequency: 286, spanish: "esté", english: "this • this one • east" },
  287: { frequency: 287, spanish: "segundo", english: "second" },
  288: { frequency: 288, spanish: "querido", english: "dear • darling" },
  289: {
    frequency: 289,
    spanish: "diablos",
    english: "devil • the devil • evil",
  },
  290: {
    frequency: 290,
    spanish: "oportunidad",
    english: "opportunity • chance • occasion • shot",
  },
  291: { frequency: 291, spanish: "algunas", english: "some • a few" },
  292: { frequency: 292, spanish: "esperando", english: "hoping for" },
  293: { frequency: 293, spanish: "adónde", english: "Where? • Whither?" },
  294: { frequency: 294, spanish: "seis", english: "six" },
  295: {
    frequency: 295,
    spanish: "cuarto",
    english: "quarter • room • fourth",
  },
  296: { frequency: 296, spanish: "cielo", english: "sky • heaven • skies" },
  297: {
    frequency: 297,
    spanish: "recuerdo",
    english:
      "memory • souvenir • keepsake • remembrance • recollection • reminder",
  },
  298: {
    frequency: 298,
    spanish: "pequeña",
    english: "small • little • short • slight",
  },
  299: { frequency: 299, spanish: "oído", english: "ear • hearing" },
  300: { frequency: 300, spanish: "ésta", english: "this" },
  301: { frequency: 301, spanish: "aqui", english: "here" },
  302: {
    frequency: 302,
    spanish: "además",
    english: "also • besides • further • moreover • furthermore • additionally",
  },
  303: { frequency: 303, spanish: "marido", english: "husband" },
  304: { frequency: 304, spanish: "perro", english: "dog" },
  305: { frequency: 305, spanish: "calle", english: "street • road" },
  306: {
    frequency: 306,
    spanish: "exactamente",
    english: "exactly • just • precisely • accurately • quite",
  },
  307: { frequency: 307, spanish: "rey", english: "king" },
  308: { frequency: 308, spanish: "padres", english: "father • parent" },
  309: { frequency: 309, spanish: "lista", english: "list • table • roster" },
  310: { frequency: 310, spanish: "habitación", english: "room" },
  311: { frequency: 311, spanish: "niña", english: "girl" },
  312: { frequency: 312, spanish: "música", english: "music" },
  313: { frequency: 313, spanish: "di", english: "gave" },
  314: { frequency: 314, spanish: "mucha", english: "lot" },
  315: { frequency: 315, spanish: "afuera", english: "outside • out" },
  316: { frequency: 316, spanish: "café", english: "coffee • cafe" },
  317: { frequency: 317, spanish: "nuestras", english: "our" },
  318: { frequency: 318, spanish: "ello", english: "it" },
  319: {
    frequency: 319,
    spanish: "grandes",
    english: "large • big • great • high",
  },
  320: { frequency: 320, spanish: "bebé", english: "baby • babe" },
  321: { frequency: 321, spanish: "jamás", english: "never" },
  322: { frequency: 322, spanish: "minuto", english: "minute" },
  323: {
    frequency: 323,
    spanish: "única",
    english: "only • alone • unique • single • one • sole",
  },
  324: { frequency: 324, spanish: "muchachos", english: "boy • lad" },
  325: { frequency: 325, spanish: "perdido", english: "lost • missing" },
  326: { frequency: 326, spanish: "diez", english: "ten" },
  327: { frequency: 327, spanish: "dado", english: "given" },
  328: {
    frequency: 328,
    spanish: "mil",
    english: "mil • thousand • one thousand",
  },
  329: {
    frequency: 329,
    spanish: "peor",
    english: "worse off • worst • worse",
  },
  330: {
    frequency: 330,
    spanish: "extraño",
    english:
      "strange • weird • odd • peculiar • foreign • extraneous • bizarre • uncommon",
  },
  331: { frequency: 331, spanish: "pobre", english: "poor • meager" },
  332: { frequency: 332, spanish: "oficina", english: "office • bureau" },
  333: { frequency: 333, spanish: "sino", english: "but" },
  334: { frequency: 334, spanish: "modo", english: "mode • way • manner" },
  335: { frequency: 335, spanish: "muchacho", english: "boy • lad" },
  336: {
    frequency: 336,
    spanish: "otras",
    english: "other • another • another one",
  },
  337: {
    frequency: 337,
    spanish: "presidente",
    english: "president • chairman",
  },
  338: { frequency: 338, spanish: "especial", english: "special • particular" },
  339: { frequency: 339, spanish: "millones", english: "millions" },
  340: { frequency: 340, spanish: "derecho", english: "straight • right" },
  341: { frequency: 341, spanish: "negro", english: "dark • black" },
  342: { frequency: 342, spanish: "acá", english: "here • hither" },
  343: { frequency: 343, spanish: "caballeros", english: "gentlemen" },
  344: { frequency: 344, spanish: "semanas", english: "week" },
  345: { frequency: 345, spanish: "palabras", english: "words" },
  346: {
    frequency: 346,
    spanish: "duro",
    english: "hard • tough • harsh • difficult • rough",
  },
  347: { frequency: 347, spanish: "boca", english: "mouth" },
  348: { frequency: 348, spanish: "mire", english: "Look!" },
  349: { frequency: 349, spanish: "armas", english: "arms • weaponry" },
  350: {
    frequency: 350,
    spanish: "encima",
    english: "above • over • atop • overhead",
  },
  351: {
    frequency: 351,
    spanish: "demonios",
    english: "demon • devil • daemon • fiend",
  },
  352: {
    frequency: 352,
    spanish: "mala",
    english: "bad • wrong • evil • poor • wicked • naughty",
  },
  353: { frequency: 353, spanish: "cual", english: "which • that" },
  354: { frequency: 354, spanish: "llamado", english: "called" },
  355: {
    frequency: 355,
    spanish: "seguridad",
    english: "security • safety • certainty • assurance",
  },
  356: { frequency: 356, spanish: "largo", english: "long • lengthy • length" },
  357: {
    frequency: 357,
    spanish: "probablemente",
    english: "probably • likely • possibly • presumably",
  },
  358: { frequency: 358, spanish: "ayer", english: "yesterday" },
  359: {
    frequency: 359,
    spanish: "bonito",
    english: "nice • beautiful • pretty",
  },
  360: { frequency: 360, spanish: "veras", english: "truth" },
  361: {
    frequency: 361,
    spanish: "increíble",
    english: "incredible • unbelievable",
  },
  362: { frequency: 362, spanish: "simplemente", english: "simply • merely" },
  363: { frequency: 363, spanish: "preguntas", english: "questioning" },
  364: {
    frequency: 364,
    spanish: "carta",
    english: "letter • card • carte • menu • charter",
  },
  365: { frequency: 365, spanish: "ése", english: "that • that one" },
  366: {
    frequency: 366,
    spanish: "hambre",
    english: "hunger • famine • starvation",
  },
  367: { frequency: 367, spanish: "vuelto", english: "turned" },
  368: { frequency: 368, spanish: "sol", english: "sun • sunshine • sol" },
  369: { frequency: 369, spanish: "tuyo", english: "yours • thine" },
  370: { frequency: 370, spanish: "llegado", english: "arrived" },
  371: { frequency: 371, spanish: "noticias", english: "news • information" },
  372: { frequency: 372, spanish: "pie", english: "foot • bottom" },
  373: { frequency: 373, spanish: "espere", english: "Wait!" },
  374: { frequency: 374, spanish: "secreto", english: "secret" },
  375: {
    frequency: 375,
    spanish: "compañía",
    english: "company • companionship",
  },
  376: { frequency: 376, spanish: "tras", english: "after • behind" },
  377: { frequency: 377, spanish: "futuro", english: "future" },
  378: {
    frequency: 378,
    spanish: "médico",
    english: "doctor • physician • medico • medical",
  },
  379: { frequency: 379, spanish: "loca", english: "crazy • mad • madman" },
  380: { frequency: 380, spanish: "sal", english: "salt • sal" },
  381: { frequency: 381, spanish: "raro", english: "rare • unusual • odd" },
  382: {
    frequency: 382,
    spanish: "novia",
    english: "bride • fiancee • fiancée",
  },
  383: {
    frequency: 383,
    spanish: "diferente",
    english: "different • distinct • various",
  },
  384: { frequency: 384, spanish: "imposible", english: "impossible" },
  385: { frequency: 385, spanish: "amiga", english: "female friend" },
  386: {
    frequency: 386,
    spanish: "enseguida",
    english: "immediately • promptly",
  },
  387: { frequency: 387, spanish: "dejó", english: "left" },
  388: { frequency: 388, spanish: "mes", english: "month • MO" },
  389: {
    frequency: 389,
    spanish: "avión",
    english: "plane • airplane • aircraft",
  },
  390: { frequency: 390, spanish: "siguiente", english: "following" },
  391: { frequency: 391, spanish: "oro", english: "gold" },
  392: {
    frequency: 392,
    spanish: "oficial",
    english: "formal • official • officer",
  },
  393: { frequency: 393, spanish: "entendido", english: "understood" },
  394: {
    frequency: 394,
    spanish: "estúpido",
    english: "stupid • idiotic • idiot",
  },
  395: { frequency: 395, spanish: "mitad", english: "half • middle" },
  396: { frequency: 396, spanish: "caballo", english: "horse" },
  397: {
    frequency: 397,
    spanish: "asesino",
    english: "murderer • killer • slayer • assassin • murderous",
  },
  398: { frequency: 398, spanish: "ellas", english: "they" },
  399: {
    frequency: 399,
    spanish: "maravilloso",
    english: "wonderful • marvelous • wondrous • amazing",
  },
  400: { frequency: 400, spanish: "mesa", english: "table • board" },
  401: {
    frequency: 401,
    spanish: "divertido",
    english: "funny • amusing • amused • enjoyable",
  },
  402: { frequency: 402, spanish: "mar", english: "sea • ocean" },
  403: { frequency: 403, spanish: "siete", english: "seven" },
  404: { frequency: 404, spanish: "hacía", english: "towards • to • toward" },
  405: { frequency: 405, spanish: "sexo", english: "sex • gender" },
  406: {
    frequency: 406,
    spanish: "amable",
    english:
      "friendly • kind • nice • helpful • gentle • amiable • good • pleasant",
  },
  407: { frequency: 407, spanish: "mensaje", english: "message" },
  408: {
    frequency: 408,
    spanish: "información",
    english: "information • data",
  },
  409: { frequency: 409, spanish: "alma", english: "soul • heart • spirit" },
  410: { frequency: 410, spanish: "coronel", english: "colonel" },
  411: { frequency: 411, spanish: "san", english: "saint" },
  412: { frequency: 412, spanish: "asi", english: "so • thus • thereby" },
  413: { frequency: 413, spanish: "adentro", english: "indoors • in • inward" },
  414: { frequency: 414, spanish: "canción", english: "song • singing" },
  415: {
    frequency: 415,
    spanish: "gobierno",
    english: "government • administration",
  },
  416: { frequency: 416, spanish: "vosotros", english: "you • yourselves" },
  417: { frequency: 417, spanish: "foto", english: "photo • shot" },
  418: {
    frequency: 418,
    spanish: "bonita",
    english: "nice • beautiful • pretty",
  },
  419: { frequency: 419, spanish: "ante", english: "before • suede" },
  420: { frequency: 420, spanish: "teniente", english: "lieutenant" },
  421: { frequency: 421, spanish: "luna", english: "moon" },
  422: { frequency: 422, spanish: "izquierda", english: "left" },
  423: { frequency: 423, spanish: "uds", english: "you" },
  424: { frequency: 424, spanish: "llamas", english: "flames" },
  425: { frequency: 425, spanish: "junto", english: "together" },
  426: { frequency: 426, spanish: "navidad", english: "Christmas • Xmas" },
  427: { frequency: 427, spanish: "abuela", english: "grandmother" },
  428: { frequency: 428, spanish: "libertad", english: "freedom • liberty" },
  429: { frequency: 429, spanish: "pies", english: "foot • bottom" },
  430: { frequency: 430, spanish: "vieja", english: "old woman • old lady" },
  431: {
    frequency: 431,
    spanish: "terminado",
    english: "finished • completed • over • done",
  },
  432: { frequency: 432, spanish: "dejado", english: "left" },
  433: { frequency: 433, spanish: "dulce", english: "gentle • sweet • candy" },
  434: {
    frequency: 434,
    spanish: "interesante",
    english: "interesting • intriguing",
  },
  435: {
    frequency: 435,
    spanish: "cualquiera",
    english: "any • whatever • whichever • anyone • anybody",
  },
  436: { frequency: 436, spanish: "ocho", english: "eight" },
  437: {
    frequency: 437,
    spanish: "gracioso",
    english: "funny • amusing • graceful • gracious • droll",
  },
  438: {
    frequency: 438,
    spanish: "completamente",
    english: "completely • all • altogether • fully • full • thoroughly",
  },
  439: {
    frequency: 439,
    spanish: "abuelo",
    english: "grandfather • grandpa • granddad • grandparent",
  },
  440: {
    frequency: 440,
    spanish: "linda",
    english: "cute • nice • beautiful • pretty",
  },
  441: { frequency: 441, spanish: "partes", english: "parts" },
  442: { frequency: 442, spanish: "cárcel", english: "jail • gaol • prison" },
  443: { frequency: 443, spanish: "sistema", english: "system • scheme" },
  444: {
    frequency: 444,
    spanish: "lindo",
    english: "cute • nice • beautiful • pretty",
  },
  445: { frequency: 445, spanish: "tía", english: "aunt • auntie • aunty" },
  446: { frequency: 446, spanish: "salud", english: "health" },
  447: { frequency: 447, spanish: "negocios", english: "business" },
  448: { frequency: 448, spanish: "cámara", english: "camera • chamber" },
  449: { frequency: 449, spanish: "agente", english: "agent" },
  450: { frequency: 450, spanish: "verás", english: "truth" },
  451: { frequency: 451, spanish: "infierno", english: "hell • inferno" },
  452: { frequency: 452, spanish: "río", english: "river" },
  453: { frequency: 453, spanish: "través", english: "through" },
  454: { frequency: 454, spanish: "carne", english: "meat • flesh" },
  455: {
    frequency: 455,
    spanish: "totalmente",
    english: "totally • completely • entirely • wholly",
  },
  456: { frequency: 456, spanish: "esposo", english: "husband • spouse" },
  457: { frequency: 457, spanish: "sargento", english: "sergeant • serjeant" },
  458: { frequency: 458, spanish: "tuya", english: "yours • thine" },
  459: { frequency: 459, spanish: "ambos", english: "both" },
  460: { frequency: 460, spanish: "ésa", english: "that • that one" },
  461: {
    frequency: 461,
    spanish: "verdadero",
    english: "TRUE • real • actual • veritable",
  },
  462: { frequency: 462, spanish: "cumpleaños", english: "birthday" },
  463: {
    frequency: 463,
    spanish: "rato",
    english: "while • time • bit • little",
  },
  464: {
    frequency: 464,
    spanish: "universidad",
    english: "university • school",
  },
  465: {
    frequency: 465,
    spanish: "triste",
    english:
      "sad • sorry • unhappy • dismal • gloomy • dreary • blue • sorrowful • miserable • mournful",
  },
  466: { frequency: 466, spanish: "iglesia", english: "church" },
  467: { frequency: 467, spanish: "delante", english: "before" },
  468: { frequency: 468, spanish: "nena", english: "baby • babe" },
  469: { frequency: 469, spanish: "banco", english: "bank • bench" },
  470: { frequency: 470, spanish: "cuántos", english: "How many?" },
  471: { frequency: 471, spanish: "alegro", english: "glad" },
  472: { frequency: 472, spanish: "santo", english: "holy • saintly • saint" },
  473: { frequency: 473, spanish: "porqué", english: "why" },
  474: { frequency: 474, spanish: "salió", english: "he left" },
  475: { frequency: 475, spanish: "jesús", english: "Jesus" },
  476: { frequency: 476, spanish: "vienes", english: "Viennese" },
  477: { frequency: 477, spanish: "cerveza", english: "beer" },
  478: { frequency: 478, spanish: "muerta", english: "dead" },
  479: { frequency: 479, spanish: "pensado", english: "thought-out" },
  480: {
    frequency: 480,
    spanish: "destino",
    english: "destination • destiny • fate • purpose",
  },
  481: { frequency: 481, spanish: "fotos", english: "photo • shot" },
  482: {
    frequency: 482,
    spanish: "diablo",
    english: "devil • the devil • evil",
  },
  483: { frequency: 483, spanish: "cielos", english: "Heavens!" },
  484: { frequency: 484, spanish: "norte", english: "north" },
  485: {
    frequency: 485,
    spanish: "sala",
    english: "room • hall • lounge • ward • chamber",
  },
  486: {
    frequency: 486,
    spanish: "según",
    english:
      "according to • by • depending on • in accordance with • under • to • on",
  },
  487: { frequency: 487, spanish: "parís", english: "Paris" },
  488: {
    frequency: 488,
    spanish: "ejemplo",
    english: "example • instance • illustration",
  },
  489: { frequency: 489, spanish: "té", english: "tea" },
  490: { frequency: 490, spanish: "temprano", english: "early • soon" },
  491: { frequency: 491, spanish: "público", english: "public • audience" },
  492: { frequency: 492, spanish: "tí", english: "you • thee" },
  493: { frequency: 493, spanish: "ventana", english: "window" },
  494: { frequency: 494, spanish: "conocido", english: "known" },
  495: { frequency: 495, spanish: "boda", english: "wedding • marriage" },
  496: {
    frequency: 496,
    spanish: "peligro",
    english: "danger • peril • hazard • jeopardy • distress",
  },
  497: { frequency: 497, spanish: "reina", english: "queen" },
  498: { frequency: 498, spanish: "sur", english: "south" },
  499: {
    frequency: 499,
    spanish: "enfermo",
    english: "sick • ill • diseased • ailing • sickly • patient • invalid",
  },
  500: { frequency: 500, spanish: "excelente", english: "excellent" },
  501: { frequency: 501, spanish: "aquel", english: "that" },
  502: { frequency: 502, spanish: "pruebas", english: "testing • proof" },
  503: { frequency: 503, spanish: "señores", english: "Messrs" },
  504: { frequency: 504, spanish: "vos", english: "you" },
  505: { frequency: 505, spanish: "aun", english: "even • yet • still" },
  506: {
    frequency: 506,
    spanish: "ninguno",
    english: "none • any • no • neither",
  },
  507: {
    frequency: 507,
    spanish: "apenas",
    english: "just • scarcely • barely • hardly",
  },
  508: { frequency: 508, spanish: "segunda", english: "second" },
  509: { frequency: 509, spanish: "ojalá", english: "hopefully" },
  510: { frequency: 510, spanish: "éi", english: "ei" },
  511: { frequency: 511, spanish: "cartas", english: "letters" },
  512: { frequency: 512, spanish: "libros", english: "books" },
  513: { frequency: 513, spanish: "apuesto", english: "dapper" },
  514: { frequency: 514, spanish: "cambiado", english: "changed" },
  515: {
    frequency: 515,
    spanish: "agradable",
    english: "nice • pleasant • enjoyable • pleasurable • pleasing • agreeable",
  },
  516: {
    frequency: 516,
    spanish: "cocina",
    english: "kitchen • cuisine • cooking • stove",
  },
  517: { frequency: 517, spanish: "sueños", english: "dreams" },
  518: { frequency: 518, spanish: "segundos", english: "second" },
  519: { frequency: 519, spanish: "cerebro", english: "brain • mastermind" },
  520: {
    frequency: 520,
    spanish: "locura",
    english: "madness • insanity • folly • craziness • lunacy",
  },
  521: {
    frequency: 521,
    spanish: "peligroso",
    english: "dangerous • hazardous • risky • perilous",
  },
  522: { frequency: 522, spanish: "hermanos", english: "brethren" },
  523: {
    frequency: 523,
    spanish: "cine",
    english: "cinema • movie theater • movies • cine",
  },
  524: { frequency: 524, spanish: "londres", english: "London" },
  525: {
    frequency: 525,
    spanish: "mayoría",
    english: "most • majority • many",
  },
  526: { frequency: 526, spanish: "acaso", english: "perhaps" },
  527: {
    frequency: 527,
    spanish: "misión",
    english: "mission • task • job • assignment",
  },
  528: {
    frequency: 528,
    spanish: "vidas",
    english: "life • living • lifetime",
  },
  529: { frequency: 529, spanish: "decisión", english: "decision" },
  530: {
    frequency: 530,
    spanish: "hogar",
    english: "home • hearth • fireplace",
  },
  531: {
    frequency: 531,
    spanish: "rico",
    english: "rich • wealthy • delicious",
  },
  532: { frequency: 532, spanish: "capaz", english: "able • capable" },
  533: {
    frequency: 533,
    spanish: "unidos",
    english: "united • joined • attached • connected",
  },
  534: { frequency: 534, spanish: "lleno", english: "filled • full • packed" },
  535: { frequency: 535, spanish: "bar", english: "bar • snack bar" },
  536: { frequency: 536, spanish: "edificio", english: "building • edifice" },
  537: {
    frequency: 537,
    spanish: "pistola",
    english: "gun • pistol • handgun",
  },
  538: { frequency: 538, spanish: "humano", english: "humane • human" },
  539: {
    frequency: 539,
    spanish: "fantástico",
    english: "fantastic • fantastical",
  },
  540: {
    frequency: 540,
    spanish: "jóvenes",
    english: "young • youth • young people",
  },
  541: { frequency: 541, spanish: "zapatos", english: "shoes" },
  542: { frequency: 542, spanish: "majestad", english: "majesty" },
  543: {
    frequency: 543,
    spanish: "zona",
    english: "area • region • zone • district",
  },
  544: { frequency: 544, spanish: "matado", english: "murdered" },
  545: { frequency: 545, spanish: "soldado", english: "soldier" },
  546: { frequency: 546, spanish: "equivocado", english: "wrong • mistaken" },
  547: { frequency: 547, spanish: "animales", english: "animal • beast" },
  548: { frequency: 548, spanish: "enemigo", english: "enemy • foe • hostile" },
  549: {
    frequency: 549,
    spanish: "caballero",
    english: "gentleman • knight • sir • cavalier • chevalier • gent",
  },
  550: { frequency: 550, spanish: "empieza", english: "starts" },
  551: { frequency: 551, spanish: "soldados", english: "soldier" },
  552: { frequency: 552, spanish: "azul", english: "blue" },
  553: { frequency: 553, spanish: "miles", english: "thousands" },
  554: {
    frequency: 554,
    spanish: "espacio",
    english: "space • room • gap • spacing",
  },
  555: {
    frequency: 555,
    spanish: "inteligente",
    english: "intelligent • smart • clever",
  },
  556: { frequency: 556, spanish: "arte", english: "art • craft" },
  557: { frequency: 557, spanish: "oiga", english: "Listen!" },
  558: { frequency: 558, spanish: "inglés", english: "English • Englishman" },
  559: { frequency: 559, spanish: "rojo", english: "red" },
  560: { frequency: 560, spanish: "verano", english: "summer • summertime" },
  561: { frequency: 561, spanish: "piernas", english: "leg" },
  562: { frequency: 562, spanish: "isla", english: "island • isle" },
  563: { frequency: 563, spanish: "damas", english: "ladies" },
  564: { frequency: 564, spanish: "luces", english: "lights • highlights" },
  565: {
    frequency: 565,
    spanish: "crimen",
    english: "crime • offense • felony",
  },
  566: { frequency: 566, spanish: "dejo", english: "left" },
  567: {
    frequency: 567,
    spanish: "diferencia",
    english: "difference • contrast • gap",
  },
  568: { frequency: 568, spanish: "ama", english: "loves" },
  569: { frequency: 569, spanish: "tiempos", english: "time" },
  570: {
    frequency: 570,
    spanish: "debajo",
    english: "below • beneath • underneath • under",
  },
  571: {
    frequency: 571,
    spanish: "molesta",
    english:
      "annoying • bothersome • irritating • troublesome • inconvenient • obtrusive",
  },
  572: {
    frequency: 572,
    spanish: "cansado",
    english: "tired • weary • tiresome • wearing",
  },
  573: { frequency: 573, spanish: "ganado", english: "cattle • livestock" },
  574: { frequency: 574, spanish: "hablado", english: "spoken" },
  575: { frequency: 575, spanish: "salida", english: "output" },
  576: {
    frequency: 576,
    spanish: "estupendo",
    english:
      "great • super • wonderful • marvelous • terrific • fantastic • stupendous",
  },
  577: { frequency: 577, spanish: "vuelo", english: "flight • flying" },
  578: {
    frequency: 578,
    spanish: "tonterías",
    english: "foolishness • stupidity • bullshit • rubbish",
  },
  579: { frequency: 579, spanish: "salga", english: "out" },
  580: { frequency: 580, spanish: "olvidado", english: "forgotten" },
  581: { frequency: 581, spanish: "caballos", english: "horse" },
  582: { frequency: 582, spanish: "vd", english: "you" },
  583: { frequency: 583, spanish: "perros", english: "dog" },
  584: { frequency: 584, spanish: "casado", english: "married • wedded" },
  585: {
    frequency: 585,
    spanish: "vacaciones",
    english: "holidays • vacation",
  },
  586: { frequency: 586, spanish: "copa", english: "cup • glass • goblet" },
  587: {
    frequency: 587,
    spanish: "investigación",
    english: "research • inquiry • investigation",
  },
  588: { frequency: 588, spanish: "solía", english: "I used to" },
  589: { frequency: 589, spanish: "menudo", english: "often" },
  590: { frequency: 590, spanish: "partir", english: "from" },
  591: {
    frequency: 591,
    spanish: "alta",
    english: "high • tall • upper • loud",
  },
  592: { frequency: 592, spanish: "nueve", english: "ninth • nine" },
  593: { frequency: 593, spanish: "santa", english: "St ." },
  594: { frequency: 594, spanish: "pan", english: "bread • loaf" },
  595: {
    frequency: 595,
    spanish: "metros",
    english: "metro • subway • underground • tube • meter",
  },
  596: {
    frequency: 596,
    spanish: "escrito",
    english: "written • writing • in writing",
  },
  597: { frequency: 597, spanish: "jugando", english: "Playing" },
  598: { frequency: 598, spanish: "cuello", english: "neck • collar" },
  599: { frequency: 599, spanish: "larga", english: "long" },
  600: { frequency: 600, spanish: "aquella", english: "that" },
  601: { frequency: 601, spanish: "verá", english: "edge" },
  602: { frequency: 602, spanish: "llena", english: "filled • full • packed" },
  603: { frequency: 603, spanish: "blanca", english: "white" },
  604: { frequency: 604, spanish: "fe", english: "faith • belief" },
  605: { frequency: 605, spanish: "modos", english: "mode • way • manner" },
  606: { frequency: 606, spanish: "traído", english: "brought" },
  607: { frequency: 607, spanish: "dama", english: "lady • dame" },
  608: {
    frequency: 608,
    spanish: "suya",
    english: "his • yours • hers • theirs • their • its",
  },
  609: {
    frequency: 609,
    spanish: "inmediatamente",
    english: "immediately • right away • at once • promptly • straight away",
  },
  610: { frequency: 610, spanish: "culpable", english: "guilty" },
  611: {
    frequency: 611,
    spanish: "tardes",
    english: "afternoon • evening • late",
  },
  612: {
    frequency: 612,
    spanish: "bosque",
    english: "forest • woods • wood • woodland",
  },
  613: { frequency: 613, spanish: "taxi", english: "taxi • cab • taxicab" },
  614: { frequency: 614, spanish: "debido", english: "due • owing" },
  615: { frequency: 615, spanish: "repente", english: "suddenly" },
  616: { frequency: 616, spanish: "viejos", english: "aged" },
  617: { frequency: 617, spanish: "planeta", english: "planet" },
  618: { frequency: 618, spanish: "tanta", english: "such" },
  619: { frequency: 619, spanish: "encantado", english: "delighted • haunted" },
  620: {
    frequency: 620,
    spanish: "cabrón",
    english: "bastard • asshole • ass",
  },
  621: {
    frequency: 621,
    spanish: "común",
    english: "common • mutual • ordinary • communal",
  },
  622: {
    frequency: 622,
    spanish: "solamente",
    english: "only • alone • solely • just",
  },
  623: { frequency: 623, spanish: "américa", english: "America" },
  624: { frequency: 624, spanish: "príncipe", english: "prince" },
  625: {
    frequency: 625,
    spanish: "preparado",
    english: "prepared • ready • preparation",
  },
  626: { frequency: 626, spanish: "salido", english: "left" },
  627: { frequency: 627, spanish: "cliente", english: "customer • client" },
  628: { frequency: 628, spanish: "árbol", english: "tree" },
  629: { frequency: 629, spanish: "quiénes", english: "Who" },
  630: { frequency: 630, spanish: "belleza", english: "beauty" },
  631: { frequency: 631, spanish: "humanos", english: "humane • human" },
  632: { frequency: 632, spanish: "vuestra", english: "your • yours" },
  633: { frequency: 633, spanish: "princesa", english: "princess" },
  634: { frequency: 634, spanish: "oeste", english: "west • westerly" },
  635: { frequency: 635, spanish: "francia", english: "France" },
  636: { frequency: 636, spanish: "deprisa", english: "fast • hastily" },
  637: {
    frequency: 637,
    spanish: "cerdo",
    english: "pork • pig • hog • swine",
  },
  638: { frequency: 638, spanish: "gato", english: "cat • jack" },
  639: { frequency: 639, spanish: "inglaterra", english: "England" },
  640: { frequency: 640, spanish: "cuántas", english: "how many" },
  641: { frequency: 641, spanish: "defensa", english: "defense" },
  642: {
    frequency: 642,
    spanish: "nuevos",
    english: "new • fresh • novel • further",
  },
  643: {
    frequency: 643,
    spanish: "interior",
    english:
      "inner • interior • internal • indoor • inland • domestic • inward • inside",
  },
  644: { frequency: 644, spanish: "bella", english: "beauty" },
  645: { frequency: 645, spanish: "felices", english: "happy" },
  646: { frequency: 646, spanish: "apartamento", english: "apartment • flat" },
  647: { frequency: 647, spanish: "hacerme", english: "me" },
  648: { frequency: 648, spanish: "papi", english: "Daddy" },
  649: { frequency: 649, spanish: "hielo", english: "ice • frost" },
  650: { frequency: 650, spanish: "vuestro", english: "your • yours" },
  651: { frequency: 651, spanish: "locos", english: "crazy • mad • madman" },
  652: { frequency: 652, spanish: "fuerzas", english: "forces" },
  653: {
    frequency: 653,
    spanish: "borracho",
    english: "drunk • drunken • drunkard",
  },
  654: { frequency: 654, spanish: "héroe", english: "hero" },
  655: {
    frequency: 655,
    spanish: "camión",
    english: "truck • camion • lorry • van",
  },
  656: { frequency: 656, spanish: "dientes", english: "teeth" },
  657: { frequency: 657, spanish: "verde", english: "green" },
  658: { frequency: 658, spanish: "decidido", english: "decided" },
  659: {
    frequency: 659,
    spanish: "sociedad",
    english: "society • corporation • partnership",
  },
  660: { frequency: 660, spanish: "nariz", english: "nose" },
  661: { frequency: 661, spanish: "herido", english: "wounded • injured" },
  662: { frequency: 662, spanish: "cuentas", english: "accounts" },
  663: { frequency: 663, spanish: "bebe", english: "baby • babe" },
  664: { frequency: 664, spanish: "sombrero", english: "hat" },
  665: { frequency: 665, spanish: "maría", english: "Mary" },
  666: { frequency: 666, spanish: "memoria", english: "memory • remembrance" },
  667: { frequency: 667, spanish: "solos", english: "alone" },
  668: {
    frequency: 668,
    spanish: "nervioso",
    english: "nervous • nerve • edgy • jittery",
  },
  669: { frequency: 669, spanish: "naturaleza", english: "nature • character" },
  670: { frequency: 670, spanish: "política", english: "policy • politics" },
  671: {
    frequency: 671,
    spanish: "brillante",
    english: "bright • shiny • shining • glowing • sparkling • brilliant",
  },
  672: {
    frequency: 672,
    spanish: "diferentes",
    english: "different • distinct • various",
  },
  673: {
    frequency: 673,
    spanish: "justicia",
    english: "justice • righteousness • fairness",
  },
  674: { frequency: 674, spanish: "roma", english: "Rome" },
  675: { frequency: 675, spanish: "seguramente", english: "surely • safely" },
  676: { frequency: 676, spanish: "papa", english: "potato • pope" },
  677: { frequency: 677, spanish: "pierna", english: "leg" },
  678: { frequency: 678, spanish: "quienes", english: "Who" },
  679: { frequency: 679, spanish: "millón", english: "million" },
  680: {
    frequency: 680,
    spanish: "enorme",
    english: "huge • vast • immense • enormous • tremendous • massive • great",
  },
  681: {
    frequency: 681,
    spanish: "inocente",
    english: "naive • guiltless • blameless • innocent",
  },
  682: { frequency: 682, spanish: "mismos", english: "same • very • one" },
  683: {
    frequency: 683,
    spanish: "pantalones",
    english: "pants • trousers • breeches",
  },
  684: { frequency: 684, spanish: "ministro", english: "minister" },
  685: { frequency: 685, spanish: "u", english: "or" },
  686: { frequency: 686, spanish: "preciosa", english: "precious" },
  687: {
    frequency: 687,
    spanish: "enferma",
    english: "sick • ill • diseased • ailing • sickly",
  },
  688: {
    frequency: 688,
    spanish: "extraña",
    english:
      "strange • weird • odd • peculiar • foreign • extraneous • bizarre • uncommon",
  },
  689: { frequency: 689, spanish: "autobús", english: "bus" },
  690: {
    frequency: 690,
    spanish: "pequeños",
    english: "small • little • short • slight",
  },
  691: { frequency: 691, spanish: "tomado", english: "taken" },
  692: { frequency: 692, spanish: "sois", english: "you are" },
  693: { frequency: 693, spanish: "sentado", english: "sitting" },
  694: {
    frequency: 694,
    spanish: "absolutamente",
    english: "absolutely • utterly • quite • indeed",
  },
  695: {
    frequency: 695,
    spanish: "dé",
    english: "of • from • by • with • at • about • than",
  },
  696: { frequency: 696, spanish: "huevos", english: "egg" },
  697: { frequency: 697, spanish: "tomó", english: "volume • tome" },
  698: {
    frequency: 698,
    spanish: "cuento",
    english: "story • tale • fairy tale • yarn",
  },
  699: { frequency: 699, spanish: "botella", english: "bottle" },
  700: { frequency: 700, spanish: "cuesta", english: "slope • hill" },
  701: { frequency: 701, spanish: "corriendo", english: "running" },
  702: {
    frequency: 702,
    spanish: "felicidad",
    english: "happiness • felicity • bliss",
  },
  703: { frequency: 703, spanish: "espada", english: "sword • blade" },
  704: { frequency: 704, spanish: "casas", english: "housing" },
  705: { frequency: 705, spanish: "televisión", english: "TV • television" },
  706: { frequency: 706, spanish: "pared", english: "wall" },
  707: { frequency: 707, spanish: "aquellos", english: "those" },
  708: { frequency: 708, spanish: "jardín", english: "garden" },
  709: { frequency: 709, spanish: "cabello", english: "hair" },
  710: {
    frequency: 710,
    spanish: "grave",
    english: "serious • severe • grave • grievous • acute • bad • low • deep",
  },
  711: { frequency: 711, spanish: "bastardo", english: "bastard" },
  712: {
    frequency: 712,
    spanish: "periódico",
    english: "newspaper • paper • journal • periodic • periodical",
  },
  713: { frequency: 713, spanish: "coño", english: "pussy • cunt" },
  714: { frequency: 714, spanish: "negros", english: "dark • black" },
  715: { frequency: 715, spanish: "alguno", english: "some • any • a few" },
  716: {
    frequency: 716,
    spanish: "contento",
    english:
      "happy • glad • contented • content • pleased • satisfied • contentment",
  },
  717: { frequency: 717, spanish: "ahi", english: "there" },
  718: { frequency: 718, spanish: "policías", english: "police" },
  719: { frequency: 719, spanish: "playa", english: "beach • seaside" },
  720: { frequency: 720, spanish: "puertas", english: "doors" },
  721: { frequency: 721, spanish: "tarjeta", english: "card" },
  722: { frequency: 722, spanish: "restaurante", english: "restaurant" },
  723: { frequency: 723, spanish: "hechos", english: "acts" },
  724: {
    frequency: 724,
    spanish: "maravillosa",
    english: "wonderful • marvelous • wondrous • amazing",
  },
  725: { frequency: 725, spanish: "domingo", english: "Sunday" },
  726: {
    frequency: 726,
    spanish: "especialmente",
    english: "especially • particularly • specially",
  },
  727: { frequency: 727, spanish: "negra", english: "black" },
  728: { frequency: 728, spanish: "clientes", english: "customer • client" },
  729: {
    frequency: 729,
    spanish: "operación",
    english: "operation • transaction",
  },
  730: { frequency: 730, spanish: "tantos", english: "such" },
  731: {
    frequency: 731,
    spanish: "malas",
    english: "bad • wrong • evil • poor • wicked • naughty",
  },
  732: {
    frequency: 732,
    spanish: "red",
    english: "network • web • net • grid",
  },
  733: { frequency: 733, spanish: "nuevas", english: "news" },
  734: { frequency: 734, spanish: "muchacha", english: "girl • lass" },
  735: { frequency: 735, spanish: "dormido", english: "asleep • sleeping" },
  736: {
    frequency: 736,
    spanish: "historias",
    english: "history • story • tale",
  },
  737: { frequency: 737, spanish: "tantas", english: "such" },
  738: { frequency: 738, spanish: "montaña", english: "mountain" },
  739: {
    frequency: 739,
    spanish: "dedos",
    english: "disease • illness • sickness • disorder",
  },
  740: { frequency: 740, spanish: "guapa", english: "beautiful" },
  741: { frequency: 741, spanish: "querer", english: "lovely" },
  742: { frequency: 742, spanish: "pocos", english: "few" },
  743: { frequency: 743, spanish: "viernes", english: "Friday" },
  744: {
    frequency: 744,
    spanish: "preocupado",
    english: "concerned • worried • preoccupied • troubled",
  },
  745: { frequency: 745, spanish: "haberte", english: "sorry" },
  746: {
    frequency: 746,
    spanish: "oscuridad",
    english: "darkness • dark • obscurity • blackness • gloom",
  },
  747: {
    frequency: 747,
    spanish: "verdadera",
    english: "TRUE • real • actual • veritable",
  },
  748: { frequency: 748, spanish: "americano", english: "American" },
  749: { frequency: 749, spanish: "primo", english: "cousin • prime" },
  750: { frequency: 750, spanish: "nacional", english: "domestic • national" },
  751: { frequency: 751, spanish: "colegio", english: "school • college" },
  752: { frequency: 752, spanish: "cuchillo", english: "knife" },
  753: {
    frequency: 753,
    spanish: "diario",
    english: "everyday • daily • journal • diary • newspaper • paper",
  },
  754: { frequency: 754, spanish: "francés", english: "French • Frenchman" },
  755: { frequency: 755, spanish: "orgulloso", english: "proud • prideful" },
  756: {
    frequency: 756,
    spanish: "ladrón",
    english: "thief • robber • burglar • crook",
  },
  757: { frequency: 757, spanish: "militar", english: "military • soldier" },
  758: { frequency: 758, spanish: "mami", english: "Mommy" },
  759: { frequency: 759, spanish: "calles", english: "street • road" },
  760: { frequency: 760, spanish: "cien", english: "hundred" },
  761: { frequency: 761, spanish: "lengua", english: "language • tongue" },
  762: {
    frequency: 762,
    spanish: "cansada",
    english: "tired • weary • tiresome • wearing",
  },
  763: { frequency: 763, spanish: "alemanes", english: "German" },
  764: {
    frequency: 764,
    spanish: "pago",
    english: "payment • paying • fee • repayment • payout",
  },
  765: { frequency: 765, spanish: "mercado", english: "market • marketplace" },
  766: {
    frequency: 766,
    spanish: "dura",
    english: "hard • tough • harsh • difficult",
  },
  767: { frequency: 767, spanish: "humana", english: "human • humane" },
  768: { frequency: 768, spanish: "estúpida", english: "stupid • idiotic" },
  769: { frequency: 769, spanish: "roja", english: "red" },
  770: { frequency: 770, spanish: "dueño", english: "owner • landlord" },
  771: { frequency: 771, spanish: "emergencia", english: "emergency" },
  772: { frequency: 772, spanish: "roto", english: "broken" },
  773: { frequency: 773, spanish: "puntos", english: "points" },
  774: { frequency: 774, spanish: "paciente", english: "patient" },
  775: { frequency: 775, spanish: "varios", english: "several • some" },
  776: { frequency: 776, spanish: "polvo", english: "dust • powder" },
  777: { frequency: 777, spanish: "ángeles", english: "angel" },
  778: { frequency: 778, spanish: "europa", english: "Europe" },
  779: { frequency: 779, spanish: "señoría", english: "lordship • ladyship" },
  780: { frequency: 780, spanish: "ángel", english: "angel" },
  781: { frequency: 781, spanish: "amante", english: "lover • mistress" },
  782: { frequency: 782, spanish: "saca", english: "shows" },
  783: { frequency: 783, spanish: "rosa", english: "pink • rose" },
  784: { frequency: 784, spanish: "nosotras", english: "us • we" },
  785: { frequency: 785, spanish: "desastre", english: "disaster • calamity" },
  786: {
    frequency: 786,
    spanish: "bote",
    english: "boat • pot • canister • dinghy",
  },
  787: { frequency: 787, spanish: "embarazada", english: "pregnant" },
  788: { frequency: 788, spanish: "gordo", english: "fat • thick • plump" },
  789: { frequency: 789, spanish: "puto", english: "fucking" },
  790: { frequency: 790, spanish: "papeles", english: "papers" },
  791: { frequency: 791, spanish: "gloria", english: "glory • fame" },
  792: {
    frequency: 792,
    spanish: "perfectamente",
    english: "perfectly • ideally",
  },
  793: { frequency: 793, spanish: "dedo", english: "finger" },
  794: { frequency: 794, spanish: "tumba", english: "tomb • grave" },
  795: { frequency: 795, spanish: "área", english: "area" },
  796: { frequency: 796, spanish: "metido", english: "I got" },
  797: {
    frequency: 797,
    spanish: "posibilidad",
    english: "possibility • chance • prospect • likelihood",
  },
  798: {
    frequency: 798,
    spanish: "guapo",
    english: "handsome • beautiful • pretty • nice",
  },
  799: { frequency: 799, spanish: "talento", english: "talent • gift" },
  800: { frequency: 800, spanish: "definitivamente", english: "definitely" },
  801: { frequency: 801, spanish: "dia", english: "day" },
  802: {
    frequency: 802,
    spanish: "demonio",
    english: "demon • devil • daemon • fiend",
  },
  803: { frequency: 803, spanish: "cinta", english: "tape" },
  804: {
    frequency: 804,
    spanish: "famoso",
    english: "famous • renowned • famed • celebrated",
  },
  805: { frequency: 805, spanish: "señoras", english: "ladies" },
  806: { frequency: 806, spanish: "sóio", english: "sodium" },
  807: {
    frequency: 807,
    spanish: "precioso",
    english: "beautiful • lovely • precious • nice",
  },
  808: {
    frequency: 808,
    spanish: "intención",
    english: "intention • intent • purpose",
  },
  809: { frequency: 809, spanish: "tambien", english: "also • too • as well" },
  810: { frequency: 810, spanish: "menor", english: "less • smaller • minor" },
  811: {
    frequency: 811,
    spanish: "profesional",
    english: "vocational • occupational • professional • pro",
  },
  812: {
    frequency: 812,
    spanish: "niñas",
    english: "child • kid • boy • baby • infant",
  },
  813: { frequency: 813, spanish: "opción", english: "option • choice" },
  814: { frequency: 814, spanish: "tribunal", english: "court • tribunal" },
  815: { frequency: 815, spanish: "lago", english: "lake" },
  816: { frequency: 816, spanish: "unidad", english: "unit • unity • oneness" },
  817: { frequency: 817, spanish: "varias", english: "various" },
  818: {
    frequency: 818,
    spanish: "robo",
    english: "theft • stealing • thievery • robbery",
  },
  819: { frequency: 819, spanish: "quedado", english: "left" },
  820: { frequency: 820, spanish: "alcalde", english: "mayor" },
  821: { frequency: 821, spanish: "whisky", english: "whiskey" },
  822: { frequency: 822, spanish: "carretera", english: "road • highway" },
  823: { frequency: 823, spanish: "sábado", english: "Saturday • sabbath" },
  824: { frequency: 824, spanish: "aeropuerto", english: "airport" },
  825: { frequency: 825, spanish: "jodido", english: "fucking" },
  826: { frequency: 826, spanish: "pobres", english: "poor" },
  827: { frequency: 827, spanish: "medicina", english: "medicine" },
  828: { frequency: 828, spanish: "fortuna", english: "fortune • luck" },
  829: { frequency: 829, spanish: "castillo", english: "castle • chateau" },
  830: { frequency: 830, spanish: "nieve", english: "snow" },
  831: { frequency: 831, spanish: "alemán", english: "German" },
  832: {
    frequency: 832,
    spanish: "sucio",
    english: "dirty • dingy • unclean • grubby • nasty",
  },
  833: {
    frequency: 833,
    spanish: "propiedad",
    english: "property • ownership • estate • proprietary",
  },
  834: { frequency: 834, spanish: "mundial", english: "world • worldwide" },
  835: {
    frequency: 835,
    spanish: "familiar",
    english: "family • familiar • relative",
  },
  836: { frequency: 836, spanish: "libras", english: "pound" },
  837: {
    frequency: 837,
    spanish: "pedazo",
    english: "piece • chunk • hunk • lump",
  },
  838: {
    frequency: 838,
    spanish: "enemigos",
    english: "enemy • foe • hostile",
  },
  839: { frequency: 839, spanish: "líder", english: "leader" },
  840: { frequency: 840, spanish: "fútbol", english: "football • soccer" },
  841: { frequency: 841, spanish: "detalles", english: "detail" },
  842: { frequency: 842, spanish: "recibido", english: "received" },
  843: { frequency: 843, spanish: "camisa", english: "shirt" },
  844: { frequency: 844, spanish: "llevado", english: "carried" },
  845: {
    frequency: 845,
    spanish: "alegría",
    english:
      "joy • cheerfulness • happiness • gladness • gaiety • contentment • rejoicing • fun • merriment • mirth",
  },
  846: { frequency: 846, spanish: "pertenece", english: "pertain" },
  847: { frequency: 847, spanish: "mama", english: "momma • mamma" },
  848: {
    frequency: 848,
    spanish: "oficiales",
    english: "formal • official • officer",
  },
  849: {
    frequency: 849,
    spanish: "víctima",
    english: "victim • sufferer • casualty • prey",
  },
  850: {
    frequency: 850,
    spanish: "malos",
    english: "bad • wrong • evil • poor • wicked • naughty",
  },
  851: {
    frequency: 851,
    spanish: "barrio",
    english: "neighborhood • district • quarter • suburb",
  },
  852: {
    frequency: 852,
    spanish: "desgraciado",
    english: "miserable • unhappy • unfortunate • wretch",
  },
  853: {
    frequency: 853,
    spanish: "responsabilidad",
    english: "responsibility • liability • accountability",
  },
  854: {
    frequency: 854,
    spanish: "bala",
    english: "bullet • bale • shot • ball",
  },
  855: {
    frequency: 855,
    spanish: "importantes",
    english: "important • significant • large • big • main • leading",
  },
  856: { frequency: 856, spanish: "grandioso", english: "grand • grandiose" },
  857: { frequency: 857, spanish: "kilómetros", english: "kilometer" },
  858: { frequency: 858, spanish: "laboratorio", english: "laboratory • lab" },
  859: { frequency: 859, spanish: "conseguido", english: "I got" },
  860: { frequency: 860, spanish: "bordo", english: "board" },
  861: {
    frequency: 861,
    spanish: "asustado",
    english: "frightened • afraid • startled",
  },
  862: { frequency: 862, spanish: "invitado", english: "invited • guest" },
  863: { frequency: 863, spanish: "tenia", english: "tapeworm" },
  864: { frequency: 864, spanish: "jurado", english: "jury • panel" },
  865: { frequency: 865, spanish: "profundo", english: "deep • profound" },
  866: { frequency: 866, spanish: "alemania", english: "Germany" },
  867: { frequency: 867, spanish: "americanos", english: "American" },
  868: { frequency: 868, spanish: "privado", english: "private • deprived" },
  869: { frequency: 869, spanish: "lunes", english: "Monday" },
  870: { frequency: 870, spanish: "robado", english: "stolen" },
  871: { frequency: 871, spanish: "derechos", english: "duty" },
  872: { frequency: 872, spanish: "invierno", english: "winter" },
  873: { frequency: 873, spanish: "plata", english: "silver" },
  874: { frequency: 874, spanish: "tercer", english: "third" },
  875: { frequency: 875, spanish: "obviamente", english: "obviously" },
  876: {
    frequency: 876,
    spanish: "recién",
    english: "newly • freshly • recently • just",
  },
  877: { frequency: 877, spanish: "árboles", english: "tree" },
  878: { frequency: 878, spanish: "techo", english: "ceiling • roof" },
  879: { frequency: 879, spanish: "haberme", english: "me" },
  880: { frequency: 880, spanish: "tomo", english: "volume • tome" },
  881: { frequency: 881, spanish: "campeón", english: "champion" },
  882: {
    frequency: 882,
    spanish: "últimamente",
    english: "lately • recently • of late",
  },
  883: {
    frequency: 883,
    spanish: "relaciones",
    english: "relations • relationship • intercourse",
  },
  884: {
    frequency: 884,
    spanish: "cobarde",
    english: "cowardly • coward • craven",
  },
  885: { frequency: 885, spanish: "tercera", english: "third" },
  886: { frequency: 886, spanish: "ex", english: "former • ex" },
  887: { frequency: 887, spanish: "secretos", english: "secret" },
  888: { frequency: 888, spanish: "pecho", english: "chest • breast • bosom" },
  889: { frequency: 889, spanish: "esquina", english: "corner" },
  890: { frequency: 890, spanish: "primeros", english: "first" },
  891: { frequency: 891, spanish: "sabido", english: "learned" },
  892: { frequency: 892, spanish: "corta", english: "cutting" },
  893: {
    frequency: 893,
    spanish: "artista",
    english: "artist • performer • entertainer",
  },
  894: { frequency: 894, spanish: "recuerdos", english: "regards" },
  895: { frequency: 895, spanish: "desaparecido", english: "missing" },
  896: { frequency: 896, spanish: "reino", english: "kingdom • realm" },
  897: { frequency: 897, spanish: "veinte", english: "twenty" },
  898: {
    frequency: 898,
    spanish: "seria",
    english: "serious • earnest • severe",
  },
  899: {
    frequency: 899,
    spanish: "almuerzo",
    english: "lunch • luncheon • dinner",
  },
  900: { frequency: 900, spanish: "condiciones", english: "terms" },
  901: {
    frequency: 901,
    spanish: "suficientemente",
    english: "enough • sufficiently • adequately",
  },
  902: { frequency: 902, spanish: "montañas", english: "mountains" },
  903: { frequency: 903, spanish: "violencia", english: "violence" },
  904: { frequency: 904, spanish: "presencia", english: "presence" },
  905: { frequency: 905, spanish: "poquito", english: "bit" },
  906: {
    frequency: 906,
    spanish: "fuertes",
    english: "strong • hard • sharp • loud • heavy • powerful • high • fort",
  },
  907: { frequency: 907, spanish: "labios", english: "lips" },
  908: { frequency: 908, spanish: "caray", english: "Wow! • Blimey!" },
  909: { frequency: 909, spanish: "teoría", english: "theory" },
  910: { frequency: 910, spanish: "tamaño", english: "size" },
  911: { frequency: 911, spanish: "virgen", english: "virgin" },
  912: { frequency: 912, spanish: "míos", english: "my" },
  913: { frequency: 913, spanish: "pelota", english: "ball" },
  914: { frequency: 914, spanish: "cuerpos", english: "body • corps" },
  915: { frequency: 915, spanish: "cientos", english: "hundreds" },
  916: {
    frequency: 916,
    spanish: "vaso",
    english: "glass • vessel • beaker • tumbler",
  },
  917: {
    frequency: 917,
    spanish: "despues",
    english: "after • later • afterwards • afterward • then • next",
  },
  918: { frequency: 918, spanish: "universo", english: "universe" },
  919: { frequency: 919, spanish: "salvaje", english: "wild • feral • savage" },
  920: {
    frequency: 920,
    spanish: "curso",
    english: "course • grade • progress • path",
  },
  921: {
    frequency: 921,
    spanish: "firma",
    english: "signature • firm • signing",
  },
  922: { frequency: 922, spanish: "queso", english: "cheese" },
  923: {
    frequency: 923,
    spanish: "pequeñas",
    english: "small • little • short • slight",
  },
  924: { frequency: 924, spanish: "solas", english: "alone" },
  925: { frequency: 925, spanish: "parado", english: "stationary" },
  926: { frequency: 926, spanish: "magia", english: "magic" },
  927: { frequency: 927, spanish: "casada", english: "married • wedded" },
  928: {
    frequency: 928,
    spanish: "mentiroso",
    english: "liar • deceiver • lying • FALSE • deceitful • untruthful",
  },
  929: { frequency: 929, spanish: "estómago", english: "stomach" },
  930: { frequency: 930, spanish: "aquello", english: "that" },
  931: {
    frequency: 931,
    spanish: "antiguo",
    english: "old • former • ancient • antique",
  },
  932: { frequency: 932, spanish: "judíos", english: "Jewish • Jew" },
  933: {
    frequency: 933,
    spanish: "aviones",
    english: "plane • airplane • aircraft",
  },
  934: {
    frequency: 934,
    spanish: "cadáver",
    english: "corpse • cadaver • body",
  },
  935: { frequency: 935, spanish: "autos", english: "car • auto" },
  936: { frequency: 936, spanish: "millas", english: "mile" },
  937: { frequency: 937, spanish: "cierta", english: "certain • sure" },
  938: { frequency: 938, spanish: "dioses", english: "God • Lord" },
  939: { frequency: 939, spanish: "comido", english: "eaten" },
  940: { frequency: 940, spanish: "emperador", english: "emperor" },
  941: { frequency: 941, spanish: "cigarrillo", english: "cigarette" },
  942: {
    frequency: 942,
    spanish: "helado",
    english: "ice cream • ice • frozen • icy • frosty • freezing",
  },
  943: { frequency: 943, spanish: "miembro", english: "member" },
  944: { frequency: 944, spanish: "felicidades", english: "Congratulations!" },
  945: {
    frequency: 945,
    spanish: "paciencia",
    english: "patience • forbearance",
  },
  946: { frequency: 946, spanish: "ó", english: "or" },
  947: { frequency: 947, spanish: "cristo", english: "Christ" },
  948: { frequency: 948, spanish: "invitados", english: "invited • guest" },
  949: {
    frequency: 949,
    spanish: "encantador",
    english: "charming • lovely • enchanting • delightful",
  },
  950: {
    frequency: 950,
    spanish: "felicitaciones",
    english: "congratulations",
  },
  951: {
    frequency: 951,
    spanish: "disparo",
    english: "shot • shooting • firing",
  },
  952: {
    frequency: 952,
    spanish: "ricos",
    english: "rich • wealthy • delicious",
  },
  953: {
    frequency: 953,
    spanish: "rápidamente",
    english: "quickly • quick • rapidly • speedily • fast • swiftly",
  },
  954: { frequency: 954, spanish: "acciones", english: "Actions" },
  955: {
    frequency: 955,
    spanish: "gobernador",
    english: "governor • gubernatorial",
  },
  956: { frequency: 956, spanish: "ciencia", english: "science • knowledge" },
  957: {
    frequency: 957,
    spanish: "pura",
    english: "pure • sheer • fresh • raw",
  },
  958: { frequency: 958, spanish: "números", english: "numbers" },
  959: { frequency: 959, spanish: "miembros", english: "member" },
  960: { frequency: 960, spanish: "sopa", english: "soup" },
  961: { frequency: 961, spanish: "adonde", english: "where to • where" },
  962: { frequency: 962, spanish: "limpia", english: "cleaning" },
  963: { frequency: 963, spanish: "canciones", english: "song • singing" },
  964: { frequency: 964, spanish: "orgullo", english: "pride" },
  965: {
    frequency: 965,
    spanish: "tarea",
    english: "task • job • homework • assignment",
  },
  966: {
    frequency: 966,
    spanish: "encantadora",
    english: "charming • lovely • enchanting • delightful",
  },
  967: { frequency: 967, spanish: "enviado", english: "envoy" },
  968: { frequency: 968, spanish: "compras", english: "shopping" },
  969: { frequency: 969, spanish: "caído", english: "fallen" },
  970: { frequency: 970, spanish: "casos", english: "case • instance" },
  971: {
    frequency: 971,
    spanish: "desgracia",
    english: "misfortune • disgrace • mishap",
  },
  972: { frequency: 972, spanish: "asesinado", english: "murdered" },
  973: { frequency: 973, spanish: "palacio", english: "palace" },
  974: { frequency: 974, spanish: "contado", english: "counted" },
  975: {
    frequency: 975,
    spanish: "amistad",
    english: "friendship • friendliness • amity",
  },
  976: { frequency: 976, spanish: "bola", english: "ball" },
  977: {
    frequency: 977,
    spanish: "educación",
    english: "education • school • upbringing",
  },
  978: { frequency: 978, spanish: "centavos", english: "penny • cent" },
  979: { frequency: 979, spanish: "llorando", english: "in tears" },
  980: { frequency: 980, spanish: "taza", english: "cup • bowl" },
  981: { frequency: 981, spanish: "madera", english: "wood • timber" },
  982: { frequency: 982, spanish: "amenaza", english: "threat • menace" },
  983: {
    frequency: 983,
    spanish: "mono",
    english: "monkey • ape • overalls • cute",
  },
  984: {
    frequency: 984,
    spanish: "periódicos",
    english: "newspaper • paper • journal • periodic • periodical",
  },
  985: {
    frequency: 985,
    spanish: "ocasión",
    english: "chance • opportunity • occasion • time",
  },
  986: { frequency: 986, spanish: "personalmente", english: "personally" },
  987: { frequency: 987, spanish: "estudiante", english: "student" },
  988: { frequency: 988, spanish: "consigo", english: "with him • with her" },
  989: { frequency: 989, spanish: "pájaro", english: "bird" },
  990: {
    frequency: 990,
    spanish: "sencillo",
    english: "simple • easy • straightforward",
  },
  991: { frequency: 991, spanish: "dejarme", english: "me" },
  992: { frequency: 992, spanish: "lección", english: "lesson" },
  993: { frequency: 993, spanish: "evidencia", english: "evidence" },
  994: { frequency: 994, spanish: "normalmente", english: "normally" },
  995: { frequency: 995, spanish: "indios", english: "India" },
  996: {
    frequency: 996,
    spanish: "uniforme",
    english: "uniform • even • uniformed",
  },
  997: {
    frequency: 997,
    spanish: "conciencia",
    english: "awareness • conscience",
  },
  998: {
    frequency: 998,
    spanish: "suave",
    english: "soft • gentle • sweet • mild",
  },
  999: { frequency: 999, spanish: "cuantos", english: "as many as" },
  1000: { frequency: 1000, spanish: "cero", english: "nil • nothing • zero" },
  1001: {
    frequency: 1001,
    spanish: "balas",
    english: "bullet • bale • shot • ball",
  },
  1002: { frequency: 1002, spanish: "tropas", english: "troops" },
  1003: { frequency: 1003, spanish: "alteza", english: "Highness" },
  1004: { frequency: 1004, spanish: "protección", english: "protection" },
  1005: { frequency: 1005, spanish: "vueltas", english: "laps" },
  1006: {
    frequency: 1006,
    spanish: "aparte",
    english: "apart • apart from • aside",
  },
  1007: {
    frequency: 1007,
    spanish: "venganza",
    english: "revenge • retaliation • vengeance • retribution",
  },
  1008: {
    frequency: 1008,
    spanish: "comisario",
    english: "commissar • deputy",
  },
  1009: { frequency: 1009, spanish: "familias", english: "family • household" },
  1010: { frequency: 1010, spanish: "lobo", english: "wolf" },
  1011: { frequency: 1011, spanish: "estudiantes", english: "student" },
  1012: { frequency: 1012, spanish: "espejo", english: "mirror • glass" },
  1013: {
    frequency: 1013,
    spanish: "bestia",
    english: "beast • animal • brute",
  },
  1014: {
    frequency: 1014,
    spanish: "médicos",
    english: "doctor • physician • medico • medical",
  },
  1015: { frequency: 1015, spanish: "inteligencia", english: "intelligence" },
  1016: {
    frequency: 1016,
    spanish: "curioso",
    english: "curious • inquisitive • intriguing",
  },
  1017: {
    frequency: 1017,
    spanish: "aburrido",
    english: "boring • dull • tiresome • tired",
  },
  1018: {
    frequency: 1018,
    spanish: "artículo",
    english: "article • item • section • paper",
  },
  1019: {
    frequency: 1019,
    spanish: "criatura",
    english: "creature • thing • infant",
  },
  1020: {
    frequency: 1020,
    spanish: "contenta",
    english: "happy • glad • contented • content • pleased • satisfied",
  },
  1021: {
    frequency: 1021,
    spanish: "diversión",
    english: "fun • diversion • amusement • entertainment",
  },
  1022: { frequency: 1022, spanish: "llamadas", english: "calls" },
  1023: {
    frequency: 1023,
    spanish: "excelencia",
    english: "excellence • excellency",
  },
  1024: {
    frequency: 1024,
    spanish: "nerviosa",
    english: "nervous • nerve • edgy • jittery",
  },
  1025: {
    frequency: 1025,
    spanish: "vecinos",
    english: "neighboring • next • neighbor • resident • local",
  },
  1026: { frequency: 1026, spanish: "camioneta", english: "van • wagon" },
  1027: { frequency: 1027, spanish: "azúcar", english: "sugar" },
  1028: { frequency: 1028, spanish: "amigas", english: "friend • buddy" },
  1029: {
    frequency: 1029,
    spanish: "feo",
    english: "ugly • unsightly • nasty",
  },
  1030: { frequency: 1030, spanish: "judío", english: "Jewish • Jew" },
  1031: {
    frequency: 1031,
    spanish: "antigua",
    english: "old • former • ancient • antique",
  },
  1032: { frequency: 1032, spanish: "rara", english: "rare • unusual • odd" },
  1033: { frequency: 1033, spanish: "amén", english: "amen" },
  1034: { frequency: 1034, spanish: "temporada", english: "season • time" },
  1035: {
    frequency: 1035,
    spanish: "convertido",
    english: "converted • turned",
  },
  1036: {
    frequency: 1036,
    spanish: "humanidad",
    english: "humanity • mankind • humankind",
  },
  1037: { frequency: 1037, spanish: "datos", english: "data • information" },
  1038: { frequency: 1038, spanish: "pocas", english: "little • short" },
  1039: {
    frequency: 1039,
    spanish: "asesinos",
    english: "murderer • killer • slayer • assassin • murderous",
  },
  1040: {
    frequency: 1040,
    spanish: "maleta",
    english: "suitcase • case • baggage • portmanteau",
  },
  1041: { frequency: 1041, spanish: "doce", english: "dozen • twelve" },
  1042: { frequency: 1042, spanish: "guardias", english: "guards" },
  1043: {
    frequency: 1043,
    spanish: "tontería",
    english: "foolishness • silliness",
  },
  1044: {
    frequency: 1044,
    spanish: "preocupada",
    english: "concerned • worried • preoccupied • troubled",
  },
  1045: { frequency: 1045, spanish: "honesto", english: "honest" },
  1046: { frequency: 1046, spanish: "miel", english: "honey" },
  1047: {
    frequency: 1047,
    spanish: "robó",
    english: "theft • stealing • thievery • robbery",
  },
  1048: { frequency: 1048, spanish: "pesadilla", english: "nightmare" },
  1049: { frequency: 1049, spanish: "huesos", english: "bones" },
  1050: { frequency: 1050, spanish: "enojado", english: "angry" },
  1051: { frequency: 1051, spanish: "muñeca", english: "wrist • doll" },
  1052: { frequency: 1052, spanish: "agentes", english: "agent" },
  1053: {
    frequency: 1053,
    spanish: "personaje",
    english: "character • personage",
  },
  1054: {
    frequency: 1054,
    spanish: "precisamente",
    english: "precisely • just • accurately",
  },
  1055: { frequency: 1055, spanish: "pasos", english: "steps" },
  1056: { frequency: 1056, spanish: "útil", english: "helpful • useful" },
  1057: { frequency: 1057, spanish: "revolución", english: "revolution" },
  1058: { frequency: 1058, spanish: "hermanas", english: "brother • sibling" },
  1059: { frequency: 1059, spanish: "comunidad", english: "community" },
  1060: { frequency: 1060, spanish: "senador", english: "senator" },
  1061: { frequency: 1061, spanish: "chino", english: "Chinese" },
  1062: { frequency: 1062, spanish: "favorito", english: "favorite" },
  1063: { frequency: 1063, spanish: "sótano", english: "cellar • basement" },
  1064: { frequency: 1064, spanish: "julio", english: "July" },
  1065: { frequency: 1065, spanish: "venta", english: "sale • sales" },
  1066: { frequency: 1066, spanish: "bolso", english: "bag • handbag • purse" },
  1067: {
    frequency: 1067,
    spanish: "tratado",
    english: "treaty • treatise • agreement",
  },
  1068: { frequency: 1068, spanish: "rodillas", english: "knees" },
  1069: {
    frequency: 1069,
    spanish: "magnífico",
    english:
      "magnificent • splendid • marvelous • grand • great • superb • beautiful",
  },
  1070: { frequency: 1070, spanish: "nación", english: "nation • people" },
  1071: { frequency: 1071, spanish: "vivido", english: "vivid" },
  1072: { frequency: 1072, spanish: "méxico", english: "Mexico" },
  1073: {
    frequency: 1073,
    spanish: "puro",
    english: "pure • sheer • fresh • raw",
  },
  1074: {
    frequency: 1074,
    spanish: "casualidad",
    english: "chance • accident • coincidence",
  },
  1075: {
    frequency: 1075,
    spanish: "castigo",
    english: "punishment • penalty • chastisement",
  },
  1076: {
    frequency: 1076,
    spanish: "máximo",
    english: "maximal • most • full • top • maximum • high • possible",
  },
  1077: { frequency: 1077, spanish: "cuáles", english: "which" },
  1078: { frequency: 1078, spanish: "resultados", english: "results" },
  1079: { frequency: 1079, spanish: "mirad", english: "Behold!" },
  1080: { frequency: 1080, spanish: "okay", english: "OK" },
  1081: { frequency: 1081, spanish: "computadora", english: "computer" },
  1082: {
    frequency: 1082,
    spanish: "víctimas",
    english: "victim • sufferer • casualty • prey",
  },
  1083: { frequency: 1083, spanish: "naturalmente", english: "naturally" },
  1084: {
    frequency: 1084,
    spanish: "tele",
    english: "TV • television • telly • tube",
  },
  1085: { frequency: 1085, spanish: "juntas", english: "together" },
  1086: { frequency: 1086, spanish: "estudios", english: "studies" },
  1087: { frequency: 1087, spanish: "jueves", english: "Thursday" },
  1088: {
    frequency: 1088,
    spanish: "preparados",
    english: "prepared • ready • preparation",
  },
  1089: {
    frequency: 1089,
    spanish: "concierto",
    english: "concert • gig • concerto",
  },
  1090: {
    frequency: 1090,
    spanish: "prisioneros",
    english: "prisoner • captive",
  },
  1091: { frequency: 1091, spanish: "adios", english: "adieu" },
  1092: { frequency: 1092, spanish: "peces", english: "fishes" },
  1093: { frequency: 1093, spanish: "comité", english: "committee" },
  1094: { frequency: 1094, spanish: "medianoche", english: "midnight" },
  1095: { frequency: 1095, spanish: "trabajado", english: "worked" },
  1096: {
    frequency: 1096,
    spanish: "pedazos",
    english: "piece • chunk • hunk • lump",
  },
  1097: {
    frequency: 1097,
    spanish: "interesado",
    english: "interested • concerned",
  },
  1098: { frequency: 1098, spanish: "seguido", english: "continued" },
  1099: { frequency: 1099, spanish: "sección", english: "section" },
  1100: { frequency: 1100, spanish: "seres", english: "being" },
  1101: { frequency: 1101, spanish: "documentos", english: "documents" },
  1102: { frequency: 1102, spanish: "hecha", english: "made" },
  1103: { frequency: 1103, spanish: "nuevamente", english: "newly • anew" },
  1104: { frequency: 1104, spanish: "dulces", english: "confectionery" },
  1105: { frequency: 1105, spanish: "martes", english: "Tuesday" },
  1106: {
    frequency: 1106,
    spanish: "caro",
    english: "expensive • pricey • costly • dearly • dear",
  },
  1107: { frequency: 1107, spanish: "equivocada", english: "wrong • mistaken" },
  1108: { frequency: 1108, spanish: "amado", english: "dear • beloved" },
  1109: { frequency: 1109, spanish: "templo", english: "temple • church" },
  1110: {
    frequency: 1110,
    spanish: "sabia",
    english: "wise • learned • sage • savant",
  },
  1111: { frequency: 1111, spanish: "medios", english: "means" },
  1112: { frequency: 1112, spanish: "pensamientos", english: "thinking" },
  1113: { frequency: 1113, spanish: "treinta", english: "thirty" },
  1114: { frequency: 1114, spanish: "hierba", english: "grass • herb" },
  1115: {
    frequency: 1115,
    spanish: "impresionante",
    english: "impressive • awesome",
  },
  1116: { frequency: 1116, spanish: "pagado", english: "paid" },
  1117: { frequency: 1117, spanish: "italia", english: "Italy" },
  1118: { frequency: 1118, spanish: "volando", english: "flying" },
  1119: { frequency: 1119, spanish: "tetas", english: "tits • boobs • jugs" },
  1120: { frequency: 1120, spanish: "pasión", english: "passion" },
  1121: {
    frequency: 1121,
    spanish: "prometido",
    english: "promised • fiance • fiancé",
  },
  1122: {
    frequency: 1122,
    spanish: "acusado",
    english: "accused • charged • indicted",
  },
  1123: { frequency: 1123, spanish: "lágrimas", english: "tears" },
  1124: { frequency: 1124, spanish: "agencia", english: "agency" },
  1125: { frequency: 1125, spanish: "dias", english: "day" },
  1126: { frequency: 1126, spanish: "gustado", english: "I liked" },
  1127: { frequency: 1127, spanish: "casados", english: "married • wedded" },
  1128: { frequency: 1128, spanish: "suicidio", english: "suicide" },
  1129: {
    frequency: 1129,
    spanish: "gasolina",
    english: "gasoline • petrol • gas",
  },
  1130: { frequency: 1130, spanish: "jugador", english: "player • gambler" },
  1131: { frequency: 1131, spanish: "rusia", english: "Russia" },
  1132: {
    frequency: 1132,
    spanish: "elegante",
    english:
      "elegant • graceful • refined • stylish • fashionable • smart • chic • posh",
  },
  1133: {
    frequency: 1133,
    spanish: "especiales",
    english: "special • particular",
  },
  1134: { frequency: 1134, spanish: "fiestas", english: "parties" },
  1135: { frequency: 1135, spanish: "océano", english: "ocean" },
  1136: { frequency: 1136, spanish: "ruta", english: "route • road • way" },
  1137: { frequency: 1137, spanish: "paraíso", english: "paradise • heaven" },
  1138: {
    frequency: 1138,
    spanish: "curiosidad",
    english: "curiosity • inquisitiveness",
  },
  1139: {
    frequency: 1139,
    spanish: "encantada",
    english: "delighted • haunted",
  },
  1140: {
    frequency: 1140,
    spanish: "poderoso",
    english: "powerful • potent • mighty",
  },
  1141: {
    frequency: 1141,
    spanish: "ladrones",
    english: "thief • robber • burglar • crook",
  },
  1142: { frequency: 1142, spanish: "sed", english: "thirst" },
  1143: { frequency: 1143, spanish: "regalos", english: "gifts" },
  1144: { frequency: 1144, spanish: "imágenes", english: "imagery" },
  1145: { frequency: 1145, spanish: "elegido", english: "elected" },
  1146: {
    frequency: 1146,
    spanish: "extranjero",
    english: "foreign • alien • stranger • foreigner",
  },
  1147: { frequency: 1147, spanish: "rata", english: "rat" },
  1148: {
    frequency: 1148,
    spanish: "instituto",
    english: "institute • school • institution",
  },
  1149: { frequency: 1149, spanish: "sales", english: "salts" },
  1150: { frequency: 1150, spanish: "rica", english: "rich" },
  1151: { frequency: 1151, spanish: "impuestos", english: "taxation" },
  1152: { frequency: 1152, spanish: "sacado", english: "taken" },
  1153: { frequency: 1153, spanish: "rusos", english: "Russian" },
  1154: { frequency: 1154, spanish: "ambulancia", english: "ambulance" },
  1155: { frequency: 1155, spanish: "aprendido", english: "learned" },
  1156: { frequency: 1156, spanish: "servicios", english: "services" },
  1157: { frequency: 1157, spanish: "vaca", english: "cow" },
  1158: { frequency: 1158, spanish: "territorio", english: "territory" },
  1159: {
    frequency: 1159,
    spanish: "fácilmente",
    english: "easily • easy • readily",
  },
  1160: {
    frequency: 1160,
    spanish: "conocimiento",
    english: "knowledge • awareness • consciousness • knowing",
  },
  1161: {
    frequency: 1161,
    spanish: "campaña",
    english: "campaign • countryside",
  },
  1162: {
    frequency: 1162,
    spanish: "suficientes",
    english: "enough • adequate • sufficient • plenty • ample",
  },
  1163: { frequency: 1163, spanish: "obvio", english: "obvious" },
  1164: {
    frequency: 1164,
    spanish: "disparó",
    english: "shot • shooting • firing",
  },
  1165: {
    frequency: 1165,
    spanish: "subtítulos",
    english: "subtitle • caption • subheading • subhead",
  },
  1166: { frequency: 1166, spanish: "escritor", english: "writer" },
  1167: { frequency: 1167, spanish: "página", english: "page" },
  1168: { frequency: 1168, spanish: "cigarrillos", english: "cigarette" },
  1169: { frequency: 1169, spanish: "japón", english: "Japan" },
  1170: {
    frequency: 1170,
    spanish: "paliza",
    english: "beating • thrashing • drubbing • licking • bashing • flogging",
  },
  1171: { frequency: 1171, spanish: "vuestros", english: "your" },
  1172: {
    frequency: 1172,
    spanish: "molesto",
    english:
      "annoying • bothersome • irritating • troublesome • inconvenient • obtrusive",
  },
  1173: { frequency: 1173, spanish: "recibí", english: "I received" },
  1174: {
    frequency: 1174,
    spanish: "armario",
    english: "closet • wardrobe • cabinet • locker • cupboard",
  },
  1175: {
    frequency: 1175,
    spanish: "instante",
    english: "instant • moment • minute",
  },
  1176: { frequency: 1176, spanish: "arroz", english: "rice" },
  1177: { frequency: 1177, spanish: "éstos", english: "these" },
  1178: { frequency: 1178, spanish: "piedad", english: "piety" },
  1179: { frequency: 1179, spanish: "maestra", english: "mistress" },
  1180: { frequency: 1180, spanish: "líneas", english: "lines" },
  1181: { frequency: 1181, spanish: "cámaras", english: "cameras" },
  1182: { frequency: 1182, spanish: "posibilidades", english: "odds" },
  1183: {
    frequency: 1183,
    spanish: "prisionero",
    english: "prisoner • captive",
  },
  1184: {
    frequency: 1184,
    spanish: "anterior",
    english:
      "previous • former • prior • anterior • preceding • past • front • before",
  },
  1185: { frequency: 1185, spanish: "fiebre", english: "fever • temperature" },
  1186: { frequency: 1186, spanish: "ésto", english: "this" },
  1187: {
    frequency: 1187,
    spanish: "esposas",
    english: "handcuffs • manacles",
  },
  1188: { frequency: 1188, spanish: "bolas", english: "balls" },
  1189: { frequency: 1189, spanish: "pacientes", english: "patient" },
  1190: {
    frequency: 1190,
    spanish: "pasillo",
    english: "hall • corridor • aisle • gangway • passage • passageway",
  },
  1191: {
    frequency: 1191,
    spanish: "ciertamente",
    english: "certainly • surely",
  },
  1192: {
    frequency: 1192,
    spanish: "asistente",
    english: "assistant • attendant",
  },
  1193: { frequency: 1193, spanish: "actriz", english: "actress" },
  1194: {
    frequency: 1194,
    spanish: "secundaria",
    english: "secondary • side • minor",
  },
  1195: {
    frequency: 1195,
    spanish: "garganta",
    english: "throat • gorge • gullet • neck",
  },
  1196: {
    frequency: 1196,
    spanish: "asustada",
    english: "frightened • afraid • startled",
  },
  1197: { frequency: 1197, spanish: "enfrente", english: "opposite • facing" },
  1198: { frequency: 1198, spanish: "museo", english: "museum" },
  1199: { frequency: 1199, spanish: "valle", english: "valley • vale" },
  1200: { frequency: 1200, spanish: "misterio", english: "mystery" },
  1201: { frequency: 1201, spanish: "ambas", english: "both" },
  1202: { frequency: 1202, spanish: "joyas", english: "jewelry • jewels" },
  1203: { frequency: 1203, spanish: "ventanas", english: "window" },
  1204: { frequency: 1204, spanish: "ruso", english: "Russian" },
  1205: { frequency: 1205, spanish: "celda", english: "cell" },
  1206: { frequency: 1206, spanish: "golpes", english: "knocking" },
  1207: { frequency: 1207, spanish: "áfrica", english: "Africa" },
  1208: { frequency: 1208, spanish: "paredes", english: "wall" },
  1209: { frequency: 1209, spanish: "ganador", english: "winner" },
  1210: {
    frequency: 1210,
    spanish: "reales",
    english: "actual • royal • real",
  },
  1211: { frequency: 1211, spanish: "biblia", english: "Bible" },
  1212: {
    frequency: 1212,
    spanish: "metro",
    english: "metro • subway • underground • tube • meter • m",
  },
  1213: { frequency: 1213, spanish: "cuantas", english: "how many" },
  1214: { frequency: 1214, spanish: "adorable", english: "adorable" },
  1215: { frequency: 1215, spanish: "alegre", english: "cheerful" },
  1216: { frequency: 1216, spanish: "cuán", english: "how" },
  1217: { frequency: 1217, spanish: "berlín", english: "berlin" },
  1218: {
    frequency: 1218,
    spanish: "circunstancias",
    english: "circumstances",
  },
  1219: {
    frequency: 1219,
    spanish: "prohibido",
    english: "prohibited • forbidden • barred • no",
  },
  1220: {
    frequency: 1220,
    spanish: "pesado",
    english: "heavy • heavyweight • burdensome",
  },
  1221: { frequency: 1221, spanish: "celular", english: "cellular" },
  1222: {
    frequency: 1222,
    spanish: "ambiente",
    english:
      "environment • atmosphere • ambience • surroundings • climate • ambient",
  },
  1223: { frequency: 1223, spanish: "privada", english: "private • deprived" },
  1224: { frequency: 1224, spanish: "dudas", english: "Doubts" },
  1225: { frequency: 1225, spanish: "botas", english: "boots" },
  1226: { frequency: 1226, spanish: "pene", english: "penis" },
  1227: {
    frequency: 1227,
    spanish: "experto",
    english: "skilled • experienced • knowledgeable • expert",
  },
  1228: { frequency: 1228, spanish: "veneno", english: "venom" },
  1229: {
    frequency: 1229,
    spanish: "partida",
    english: "departure • game • item • batch • entry",
  },
  1230: { frequency: 1230, spanish: "pájaros", english: "bird" },
  1231: {
    frequency: 1231,
    spanish: "bandera",
    english: "flag • banner • ensign",
  },
  1232: { frequency: 1232, spanish: "junta", english: "board • joint • junta" },
  1233: { frequency: 1233, spanish: "ciertas", english: "certain • sure" },
  1234: { frequency: 1234, spanish: "organización", english: "organization" },
  1235: { frequency: 1235, spanish: "irán", english: "Iran" },
  1236: { frequency: 1236, spanish: "cáncer", english: "cancer" },
  1237: {
    frequency: 1237,
    spanish: "deuda",
    english: "debt • indebtedness • due",
  },
  1238: {
    frequency: 1238,
    spanish: "extraños",
    english:
      "strange • weird • odd • peculiar • foreign • extraneous • bizarre • uncommon",
  },
  1239: {
    frequency: 1239,
    spanish: "juventud",
    english: "youth • youthfulness",
  },
  1240: {
    frequency: 1240,
    spanish: "igualmente",
    english: "equally • likewise • alike",
  },
  1241: { frequency: 1241, spanish: "poca", english: "little • short" },
  1242: {
    frequency: 1242,
    spanish: "pata",
    english: "leg • paw • foot • tab • duck • hoof",
  },
  1243: { frequency: 1243, spanish: "decente", english: "decent" },
  1244: {
    frequency: 1244,
    spanish: "producción",
    english: "production • product • output",
  },
  1245: { frequency: 1245, spanish: "aldea", english: "village • hamlet" },
  1246: {
    frequency: 1246,
    spanish: "letra",
    english: "letter • words • handwriting",
  },
  1247: { frequency: 1247, spanish: "biblioteca", english: "library" },
  1248: {
    frequency: 1248,
    spanish: "exterior",
    english: "outside • outer • exterior • foreign • external • outward • out",
  },
  1249: {
    frequency: 1249,
    spanish: "desagradable",
    english:
      "unpleasant • disagreeable • distasteful • unsavory • obnoxious • objectionable • displeasing",
  },
  1250: { frequency: 1250, spanish: "distrito", english: "district" },
  1251: {
    frequency: 1251,
    spanish: "actores",
    english: "actor • player • performer",
  },
  1252: {
    frequency: 1252,
    spanish: "patio",
    english: "patio • courtyard • court • yard • playground • quad",
  },
  1253: { frequency: 1253, spanish: "diamantes", english: "diamond" },
  1254: { frequency: 1254, spanish: "hijas", english: "son • child" },
  1255: { frequency: 1255, spanish: "mismas", english: "same • very • one" },
  1256: { frequency: 1256, spanish: "italiano", english: "Italian" },
  1257: { frequency: 1257, spanish: "saludos", english: "regards" },
  1258: { frequency: 1258, spanish: "ilegal", english: "illegal • unlawful" },
  1259: { frequency: 1259, spanish: "éstas", english: "these" },
  1260: { frequency: 1260, spanish: "colina", english: "hill" },
  1261: {
    frequency: 1261,
    spanish: "porquería",
    english: "filth • dirt • muck • rubbish • bullshit",
  },
  1262: { frequency: 1262, spanish: "alas", english: "wing" },
  1263: { frequency: 1263, spanish: "as", english: "ace" },
  1264: { frequency: 1264, spanish: "habitaciones", english: "room" },
  1265: {
    frequency: 1265,
    spanish: "arreglado",
    english: "arranged • settled",
  },
  1266: { frequency: 1266, spanish: "esperado", english: "expected • hoped" },
  1267: {
    frequency: 1267,
    spanish: "periodista",
    english: "journalist • reporter • newspaperman",
  },
  1268: {
    frequency: 1268,
    spanish: "emocionante",
    english: "exciting • thrilling • moving • emotional",
  },
  1269: { frequency: 1269, spanish: "tripulación", english: "crew • aircrew" },
  1270: {
    frequency: 1270,
    spanish: "afortunado",
    english: "lucky • fortunate",
  },
  1271: {
    frequency: 1271,
    spanish: "difíciles",
    english: "difficult • hard • tough • tricky",
  },
  1272: { frequency: 1272, spanish: "usado", english: "used" },
  1273: {
    frequency: 1273,
    spanish: "estupidez",
    english: "stupidity • foolishness • idiocy",
  },
  1274: { frequency: 1274, spanish: "circo", english: "circus • cirque" },
  1275: { frequency: 1275, spanish: "trabajadores", english: "workers" },
  1276: { frequency: 1276, spanish: "quince", english: "fifteen" },
  1277: {
    frequency: 1277,
    spanish: "cerdos",
    english: "pork • pig • hog • swine",
  },
  1278: { frequency: 1278, spanish: "león", english: "lion" },
  1279: { frequency: 1279, spanish: "orgullosa", english: "proud • prideful" },
  1280: {
    frequency: 1280,
    spanish: "olvidó",
    english: "oblivion • forgetfulness",
  },
  1281: { frequency: 1281, spanish: "ron", english: "rum" },
  1282: {
    frequency: 1282,
    spanish: "ciento",
    english: "hundred • one hundred",
  },
  1283: {
    frequency: 1283,
    spanish: "equipaje",
    english: "luggage • baggage • equipment",
  },
  1284: { frequency: 1284, spanish: "doctora", english: "female doctor" },
  1285: {
    frequency: 1285,
    spanish: "salsa",
    english: "sauce • gravy • dressing",
  },
  1286: {
    frequency: 1286,
    spanish: "escritorio",
    english: "desk • writing desk",
  },
  1287: { frequency: 1287, spanish: "ciudadanos", english: "citizens" },
  1288: {
    frequency: 1288,
    spanish: "traidor",
    english: "traitor • betrayer • treacherous • traitorous",
  },
  1289: { frequency: 1289, spanish: "carter", english: "sump" },
  1290: {
    frequency: 1290,
    spanish: "instrucciones",
    english: "instructions • directions",
  },
  1291: { frequency: 1291, spanish: "empezado", english: "started" },
  1292: { frequency: 1292, spanish: "españa", english: "Spain" },
  1293: { frequency: 1293, spanish: "haberle", english: "having" },
  1294: { frequency: 1294, spanish: "duque", english: "duke" },
  1295: { frequency: 1295, spanish: "eva", english: "Eve" },
  1296: { frequency: 1296, spanish: "huevo", english: "egg" },
  1297: { frequency: 1297, spanish: "criminales", english: "criminal • felon" },
  1298: {
    frequency: 1298,
    spanish: "aparentemente",
    english: "apparently • seemingly • ostensibly",
  },
  1299: { frequency: 1299, spanish: "condado", english: "county" },
  1300: { frequency: 1300, spanish: "religión", english: "religion" },
  1301: {
    frequency: 1301,
    spanish: "detenido",
    english: "arrested • detained • detainee",
  },
  1302: {
    frequency: 1302,
    spanish: "absurdo",
    english:
      "absurd • preposterous • nonsensical • silly • ridiculous • absurdity",
  },
  1303: { frequency: 1303, spanish: "cultura", english: "culture" },
  1304: { frequency: 1304, spanish: "ceremonia", english: "ceremony" },
  1305: {
    frequency: 1305,
    spanish: "gilipollas",
    english: "asshole • dickhead • ass • idiot • wanker • arsehole • shithead",
  },
  1306: { frequency: 1306, spanish: "pelotas", english: "balls" },
  1307: {
    frequency: 1307,
    spanish: "mínimo",
    english: "minimum • minimal • least",
  },
  1308: { frequency: 1308, spanish: "nubes", english: "clouds" },
  1309: { frequency: 1309, spanish: "gigante", english: "giant" },
  1310: { frequency: 1310, spanish: "sacerdote", english: "priest" },
  1311: { frequency: 1311, spanish: "americana", english: "American" },
  1312: {
    frequency: 1312,
    spanish: "coincidencia",
    english: "coincidence • agreement",
  },
  1313: { frequency: 1313, spanish: "ratas", english: "rat" },
  1314: { frequency: 1314, spanish: "carácter", english: "character • nature" },
  1315: { frequency: 1315, spanish: "celoso", english: "jealous • zealous" },
  1316: { frequency: 1316, spanish: "césar", english: "Caesar" },
  1317: { frequency: 1317, spanish: "barcos", english: "shipping" },
  1318: {
    frequency: 1318,
    spanish: "sesión",
    english: "session • sitting • meeting",
  },
  1319: { frequency: 1319, spanish: "almas", english: "soul • heart • spirit" },
  1320: { frequency: 1320, spanish: "sofá", english: "sofa • couch • settee" },
  1321: {
    frequency: 1321,
    spanish: "franceses",
    english: "French • Frenchman",
  },
  1322: {
    frequency: 1322,
    spanish: "tratamiento",
    english: "treatment • processing",
  },
  1323: {
    frequency: 1323,
    spanish: "bello",
    english: "beautiful • lovely • handsome • fair",
  },
  1324: {
    frequency: 1324,
    spanish: "sucia",
    english: "dirty • dingy • unclean • grubby • nasty",
  },
  1325: { frequency: 1325, spanish: "rumores", english: "hearsay" },
  1326: {
    frequency: 1326,
    spanish: "sincero",
    english: "sincere • honest • candid • genuine",
  },
  1327: {
    frequency: 1327,
    spanish: "leyenda",
    english: "legend • caption • tale",
  },
  1328: { frequency: 1328, spanish: "lenguaje", english: "language • speech" },
  1329: {
    frequency: 1329,
    spanish: "maletas",
    english: "suitcase • case • baggage • portmanteau",
  },
  1330: { frequency: 1330, spanish: "clima", english: "climate • weather" },
  1331: { frequency: 1331, spanish: "indio", english: "Indian" },
  1332: {
    frequency: 1332,
    spanish: "vecino",
    english: "neighboring • next • neighbor • resident • local",
  },
  1333: { frequency: 1333, spanish: "pública", english: "public" },
  1334: { frequency: 1334, spanish: "barato", english: "cheap • inexpensive" },
  1335: { frequency: 1335, spanish: "español", english: "Spanish • Spaniard" },
  1336: { frequency: 1336, spanish: "antonio", english: "Anthony" },
  1337: { frequency: 1337, spanish: "escondido", english: "hidden" },
  1338: { frequency: 1338, spanish: "bebés", english: "baby • babe" },
  1339: { frequency: 1339, spanish: "decisiones", english: "decision" },
  1340: { frequency: 1340, spanish: "demasiada", english: "too" },
  1341: {
    frequency: 1341,
    spanish: "fea",
    english: "ugly • unsightly • nasty",
  },
  1342: {
    frequency: 1342,
    spanish: "condenado",
    english: "condemned • doomed • damned • convicted",
  },
  1343: { frequency: 1343, spanish: "vuestras", english: "your" },
  1344: {
    frequency: 1344,
    spanish: "asombroso",
    english:
      "amazing • astonishing • astounding • staggering • surprising • startling • breathtaking",
  },
  1345: { frequency: 1345, spanish: "pánico", english: "panic" },
  1346: { frequency: 1346, spanish: "cogido", english: "taken" },
  1347: { frequency: 1347, spanish: "asegúrate", english: "sure" },
  1348: {
    frequency: 1348,
    spanish: "helicóptero",
    english: "helicopter • chopper",
  },
  1349: { frequency: 1349, spanish: "pasaporte", english: "passport" },
  1350: { frequency: 1350, spanish: "gorda", english: "fat woman" },
  1351: { frequency: 1351, spanish: "bicicleta", english: "bicycle • bike" },
  1352: { frequency: 1352, spanish: "cincuenta", english: "fifty" },
  1353: { frequency: 1353, spanish: "tíos", english: "uncle • guy • chap" },
  1354: { frequency: 1354, spanish: "objeto", english: "object • purpose" },
  1355: { frequency: 1355, spanish: "coraje", english: "courage • guts" },
  1356: { frequency: 1356, spanish: "aceite", english: "oil" },
  1357: {
    frequency: 1357,
    spanish: "publicidad",
    english: "advertising • publicity",
  },
  1358: { frequency: 1358, spanish: "botón", english: "button • knob" },
  1359: {
    frequency: 1359,
    spanish: "cristal",
    english: "glass • crystal • pane",
  },
  1360: {
    frequency: 1360,
    spanish: "moneda",
    english: "currency • coin • money",
  },
  1361: { frequency: 1361, spanish: "favorita", english: "favorite" },
  1362: { frequency: 1362, spanish: "japoneses", english: "Japanese" },
  1363: { frequency: 1363, spanish: "septiembre", english: "September" },
  1364: {
    frequency: 1364,
    spanish: "abandonado",
    english: "abandoned • deserted",
  },
  1365: { frequency: 1365, spanish: "petróleo", english: "oil • petroleum" },
  1366: { frequency: 1366, spanish: "archivos", english: "records" },
  1367: { frequency: 1367, spanish: "versión", english: "version • edition" },
  1368: { frequency: 1368, spanish: "echado", english: "lying • laid" },
  1369: { frequency: 1369, spanish: "ciudades", english: "city • town" },
  1370: {
    frequency: 1370,
    spanish: "dormitorio",
    english: "bedroom • dormitory",
  },
  1371: {
    frequency: 1371,
    spanish: "prima",
    english: "premium • bonus • cousin • prime",
  },
  1372: {
    frequency: 1372,
    spanish: "cartera",
    english: "portfolio • wallet • briefcase • purse • bag",
  },
  1373: { frequency: 1373, spanish: "muro", english: "wall" },
  1374: {
    frequency: 1374,
    spanish: "mediodía",
    english: "noon • midday • noonday • south • noontime",
  },
  1375: { frequency: 1375, spanish: "pasas", english: "raisins" },
  1376: { frequency: 1376, spanish: "romántico", english: "romantic" },
  1377: { frequency: 1377, spanish: "internacional", english: "international" },
  1378: {
    frequency: 1378,
    spanish: "competencia",
    english: "competition • competence • competency • proficiency",
  },
  1379: { frequency: 1379, spanish: "esclavos", english: "slave" },
  1380: { frequency: 1380, spanish: "tecnología", english: "technology" },
  1381: {
    frequency: 1381,
    spanish: "verdaderamente",
    english: "truly • really • genuinely • indeed",
  },
  1382: { frequency: 1382, spanish: "sobrino", english: "nephew" },
  1383: {
    frequency: 1383,
    spanish: "principios",
    english: "beginning • beginnings",
  },
  1384: { frequency: 1384, spanish: "ruedas", english: "wheel" },
  1385: { frequency: 1385, spanish: "mensajes", english: "message" },
  1386: {
    frequency: 1386,
    spanish: "desconocido",
    english: "strange • unfamiliar • unknown • stranger",
  },
  1387: { frequency: 1387, spanish: "ascensor", english: "elevator" },
  1388: { frequency: 1388, spanish: "crema", english: "cream • custard" },
  1389: { frequency: 1389, spanish: "entrado", english: "I entered" },
  1390: {
    frequency: 1390,
    spanish: "razonable",
    english: "reasonable • fair • sensible • reasonably",
  },
  1391: { frequency: 1391, spanish: "miércoles", english: "Wednesday" },
  1392: { frequency: 1392, spanish: "calidad", english: "quality • grade" },
  1393: { frequency: 1393, spanish: "identidad", english: "identity" },
  1394: { frequency: 1394, spanish: "alli", english: "there" },
  1395: { frequency: 1395, spanish: "moto", english: "motorbike • scooter" },
  1396: { frequency: 1396, spanish: "detalle", english: "detail" },
  1397: { frequency: 1397, spanish: "tocado", english: "touched • headdress" },
  1398: { frequency: 1398, spanish: "conejo", english: "rabbit • coney" },
  1399: { frequency: 1399, spanish: "sentada", english: "sitting" },
  1400: { frequency: 1400, spanish: "obras", english: "works" },
  1401: { frequency: 1401, spanish: "nervios", english: "jitters" },
  1402: { frequency: 1402, spanish: "junio", english: "June" },
  1403: {
    frequency: 1403,
    spanish: "grano",
    english: "grain • kernel • pimple",
  },
  1404: { frequency: 1404, spanish: "intereses", english: "interests" },
  1405: { frequency: 1405, spanish: "darnos", english: "us" },
  1406: {
    frequency: 1406,
    spanish: "novela",
    english: "novel • story • romance",
  },
  1407: { frequency: 1407, spanish: "recibo", english: "receipt" },
  1408: {
    frequency: 1408,
    spanish: "resistencia",
    english: "resistance • strength • endurance • resilience",
  },
  1409: {
    frequency: 1409,
    spanish: "delicioso",
    english: "delicious • delectable • luscious",
  },
  1410: {
    frequency: 1410,
    spanish: "infeliz",
    english: "unhappy • unfortunate • wretch",
  },
  1411: { frequency: 1411, spanish: "cinturón", english: "belt" },
  1412: { frequency: 1412, spanish: "dragón", english: "dragon • dragoon" },
  1413: {
    frequency: 1413,
    spanish: "peores",
    english: "worst • worse • worse off",
  },
  1414: {
    frequency: 1414,
    spanish: "científico",
    english: "scientific • scientist",
  },
  1415: { frequency: 1415, spanish: "cantante", english: "singer" },
  1416: { frequency: 1416, spanish: "rosas", english: "pink • rose" },
  1417: { frequency: 1417, spanish: "poli", english: "cop" },
  1418: {
    frequency: 1418,
    spanish: "pasajeros",
    english: "passenger • rider • passing • transient • fleeting",
  },
  1419: { frequency: 1419, spanish: "almorzar", english: "lunch • luncheon" },
  1420: { frequency: 1420, spanish: "heridas", english: "wounded • injured" },
  1421: { frequency: 1421, spanish: "queridos", english: "dear • darling" },
  1422: { frequency: 1422, spanish: "países", english: "countries" },
  1423: { frequency: 1423, spanish: "cometido", english: "task" },
  1424: { frequency: 1424, spanish: "industria", english: "industry" },
  1425: { frequency: 1425, spanish: "oídos", english: "ear • hearing" },
  1426: { frequency: 1426, spanish: "flota", english: "fleet • navy" },
  1427: { frequency: 1427, spanish: "tina", english: "tub • vat" },
  1428: {
    frequency: 1428,
    spanish: "político",
    english: "political • politician",
  },
  1429: { frequency: 1429, spanish: "imperio", english: "empire • imperial" },
  1430: {
    frequency: 1430,
    spanish: "solitario",
    english:
      "solitary • lonely • lone • alone • desolate • lonesome • solitaire",
  },
  1431: { frequency: 1431, spanish: "jesucristo", english: "Christ" },
  1432: { frequency: 1432, spanish: "rubia", english: "blonde" },
  1433: { frequency: 1433, spanish: "criaturas", english: "creatures" },
  1434: {
    frequency: 1434,
    spanish: "cabaña",
    english: "cabin • cottage • cabana • hut",
  },
  1435: {
    frequency: 1435,
    spanish: "militares",
    english: "military • soldier",
  },
  1436: { frequency: 1436, spanish: "siglos", english: "ages" },
  1437: {
    frequency: 1437,
    spanish: "expresión",
    english: "expression • phrase",
  },
  1438: {
    frequency: 1438,
    spanish: "fiel",
    english: "faithful • TRUE • loyal • accurate • devoted • trusty",
  },
  1439: { frequency: 1439, spanish: "moscú", english: "Moscow" },
  1440: {
    frequency: 1440,
    spanish: "secretario",
    english: "secretary • clerk • registrar",
  },
  1441: { frequency: 1441, spanish: "cuartel", english: "barracks • quarter" },
  1442: { frequency: 1442, spanish: "soñando", english: "dreaming" },
  1443: {
    frequency: 1443,
    spanish: "fabuloso",
    english: "fabulous • fantastic • fabled",
  },
  1444: { frequency: 1444, spanish: "básicamente", english: "basically" },
  1445: { frequency: 1445, spanish: "gritos", english: "shouting" },
  1446: { frequency: 1446, spanish: "gatos", english: "cat • jack" },
  1447: { frequency: 1447, spanish: "túnel", english: "tunnel" },
  1448: { frequency: 1448, spanish: "arrestado", english: "detained" },
  1449: {
    frequency: 1449,
    spanish: "identificación",
    english: "ID • identification",
  },
  1450: { frequency: 1450, spanish: "secreta", english: "secret" },
  1451: {
    frequency: 1451,
    spanish: "cañón",
    english: "cannon • canyon • barrel • gun • tube • gorge",
  },
  1452: {
    frequency: 1452,
    spanish: "bonitas",
    english: "nice • beautiful • pretty",
  },
  1453: { frequency: 1453, spanish: "izquierdo", english: "left" },
  1454: { frequency: 1454, spanish: "gastos", english: "expenses • expense" },
  1455: { frequency: 1455, spanish: "once", english: "eleven" },
  1456: {
    frequency: 1456,
    spanish: "preciso",
    english: "precise • accurate • necessary",
  },
  1457: { frequency: 1457, spanish: "rueda", english: "wheel" },
  1458: { frequency: 1458, spanish: "despedido", english: "dismissed" },
  1459: { frequency: 1459, spanish: "autoridades", english: "authorities" },
  1460: {
    frequency: 1460,
    spanish: "prácticamente",
    english: "practically • virtually",
  },
  1461: { frequency: 1461, spanish: "tanque", english: "tank" },
  1462: { frequency: 1462, spanish: "vehículo", english: "vehicle" },
  1463: {
    frequency: 1463,
    spanish: "extrañas",
    english:
      "strange • weird • odd • peculiar • foreign • extraneous • bizarre • uncommon",
  },
  1464: { frequency: 1464, spanish: "viuda", english: "widow" },
  1465: { frequency: 1465, spanish: "salvado", english: "bran" },
  1466: { frequency: 1466, spanish: "audiencia", english: "audience" },
  1467: {
    frequency: 1467,
    spanish: "salvajes",
    english: "wild • feral • savage",
  },
  1468: { frequency: 1468, spanish: "cuales", english: "which" },
  1469: { frequency: 1469, spanish: "orejas", english: "ears" },
  1470: {
    frequency: 1470,
    spanish: "ingleses",
    english: "English • Englishman",
  },
  1471: {
    frequency: 1471,
    spanish: "concurso",
    english: "competition • contest",
  },
  1472: {
    frequency: 1472,
    spanish: "alternativa",
    english: "alternative • choice",
  },
  1473: {
    frequency: 1473,
    spanish: "extraordinario",
    english:
      "extraordinary • special • extra • remarkable • outstanding • unusual",
  },
  1474: {
    frequency: 1474,
    spanish: "maricón",
    english: "fagot • fag • fairy • poof",
  },
  1475: {
    frequency: 1475,
    spanish: "egoísta",
    english: "selfish • egoistic • egoist",
  },
  1476: {
    frequency: 1476,
    spanish: "vestidos",
    english: "clothes • apparel • garments • clothing",
  },
  1477: { frequency: 1477, spanish: "tragedia", english: "tragedy • drama" },
  1478: {
    frequency: 1478,
    spanish: "armada",
    english: "navy • armada • fleet",
  },
  1479: { frequency: 1479, spanish: "golpeó", english: "knocking" },
  1480: { frequency: 1480, spanish: "gafas", english: "glasses • spectacles" },
  1481: {
    frequency: 1481,
    spanish: "profundamente",
    english: "deeply • deep • profoundly • heavily",
  },
  1482: {
    frequency: 1482,
    spanish: "olvido",
    english: "oblivion • forgetfulness",
  },
  1483: { frequency: 1483, spanish: "payaso", english: "clown" },
  1484: { frequency: 1484, spanish: "casó", english: "case • instance" },
  1485: { frequency: 1485, spanish: "tirado", english: "lying" },
  1486: {
    frequency: 1486,
    spanish: "sensible",
    english: "sensitive • tender • noticeable • sentient",
  },
  1487: {
    frequency: 1487,
    spanish: "existencia",
    english: "existence • being • stock • life",
  },
  1488: {
    frequency: 1488,
    spanish: "unidades",
    english: "unit • unity • oneness",
  },
  1489: { frequency: 1489, spanish: "clínica", english: "clinic" },
  1490: { frequency: 1490, spanish: "barón", english: "baron" },
  1491: {
    frequency: 1491,
    spanish: "incidente",
    english: "incident • occurrence",
  },
  1492: { frequency: 1492, spanish: "secretaria", english: "Secretary" },
  1493: { frequency: 1493, spanish: "rancho", english: "ranch" },
  1494: { frequency: 1494, spanish: "està", english: "this • this one" },
  1495: {
    frequency: 1495,
    spanish: "significado",
    english: "meaning • significance",
  },
  1496: {
    frequency: 1496,
    spanish: "famosa",
    english: "famous • renowned • famed • celebrated",
  },
  1497: { frequency: 1497, spanish: "vendido", english: "sold" },
  1498: { frequency: 1498, spanish: "temperatura", english: "temperature" },
  1499: { frequency: 1499, spanish: "equipos", english: "hardware" },
  1500: { frequency: 1500, spanish: "efectos", english: "effects" },
  1501: { frequency: 1501, spanish: "terroristas", english: "terrorist" },
  1502: {
    frequency: 1502,
    spanish: "medias",
    english: "tights • hose • hosiery",
  },
  1503: { frequency: 1503, spanish: "sagrado", english: "sacred • holy" },
  1504: { frequency: 1504, spanish: "tio", english: "uncle • guy • chap" },
  1505: { frequency: 1505, spanish: "física", english: "physics" },
  1506: {
    frequency: 1506,
    spanish: "reacción",
    english: "reaction • response • feedback",
  },
  1507: {
    frequency: 1507,
    spanish: "crímenes",
    english: "crime • offense • felony",
  },
  1508: {
    frequency: 1508,
    spanish: "operaciones",
    english: "operation • transaction",
  },
  1509: { frequency: 1509, spanish: "tigre", english: "tiger" },
  1510: {
    frequency: 1510,
    spanish: "monedas",
    english: "currency • coin • money",
  },
  1511: {
    frequency: 1511,
    spanish: "aprecio",
    english: "appreciation • esteem",
  },
  1512: { frequency: 1512, spanish: "carreras", english: "racing" },
  1513: { frequency: 1513, spanish: "destrucción", english: "destruction" },
  1514: { frequency: 1514, spanish: "azules", english: "blue" },
  1515: {
    frequency: 1515,
    spanish: "pagó",
    english: "payment • paying • fee • repayment • payout",
  },
  1516: { frequency: 1516, spanish: "josé", english: "Joseph" },
  1517: { frequency: 1517, spanish: "perdedor", english: "loser" },
  1518: { frequency: 1518, spanish: "nene", english: "baby • babe" },
  1519: {
    frequency: 1519,
    spanish: "construcción",
    english: "construction • building",
  },
  1520: {
    frequency: 1520,
    spanish: "científicos",
    english: "scientific • scientist",
  },
  1521: {
    frequency: 1521,
    spanish: "delito",
    english: "crime • offense • felony • misdemeanor",
  },
  1522: { frequency: 1522, spanish: "guitarra", english: "guitar" },
  1523: { frequency: 1523, spanish: "bebido", english: "drunk" },
  1524: {
    frequency: 1524,
    spanish: "vendedor",
    english: "seller • vendor • salesman",
  },
  1525: { frequency: 1525, spanish: "consecuencias", english: "impact" },
  1526: { frequency: 1526, spanish: "personalidad", english: "personality" },
  1527: {
    frequency: 1527,
    spanish: "disparos",
    english: "shot • shooting • firing",
  },
  1528: { frequency: 1528, spanish: "traducción", english: "translation" },
  1529: {
    frequency: 1529,
    spanish: "pastillas",
    english: "tablet • lozenge • bar • chip • pastille",
  },
  1530: { frequency: 1530, spanish: "espacial", english: "space • spatial" },
  1531: {
    frequency: 1531,
    spanish: "cadáveres",
    english: "corpse • cadaver • body",
  },
  1532: {
    frequency: 1532,
    spanish: "disponible",
    english: "available • disposable",
  },
  1533: { frequency: 1533, spanish: "habilidad", english: "ability • skill" },
  1534: { frequency: 1534, spanish: "galletas", english: "cookies" },
  1535: { frequency: 1535, spanish: "estâ", english: "this • this one" },
  1536: { frequency: 1536, spanish: "remedio", english: "remedy" },
  1537: { frequency: 1537, spanish: "aniversario", english: "anniversary" },
  1538: { frequency: 1538, spanish: "generación", english: "generation" },
  1539: { frequency: 1539, spanish: "discos", english: "discs" },
  1540: {
    frequency: 1540,
    spanish: "políticos",
    english: "political • politician",
  },
  1541: {
    frequency: 1541,
    spanish: "marica",
    english: "sissy • pansy • queen",
  },
  1542: { frequency: 1542, spanish: "adultos", english: "adult" },
  1543: { frequency: 1543, spanish: "encerrado", english: "locked" },
  1544: { frequency: 1544, spanish: "caos", english: "chaos" },
  1545: { frequency: 1545, spanish: "súper", english: "super" },
  1546: {
    frequency: 1546,
    spanish: "peligrosa",
    english: "dangerous • hazardous • risky • perilous",
  },
  1547: { frequency: 1547, spanish: "amantes", english: "lover • mistress" },
  1548: {
    frequency: 1548,
    spanish: "prostituta",
    english: "prostitute • whore • harlot • hooker",
  },
  1549: { frequency: 1549, spanish: "probado", english: "tried • proven" },
  1550: { frequency: 1550, spanish: "estúpidos", english: "stupid • idiotic" },
  1551: {
    frequency: 1551,
    spanish: "auténtico",
    english: "authentic • real • genuine • TRUE",
  },
  1552: { frequency: 1552, spanish: "condesa", english: "countess" },
  1553: { frequency: 1553, spanish: "equivocas", english: "are wrong" },
  1554: { frequency: 1554, spanish: "estadounidense", english: "American" },
  1555: { frequency: 1555, spanish: "octubre", english: "October" },
  1556: {
    frequency: 1556,
    spanish: "poesía",
    english: "poetry • verse • poem",
  },
  1557: { frequency: 1557, spanish: "heridos", english: "wounded • injured" },
  1558: { frequency: 1558, spanish: "preparada", english: "prepared • ready" },
  1559: { frequency: 1559, spanish: "electricidad", english: "electricity" },
  1560: { frequency: 1560, spanish: "puesta", english: "putting • setting" },
  1561: {
    frequency: 1561,
    spanish: "consciente",
    english: "aware • conscious",
  },
  1562: { frequency: 1562, spanish: "reverendo", english: "reverend • Rev ." },
  1563: { frequency: 1563, spanish: "perdidos", english: "lost • missing" },
  1564: { frequency: 1564, spanish: "amarillo", english: "yellow" },
  1565: { frequency: 1565, spanish: "logrado", english: "accomplished" },
  1566: { frequency: 1566, spanish: "primeras", english: "first" },
  1567: {
    frequency: 1567,
    spanish: "hojas",
    english: "sheet • leaf • blade • foil",
  },
  1568: { frequency: 1568, spanish: "comisaría", english: "commissariat" },
  1569: { frequency: 1569, spanish: "hueso", english: "bone" },
  1570: { frequency: 1570, spanish: "héroes", english: "hero" },
  1571: {
    frequency: 1571,
    spanish: "barba",
    english: "beard • whiskers • chin",
  },
  1572: {
    frequency: 1572,
    spanish: "sorprendente",
    english: "surprising • astonishing • astounding",
  },
  1573: { frequency: 1573, spanish: "empleado", english: "employee • used" },
  1574: {
    frequency: 1574,
    spanish: "suyos",
    english: "his • yours • theirs • hers • their • its",
  },
  1575: { frequency: 1575, spanish: "toro", english: "bull • torus" },
  1576: { frequency: 1576, spanish: "comunicación", english: "communication" },
  1577: { frequency: 1577, spanish: "francesa", english: "French • Frenchman" },
  1578: { frequency: 1578, spanish: "suele", english: "usually" },
  1579: { frequency: 1579, spanish: "creado", english: "created" },
  1580: { frequency: 1580, spanish: "tuyos", english: "yours" },
  1581: {
    frequency: 1581,
    spanish: "lealtad",
    english: "loyalty • allegiance • devotion",
  },
  1582: {
    frequency: 1582,
    spanish: "entré",
    english: "between • among • amongst • within",
  },
  1583: { frequency: 1583, spanish: "cubierto", english: "covered" },
  1584: { frequency: 1584, spanish: "fondos", english: "funds • funding" },
  1585: {
    frequency: 1585,
    spanish: "inocentes",
    english: "naive • guiltless • blameless • innocent",
  },
  1586: { frequency: 1586, spanish: "champán", english: "champagne" },
  1587: { frequency: 1587, spanish: "algun", english: "some • any" },
  1588: {
    frequency: 1588,
    spanish: "estupenda",
    english:
      "great • super • wonderful • marvelous • terrific • fantastic • stupendous",
  },
  1589: { frequency: 1589, spanish: "convencido", english: "convinced" },
  1590: { frequency: 1590, spanish: "enojada", english: "angry" },
  1591: { frequency: 1591, spanish: "harta", english: "sick" },
  1592: { frequency: 1592, spanish: "nina", english: "girl" },
  1593: { frequency: 1593, spanish: "opciones", english: "option • choice" },
  1594: {
    frequency: 1594,
    spanish: "cojones",
    english: "balls • bollocks • nuts",
  },
  1595: { frequency: 1595, spanish: "reyes", english: "king" },
  1596: {
    frequency: 1596,
    spanish: "cabina",
    english: "cabin • cab • booth • cubicle",
  },
  1597: { frequency: 1597, spanish: "haberla", english: "having" },
  1598: {
    frequency: 1598,
    spanish: "fracaso",
    english: "failure • flop • breakdown • fiasco",
  },
  1599: { frequency: 1599, spanish: "santos", english: "Saints" },
  1600: { frequency: 1600, spanish: "guau", english: "woof" },
  1601: {
    frequency: 1601,
    spanish: "falla",
    english: "failure • fault • flaw",
  },
  1602: { frequency: 1602, spanish: "centavo", english: "penny • cent" },
  1603: { frequency: 1603, spanish: "poema", english: "poem" },
  1604: { frequency: 1604, spanish: "bromas", english: "joking" },
  1605: { frequency: 1605, spanish: "madres", english: "mother • parent" },
  1606: { frequency: 1606, spanish: "velas", english: "candles" },
  1607: {
    frequency: 1607,
    spanish: "francamente",
    english: "frankly • honestly • openly • outright • bluntly • plainly",
  },
  1608: { frequency: 1608, spanish: "japonés", english: "Japanese" },
  1609: { frequency: 1609, spanish: "plantas", english: "plants" },
  1610: { frequency: 1610, spanish: "angeles", english: "angel" },
  1611: { frequency: 1611, spanish: "tradición", english: "tradition" },
  1612: { frequency: 1612, spanish: "informes", english: "information" },
  1613: { frequency: 1613, spanish: "sufrido", english: "long-suffering" },
  1614: { frequency: 1614, spanish: "móvil", english: "mobile • movable" },
  1615: {
    frequency: 1615,
    spanish: "compasión",
    english: "compassion • pity • sympathy • amiability • graciousness",
  },
  1616: { frequency: 1616, spanish: "tokio", english: "tokyo" },
  1617: { frequency: 1617, spanish: "prestado", english: "borrowed" },
  1618: {
    frequency: 1618,
    spanish: "camarero",
    english: "waiter • server • steward • barman",
  },
  1619: {
    frequency: 1619,
    spanish: "pendejo",
    english: "stupid • motherfucker • coward",
  },
  1620: { frequency: 1620, spanish: "collar", english: "necklace • collar" },
  1621: { frequency: 1621, spanish: "bastardos", english: "bastard" },
  1622: {
    frequency: 1622,
    spanish: "agradecido",
    english: "grateful • thankful • appreciative • obliged • indebted",
  },
  1623: { frequency: 1623, spanish: "maquillaje", english: "makeup" },
  1624: { frequency: 1624, spanish: "almirante", english: "admiral" },
  1625: { frequency: 1625, spanish: "destruido", english: "destroyed" },
  1626: { frequency: 1626, spanish: "lujo", english: "luxury • luxe" },
  1627: { frequency: 1627, spanish: "caridad", english: "charity" },
  1628: { frequency: 1628, spanish: "capaces", english: "able • capable" },
  1629: { frequency: 1629, spanish: "bancos", english: "bank • bench" },
  1630: {
    frequency: 1630,
    spanish: "atractiva",
    english: "attractive • sexy • appealing • inviting • engaging",
  },
  1631: { frequency: 1631, spanish: "cabrones", english: "motherfuckers" },
  1632: {
    frequency: 1632,
    spanish: "productor",
    english: "producer • producing",
  },
  1633: { frequency: 1633, spanish: "jodida", english: "fucking" },
  1634: {
    frequency: 1634,
    spanish: "fama",
    english: "fame • renown • reputation • repute",
  },
  1635: { frequency: 1635, spanish: "maíz", english: "corn • maize" },
  1636: { frequency: 1636, spanish: "gris", english: "gray" },
  1637: { frequency: 1637, spanish: "jovencita", english: "young" },
  1638: {
    frequency: 1638,
    spanish: "familiares",
    english: "family • familiar • relative",
  },
  1639: {
    frequency: 1639,
    spanish: "congreso",
    english: "congress • conference",
  },
  1640: { frequency: 1640, spanish: "cuarenta", english: "forty • fortieth" },
  1641: {
    frequency: 1641,
    spanish: "negativo",
    english: "minus • adverse • negative",
  },
  1642: { frequency: 1642, spanish: "demasiados", english: "too many" },
  1643: {
    frequency: 1643,
    spanish: "traición",
    english: "betrayal • treason • treachery",
  },
  1644: { frequency: 1644, spanish: "civiles", english: "civil • civilian" },
  1645: { frequency: 1645, spanish: "frecuencia", english: "frequency" },
  1646: {
    frequency: 1646,
    spanish: "mago",
    english: "magician • wizard • magus",
  },
  1647: { frequency: 1647, spanish: "béisbol", english: "baseball" },
  1648: { frequency: 1648, spanish: "parientes", english: "kin • kindred" },
  1649: {
    frequency: 1649,
    spanish: "limpieza",
    english: "cleaning • cleanup • cleanliness",
  },
  1650: {
    frequency: 1650,
    spanish: "listas",
    english: "list • table • roster",
  },
  1651: { frequency: 1651, spanish: "enhorabuena", english: "congratulations" },
  1652: {
    frequency: 1652,
    spanish: "satisfecho",
    english: "satisfied • pleased • happy • glad • content • contented",
  },
  1653: { frequency: 1653, spanish: "papas", english: "potatoes" },
  1654: { frequency: 1654, spanish: "compañera", english: "girlfriend" },
  1655: {
    frequency: 1655,
    spanish: "fantástica",
    english: "fantastic • fantastical",
  },
  1656: { frequency: 1656, spanish: "idioma", english: "language • tongue" },
  1657: { frequency: 1657, spanish: "cueva", english: "cave • cavern" },
  1658: {
    frequency: 1658,
    spanish: "batería",
    english: "battery • drums • drummer",
  },
  1659: { frequency: 1659, spanish: "técnica", english: "technique" },
  1660: { frequency: 1660, spanish: "tanques", english: "tank" },
  1661: { frequency: 1661, spanish: "enfermos", english: "sick" },
  1662: {
    frequency: 1662,
    spanish: "cuentos",
    english: "story • tale • fairy tale • yarn",
  },
  1663: { frequency: 1663, spanish: "deudas", english: "liabilities" },
  1664: {
    frequency: 1664,
    spanish: "hoja",
    english: "sheet • leaf • blade • foil",
  },
  1665: {
    frequency: 1665,
    spanish: "muebles",
    english: "furniture • furnishings",
  },
  1666: { frequency: 1666, spanish: "escapado", english: "I escaped" },
  1667: { frequency: 1667, spanish: "largas", english: "long • lengthy" },
  1668: {
    frequency: 1668,
    spanish: "malvado",
    english: "evil • wicked • villain",
  },
  1669: { frequency: 1669, spanish: "embajador", english: "ambassador" },
  1670: {
    frequency: 1670,
    spanish: "artistas",
    english: "artist • performer • entertainer",
  },
  1671: {
    frequency: 1671,
    spanish: "ministerio",
    english: "ministry • office • department",
  },
  1672: { frequency: 1672, spanish: "comunista", english: "communist" },
  1673: { frequency: 1673, spanish: "bodas", english: "wedding • marriage" },
  1674: { frequency: 1674, spanish: "diciembre", english: "December • Dec ." },
  1675: { frequency: 1675, spanish: "votos", english: "votes" },
  1676: { frequency: 1676, spanish: "actividad", english: "activity" },
  1677: { frequency: 1677, spanish: "antiguos", english: "old" },
  1678: {
    frequency: 1678,
    spanish: "personajes",
    english: "character • personage",
  },
  1679: { frequency: 1679, spanish: "trono", english: "throne" },
  1680: { frequency: 1680, spanish: "pato", english: "duck" },
  1681: { frequency: 1681, spanish: "jugo", english: "juice" },
  1682: { frequency: 1682, spanish: "esclavo", english: "slave" },
  1683: {
    frequency: 1683,
    spanish: "taller",
    english: "workshop • studio • garage • atelier",
  },
  1684: { frequency: 1684, spanish: "asegurarme", english: "sure" },
  1685: {
    frequency: 1685,
    spanish: "patas",
    english: "leg • paw • foot • tab • duck • hoof",
  },
  1686: {
    frequency: 1686,
    spanish: "gerente",
    english: "manager • director • executive • managing",
  },
  1687: { frequency: 1687, spanish: "vacas", english: "cattle • kine" },
  1688: { frequency: 1688, spanish: "sentados", english: "sitting" },
  1689: { frequency: 1689, spanish: "dormida", english: "asleep • sleeping" },
  1690: { frequency: 1690, spanish: "celos", english: "jealousy" },
  1691: { frequency: 1691, spanish: "aceptado", english: "accepted" },
  1692: { frequency: 1692, spanish: "profesora", english: "teacher" },
  1693: { frequency: 1693, spanish: "tómate", english: "tomato" },
  1694: { frequency: 1694, spanish: "alumnos", english: "student • pupil" },
  1695: { frequency: 1695, spanish: "actos", english: "acts" },
  1696: {
    frequency: 1696,
    spanish: "masa",
    english: "mass • bulk • dough • body • lump",
  },
  1697: { frequency: 1697, spanish: "prometida", english: "fiancee • fiancée" },
  1698: {
    frequency: 1698,
    spanish: "cuartos",
    english: "fourth • quarter • room",
  },
  1699: { frequency: 1699, spanish: "guerrero", english: "warrior • fighter" },
  1700: { frequency: 1700, spanish: "permitido", english: "permitted" },
  1701: { frequency: 1701, spanish: "uñas", english: "some" },
  1702: { frequency: 1702, spanish: "ciudadano", english: "citizen • civic" },
  1703: { frequency: 1703, spanish: "noviembre", english: "November" },
  1704: {
    frequency: 1704,
    spanish: "logro",
    english: "achievement • accomplishment • attainment",
  },
  1705: { frequency: 1705, spanish: "naranja", english: "orange" },
  1706: {
    frequency: 1706,
    spanish: "restos",
    english: "remains • ruins • leftovers",
  },
  1707: { frequency: 1707, spanish: "considerado", english: "considered" },
  1708: {
    frequency: 1708,
    spanish: "sinceramente",
    english: "sincerely • genuinely • heartily",
  },
  1709: { frequency: 1709, spanish: "garaje", english: "garage" },
  1710: {
    frequency: 1710,
    spanish: "generoso",
    english: "generous • bountiful • rich",
  },
  1711: { frequency: 1711, spanish: "academia", english: "academy" },
  1712: { frequency: 1712, spanish: "jugadores", english: "player • gambler" },
  1713: { frequency: 1713, spanish: "cretino", english: "cretin • cretinous" },
  1714: { frequency: 1714, spanish: "recursos", english: "means" },
  1715: { frequency: 1715, spanish: "archivo", english: "archive" },
  1716: { frequency: 1716, spanish: "letras", english: "letters • lettering" },
  1717: { frequency: 1717, spanish: "poeta", english: "poet" },
  1718: { frequency: 1718, spanish: "posiblemente", english: "likely" },
  1719: { frequency: 1719, spanish: "dignidad", english: "dignity" },
  1720: {
    frequency: 1720,
    spanish: "infancia",
    english: "childhood • infancy",
  },
  1721: { frequency: 1721, spanish: "rota", english: "rattan" },
  1722: { frequency: 1722, spanish: "población", english: "population • town" },
  1723: { frequency: 1723, spanish: "chinos", english: "Chinese" },
  1724: {
    frequency: 1724,
    spanish: "soledad",
    english: "loneliness • solitude • isolation",
  },
  1725: { frequency: 1725, spanish: "medalla", english: "medal • badge" },
  1726: { frequency: 1726, spanish: "armado", english: "armed" },
  1727: {
    frequency: 1727,
    spanish: "soltero",
    english: "single • unmarried • stag • bachelor",
  },
  1728: { frequency: 1728, spanish: "ala", english: "wing" },
  1729: {
    frequency: 1729,
    spanish: "únicos",
    english: "only • alone • unique • single • one • sole",
  },
  1730: { frequency: 1730, spanish: "sistemas", english: "system • scheme" },
  1731: { frequency: 1731, spanish: "escuadrón", english: "squadron • troop" },
  1732: { frequency: 1732, spanish: "enterado", english: "learned" },
  1733: {
    frequency: 1733,
    spanish: "sorprendido",
    english: "surprised • shocked • astonished • startled",
  },
  1734: { frequency: 1734, spanish: "modales", english: "manners" },
  1735: { frequency: 1735, spanish: "médica", english: "medical" },
  1736: { frequency: 1736, spanish: "típico", english: "typical" },
  1737: {
    frequency: 1737,
    spanish: "edificios",
    english: "building • edifice",
  },
  1738: { frequency: 1738, spanish: "confundido", english: "confused" },
  1739: { frequency: 1739, spanish: "ciertos", english: "some" },
  1740: { frequency: 1740, spanish: "demasiadas", english: "too" },
  1741: { frequency: 1741, spanish: "botellas", english: "bottle" },
  1742: {
    frequency: 1742,
    spanish: "tristeza",
    english: "sadness • sorrow • gloom • misery",
  },
  1743: { frequency: 1743, spanish: "deporte", english: "sport • game" },
  1744: { frequency: 1744, spanish: "lucas", english: "Luke" },
  1745: {
    frequency: 1745,
    spanish: "recepción",
    english: "reception • receipt • receiving",
  },
  1746: { frequency: 1746, spanish: "brindis", english: "toast" },
  1747: {
    frequency: 1747,
    spanish: "fascinante",
    english: "fascinating • enthralling • spellbinding • engrossing",
  },
  1748: {
    frequency: 1748,
    spanish: "región",
    english: "region • area • district",
  },
  1749: { frequency: 1749, spanish: "terapia", english: "therapy" },
  1750: { frequency: 1750, spanish: "cómoda", english: "comfortable" },
  1751: { frequency: 1751, spanish: "miré", english: "Look!" },
  1752: { frequency: 1752, spanish: "honestamente", english: "honestly" },
  1753: { frequency: 1753, spanish: "verdes", english: "green" },
  1754: {
    frequency: 1754,
    spanish: "intenciones",
    english: "intention • intent • purpose",
  },
  1755: {
    frequency: 1755,
    spanish: "acostumbrado",
    english: "used • accustomed • customary • usual",
  },
  1756: { frequency: 1756, spanish: "tarjetas", english: "card" },
  1757: {
    frequency: 1757,
    spanish: "eterna",
    english: "eternal • everlasting • perpetual • timeless • undying",
  },
  1758: { frequency: 1758, spanish: "capítulo", english: "chapter • episode" },
  1759: { frequency: 1759, spanish: "selva", english: "jungle • forest" },
  1760: { frequency: 1760, spanish: "columna", english: "column • pillar" },
  1761: { frequency: 1761, spanish: "enterrado", english: "buried" },
  1762: { frequency: 1762, spanish: "objetos", english: "object • purpose" },
  1763: { frequency: 1763, spanish: "herramientas", english: "tools" },
  1764: { frequency: 1764, spanish: "marihuana", english: "marijuana" },
  1765: { frequency: 1765, spanish: "balón", english: "ball" },
  1766: {
    frequency: 1766,
    spanish: "confesión",
    english: "confession • admission • avowal • denomination",
  },
  1767: { frequency: 1767, spanish: "fresca", english: "fresh" },
  1768: { frequency: 1768, spanish: "amada", english: "loved" },
  1769: { frequency: 1769, spanish: "patatas", english: "potato" },
  1770: {
    frequency: 1770,
    spanish: "pandilla",
    english: "gang • mob • clique • band • crew",
  },
  1771: { frequency: 1771, spanish: "estatua", english: "statue" },
  1772: {
    frequency: 1772,
    spanish: "perrito",
    english: "puppy • doggie • doggy",
  },
  1773: {
    frequency: 1773,
    spanish: "corredor",
    english: "corridor • broker • agent • runner • racer • passage",
  },
  1774: { frequency: 1774, spanish: "canadá", english: "Canada" },
  1775: { frequency: 1775, spanish: "guantes", english: "glove • mitt" },
  1776: {
    frequency: 1776,
    spanish: "vaquero",
    english: "cowboy • cowherd • jean",
  },
  1777: {
    frequency: 1777,
    spanish: "enormes",
    english: "huge • vast • immense • enormous • tremendous • massive • great",
  },
  1778: { frequency: 1778, spanish: "pegado", english: "glued" },
  1779: {
    frequency: 1779,
    spanish: "simpático",
    english: "sympathetic • likeable • friendly • nice • charming • pleasant",
  },
  1780: { frequency: 1780, spanish: "jaula", english: "cage • birdcage" },
  1781: { frequency: 1781, spanish: "ratón", english: "mouse" },
  1782: { frequency: 1782, spanish: "brasil", english: "Brazil" },
  1783: { frequency: 1783, spanish: "zapato", english: "shoe" },
  1784: { frequency: 1784, spanish: "docena", english: "dozen" },
  1785: { frequency: 1785, spanish: "casarnos", english: "married" },
  1786: { frequency: 1786, spanish: "trajes", english: "costumes" },
  1787: { frequency: 1787, spanish: "seguida", english: "followed" },
  1788: { frequency: 1788, spanish: "evento", english: "event" },
  1789: {
    frequency: 1789,
    spanish: "administración",
    english: "administration • government • management",
  },
  1790: { frequency: 1790, spanish: "alfombra", english: "carpet" },
  1791: { frequency: 1791, spanish: "vecindario", english: "neighborhood" },
  1792: { frequency: 1792, spanish: "so", english: "SW" },
  1793: {
    frequency: 1793,
    spanish: "residencia",
    english: "residence • residency • home",
  },
  1794: {
    frequency: 1794,
    spanish: "oportunidades",
    english: "opportunity • chance • occasion • shot",
  },
  1795: {
    frequency: 1795,
    spanish: "recientemente",
    english: "recently • lately • newly",
  },
  1796: { frequency: 1796, spanish: "preso", english: "prisoner • inmate" },
  1797: { frequency: 1797, spanish: "plástico", english: "plastic" },
  1798: { frequency: 1798, spanish: "sombras", english: "shades" },
  1799: {
    frequency: 1799,
    spanish: "bondad",
    english: "goodness • kindness • kindliness",
  },
  1800: { frequency: 1800, spanish: "profunda", english: "deep • profound" },
  1801: {
    frequency: 1801,
    spanish: "físico",
    english: "physical • physicist • physique",
  },
  1802: { frequency: 1802, spanish: "cuyo", english: "which • whose" },
  1803: { frequency: 1803, spanish: "páginas", english: "pages" },
  1804: { frequency: 1804, spanish: "muchachas", english: "boy • lad" },
  1805: { frequency: 1805, spanish: "nieto", english: "grandson • grandchild" },
  1806: {
    frequency: 1806,
    spanish: "logró",
    english: "achievement • accomplishment • attainment",
  },
  1807: {
    frequency: 1807,
    spanish: "campeonato",
    english: "championship • title",
  },
  1808: {
    frequency: 1808,
    spanish: "sordo",
    english: "deaf • dull • muffled • muted",
  },
  1809: { frequency: 1809, spanish: "arruinado", english: "ruined • bankrupt" },
  1810: {
    frequency: 1810,
    spanish: "representante",
    english: "representative • agent",
  },
  1811: { frequency: 1811, spanish: "doctores", english: "doctor" },
  1812: { frequency: 1812, spanish: "oxígeno", english: "oxygen" },
  1813: {
    frequency: 1813,
    spanish: "terribles",
    english: "terrible • awful • dreadful • fearful • dire • horrible",
  },
  1814: { frequency: 1814, spanish: "liga", english: "league • garter" },
  1815: { frequency: 1815, spanish: "cenizas", english: "ash • cinder" },
  1816: { frequency: 1816, spanish: "terrorista", english: "terrorist" },
  1817: { frequency: 1817, spanish: "ave", english: "bird • fowl" },
  1818: { frequency: 1818, spanish: "heroína", english: "heroin • heroine" },
  1819: {
    frequency: 1819,
    spanish: "furioso",
    english: "furious • angry • raging • wild • mad • fierce",
  },
  1820: {
    frequency: 1820,
    spanish: "bonitos",
    english: "nice • beautiful • pretty",
  },
  1821: {
    frequency: 1821,
    spanish: "bendición",
    english: "blessing • boon • benediction • godsend",
  },
  1822: { frequency: 1822, spanish: "porno", english: "for not" },
  1823: {
    frequency: 1823,
    spanish: "pesada",
    english: "heavy • heavyweight • burdensome",
  },
  1824: { frequency: 1824, spanish: "británico", english: "British • Briton" },
  1825: { frequency: 1825, spanish: "economía", english: "economy" },
  1826: { frequency: 1826, spanish: "ensalada", english: "salad" },
  1827: {
    frequency: 1827,
    spanish: "quizas",
    english: "perhaps • maybe • possibly",
  },
  1828: { frequency: 1828, spanish: "equivoqué", english: "I was wrong" },
  1829: { frequency: 1829, spanish: "equivoca", english: "wrong" },
  1830: { frequency: 1830, spanish: "creído", english: "I thought" },
  1831: {
    frequency: 1831,
    spanish: "pluma",
    english: "pen • feather • plume • quill",
  },
  1832: {
    frequency: 1832,
    spanish: "horribles",
    english:
      "horrible • awful • frightful • hideous • horrid • gruesome • ghastly • nasty • dire",
  },
  1833: { frequency: 1833, spanish: "hacernos", english: "us" },
  1834: { frequency: 1834, spanish: "sexuales", english: "sexual • sex" },
  1835: {
    frequency: 1835,
    spanish: "dificil",
    english: "difficult • hard • tough • tricky",
  },
  1836: { frequency: 1836, spanish: "prepárate", english: "ready" },
  1837: { frequency: 1837, spanish: "embajada", english: "embassy" },
  1838: { frequency: 1838, spanish: "fruta", english: "fruit" },
  1839: { frequency: 1839, spanish: "quinto", english: "fifth" },
  1840: { frequency: 1840, spanish: "coca", english: "coca • coke" },
  1841: {
    frequency: 1841,
    spanish: "divertida",
    english: "funny • amusing • amused • enjoyable",
  },
  1842: {
    frequency: 1842,
    spanish: "extranjeros",
    english: "foreign • alien • stranger • foreigner",
  },
  1843: {
    frequency: 1843,
    spanish: "pañuelo",
    english:
      "handkerchief • hanky • scarf • kerchief • bandanna • bandana • neckerchief",
  },
  1844: { frequency: 1844, spanish: "ataúd", english: "coffin • casket" },
  1845: { frequency: 1845, spanish: "sobra", english: "surplus" },
  1846: { frequency: 1846, spanish: "sentirme", english: "feeling" },
  1847: { frequency: 1847, spanish: "dí", english: "gave" },
  1848: { frequency: 1848, spanish: "enseñado", english: "taught • learned" },
  1849: { frequency: 1849, spanish: "fines", english: "Finnish" },
  1850: { frequency: 1850, spanish: "atreve", english: "dares" },
  1851: { frequency: 1851, spanish: "salve", english: "Hail!" },
  1852: { frequency: 1852, spanish: "pulso", english: "pulse" },
  1853: { frequency: 1853, spanish: "artes", english: "art • craft" },
  1854: {
    frequency: 1854,
    spanish: "camiones",
    english: "truck • camion • lorry • van",
  },
  1855: { frequency: 1855, spanish: "juguete", english: "toy • plaything" },
  1856: {
    frequency: 1856,
    spanish: "todavia",
    english: "still • yet • as yet",
  },
  1857: { frequency: 1857, spanish: "cuánta", english: "how much" },
  1858: { frequency: 1858, spanish: "cirugía", english: "surgery" },
  1859: {
    frequency: 1859,
    spanish: "eternidad",
    english: "eternity • forever • everlasting",
  },
  1860: {
    frequency: 1860,
    spanish: "bailarina",
    english: "ballerina • dancing girl",
  },
  1861: { frequency: 1861, spanish: "rojas", english: "red" },
  1862: {
    frequency: 1862,
    spanish: "infantil",
    english: "infantile • childish • childlike",
  },
  1863: { frequency: 1863, spanish: "pavo", english: "turkey" },
  1864: { frequency: 1864, spanish: "juguetes", english: "toy • plaything" },
  1865: {
    frequency: 1865,
    spanish: "custodia",
    english: "custody • safekeeping • keeping • monstrance",
  },
  1866: {
    frequency: 1866,
    spanish: "justamente",
    english: "just • justly • fairly",
  },
  1867: { frequency: 1867, spanish: "rusa", english: "Russian" },
  1868: { frequency: 1868, spanish: "civilización", english: "civilization" },
  1869: {
    frequency: 1869,
    spanish: "burro",
    english: "donkey • burro • ass • dunce • jackass",
  },
  1870: { frequency: 1870, spanish: "muchísimas", english: "many" },
  1871: { frequency: 1871, spanish: "democracia", english: "democracy" },
  1872: { frequency: 1872, spanish: "egipto", english: "Egypt" },
  1873: { frequency: 1873, spanish: "estadounidenses", english: "US" },
  1874: { frequency: 1874, spanish: "patético", english: "pathetic" },
  1875: { frequency: 1875, spanish: "fase", english: "phase" },
  1876: {
    frequency: 1876,
    spanish: "debilidad",
    english: "weakness • debility • frailty • feebleness",
  },
  1877: {
    frequency: 1877,
    spanish: "bienes",
    english: "goods • property • assets • estate • possessions",
  },
  1878: { frequency: 1878, spanish: "psiquiatra", english: "psychiatrist" },
  1879: {
    frequency: 1879,
    spanish: "autor",
    english: "author • writer • perpetrator",
  },
  1880: { frequency: 1880, spanish: "engañado", english: "cheated" },
  1881: {
    frequency: 1881,
    spanish: "duros",
    english: "hard • tough • harsh • difficult",
  },
  1882: { frequency: 1882, spanish: "eeuu", english: "USA • US" },
  1883: { frequency: 1883, spanish: "condena", english: "conviction" },
  1884: {
    frequency: 1884,
    spanish: "repugnante",
    english:
      "disgusting • revolting • repellent • repugnant • nasty • loathsome • nauseating • offensive • ugly",
  },
  1885: { frequency: 1885, spanish: "asientos", english: "seating" },
  1886: { frequency: 1886, spanish: "vencido", english: "defeated • beaten" },
  1887: { frequency: 1887, spanish: "dibujos", english: "drawings" },
  1888: { frequency: 1888, spanish: "pechos", english: "breasts" },
  1889: { frequency: 1889, spanish: "cervezas", english: "beer" },
  1890: { frequency: 1890, spanish: "mantequilla", english: "butter" },
  1891: { frequency: 1891, spanish: "pablo", english: "Paul" },
  1892: { frequency: 1892, spanish: "copas", english: "cups" },
  1893: { frequency: 1893, spanish: "sospecha", english: "suspicion" },
  1894: { frequency: 1894, spanish: "cazador", english: "hunter • huntsman" },
  1895: {
    frequency: 1895,
    spanish: "autopista",
    english: "motorway • freeway • expressway • superhighway • highway",
  },
  1896: {
    frequency: 1896,
    spanish: "canalla",
    english:
      "scoundrel • swine • cad • blackguard • heel • cur • rat • riffraff • skunk • stinker",
  },
  1897: { frequency: 1897, spanish: "informado", english: "informed" },
  1898: { frequency: 1898, spanish: "postre", english: "dessert • sweet" },
  1899: {
    frequency: 1899,
    spanish: "concepto",
    english: "concept • conception • notion • idea",
  },
  1900: {
    frequency: 1900,
    spanish: "desarrollo",
    english: "development • developing • growth",
  },
  1901: {
    frequency: 1901,
    spanish: "digno",
    english: "worthy • dignified • deserving",
  },
  1902: { frequency: 1902, spanish: "adulto", english: "adult" },
  1903: { frequency: 1903, spanish: "quema", english: "burning" },
  1904: { frequency: 1904, spanish: "vampiro", english: "vampire" },
  1905: { frequency: 1905, spanish: "términos", english: "terms" },
  1906: { frequency: 1906, spanish: "toalla", english: "towel" },
  1907: { frequency: 1907, spanish: "negras", english: "black • dark" },
  1908: { frequency: 1908, spanish: "andas", english: "bier" },
  1909: {
    frequency: 1909,
    spanish: "llenos",
    english: "filled • full • packed",
  },
  1910: {
    frequency: 1910,
    spanish: "bomberos",
    english: "firefighter • fireman",
  },
  1911: {
    frequency: 1911,
    spanish: "gravedad",
    english: "gravity • severity • seriousness",
  },
  1912: { frequency: 1912, spanish: "hadas", english: "fairy" },
  1913: {
    frequency: 1913,
    spanish: "marinero",
    english: "sailor • seaman • mariner • seafarer • rating • seafaring",
  },
  1914: { frequency: 1914, spanish: "nube", english: "cloud" },
  1915: { frequency: 1915, spanish: "rehenes", english: "hostage" },
  1916: { frequency: 1916, spanish: "vera", english: "edge" },
  1917: {
    frequency: 1917,
    spanish: "chaval",
    english: "kid • youngster • lad • boy",
  },
  1918: { frequency: 1918, spanish: "sobrina", english: "niece" },
  1919: { frequency: 1919, spanish: "levante", english: "east" },
  1920: {
    frequency: 1920,
    spanish: "amables",
    english:
      "friendly • kind • nice • helpful • gentle • amiable • good • pleasant",
  },
  1921: { frequency: 1921, spanish: "ovejas", english: "sheep • ewe" },
  1922: { frequency: 1922, spanish: "violento", english: "violent • fierce" },
  1923: { frequency: 1923, spanish: "feria", english: "fair • carnival" },
  1924: {
    frequency: 1924,
    spanish: "origen",
    english: "origin • source • birth • descent",
  },
  1925: { frequency: 1925, spanish: "vestida", english: "dressed" },
  1926: { frequency: 1926, spanish: "golpeado", english: "beaten" },
  1927: {
    frequency: 1927,
    spanish: "constantemente",
    english: "constantly • continually",
  },
  1928: {
    frequency: 1928,
    spanish: "habilidades",
    english: "ability • skill • proficiency • expertise",
  },
  1929: { frequency: 1929, spanish: "causado", english: "inflicted" },
  1930: { frequency: 1930, spanish: "escuelas", english: "school" },
  1931: {
    frequency: 1931,
    spanish: "equivoco",
    english: "mistaken • equivocal • ambiguous • ambiguity • equivocation",
  },
  1932: {
    frequency: 1932,
    spanish: "normales",
    english: "normal • regular • ordinary",
  },
  1933: {
    frequency: 1933,
    spanish: "desafortunadamente",
    english: "unfortunately",
  },
  1934: { frequency: 1934, spanish: "refuerzos", english: "reinforcement" },
  1935: { frequency: 1935, spanish: "zorro", english: "fox" },
  1936: { frequency: 1936, spanish: "jabón", english: "soap" },
  1937: { frequency: 1937, spanish: "método", english: "method • way • means" },
  1938: { frequency: 1938, spanish: "mercancía", english: "commodity" },
  1939: { frequency: 1939, spanish: "tenis", english: "tennis" },
  1940: { frequency: 1940, spanish: "artículos", english: "articles • goods" },
  1941: { frequency: 1941, spanish: "celosa", english: "jealous • zealous" },
  1942: { frequency: 1942, spanish: "diosa", english: "goddess" },
  1943: { frequency: 1943, spanish: "comentarios", english: "comments" },
  1944: { frequency: 1944, spanish: "alla", english: "there" },
  1945: { frequency: 1945, spanish: "guerreros", english: "warrior • fighter" },
  1946: { frequency: 1946, spanish: "desfile", english: "parade • procession" },
  1947: { frequency: 1947, spanish: "lápiz", english: "pencil" },
  1948: { frequency: 1948, spanish: "guerras", english: "war • warfare" },
  1949: {
    frequency: 1949,
    spanish: "fortaleza",
    english: "fortress • fort • stronghold • fortitude",
  },
  1950: { frequency: 1950, spanish: "invitó", english: "he invited" },
  1951: {
    frequency: 1951,
    spanish: "diarios",
    english: "everyday • daily • journal • diary • newspaper • paper",
  },
  1952: {
    frequency: 1952,
    spanish: "terriblemente",
    english: "terribly • frightfully • horribly • awfully • painfully • deadly",
  },
  1953: { frequency: 1953, spanish: "rojos", english: "red" },
  1954: { frequency: 1954, spanish: "generalmente", english: "generally" },
  1955: {
    frequency: 1955,
    spanish: "inconsciente",
    english: "unconscious • unaware • senseless • unwitting",
  },
  1956: { frequency: 1956, spanish: "grabación", english: "recording" },
  1957: {
    frequency: 1957,
    spanish: "pacífico",
    english: "Pacific • peaceful • peaceable",
  },
  1958: { frequency: 1958, spanish: "complejo", english: "complex" },
  1959: {
    frequency: 1959,
    spanish: "maletín",
    english: "briefcase • attache case",
  },
  1960: {
    frequency: 1960,
    spanish: "poderosa",
    english: "powerful • potent • mighty",
  },
  1961: { frequency: 1961, spanish: "conocida", english: "known" },
  1962: {
    frequency: 1962,
    spanish: "profesionales",
    english: "vocational • occupational • professional • pro",
  },
  1963: { frequency: 1963, spanish: "positivo", english: "plus • positive" },
  1964: { frequency: 1964, spanish: "chofer", english: "driver • chauffeur" },
  1965: {
    frequency: 1965,
    spanish: "eléctrica",
    english: "electric • electrical",
  },
  1966: { frequency: 1966, spanish: "cabra", english: "goat" },
  1967: { frequency: 1967, spanish: "enero", english: "January • Jan ." },
  1968: { frequency: 1968, spanish: "comedor", english: "dining room • diner" },
  1969: { frequency: 1969, spanish: "república", english: "republic" },
  1970: { frequency: 1970, spanish: "cintas", english: "tapes" },
  1971: {
    frequency: 1971,
    spanish: "sabio",
    english: "wise • learned • sage • savant",
  },
  1972: {
    frequency: 1972,
    spanish: "comprendido",
    english: "understood • included",
  },
  1973: {
    frequency: 1973,
    spanish: "ejecución",
    english: "execution • performance • implementation • enforcement",
  },
  1974: {
    frequency: 1974,
    spanish: "brillantes",
    english: "bright • shiny • shining • brilliant • glowing • sparkling",
  },
  1975: { frequency: 1975, spanish: "licor", english: "liqueur • spirit" },
  1976: { frequency: 1976, spanish: "narices", english: "nose" },
  1977: {
    frequency: 1977,
    spanish: "enfermedades",
    english: "disease • illness • sickness • disorder",
  },
  1978: {
    frequency: 1978,
    spanish: "mágico",
    english: "magical • magic • magician",
  },
  1979: {
    frequency: 1979,
    spanish: "reconocimiento",
    english: "recognition • acknowledgment • prize",
  },
  1980: { frequency: 1980, spanish: "involucrado", english: "involved" },
  1981: {
    frequency: 1981,
    spanish: "desesperado",
    english: "desperate • hopeless • in despair",
  },
  1982: { frequency: 1982, spanish: "elefante", english: "elephant" },
  1983: {
    frequency: 1983,
    spanish: "torneo",
    english: "tournament • tourney • championship",
  },
  1984: { frequency: 1984, spanish: "apetito", english: "appetite" },
  1985: {
    frequency: 1985,
    spanish: "alimentos",
    english: "food • nourishment",
  },
  1986: { frequency: 1986, spanish: "marte", english: "Mars" },
  1987: {
    frequency: 1987,
    spanish: "submarino",
    english: "undersea • underwater • submarine",
  },
  1988: {
    frequency: 1988,
    spanish: "sabiduría",
    english: "wisdom • knowledge",
  },
  1989: { frequency: 1989, spanish: "locales", english: "local • premises" },
  1990: { frequency: 1990, spanish: "policial", english: "police" },
  1991: { frequency: 1991, spanish: "campana", english: "bell" },
  1992: {
    frequency: 1992,
    spanish: "criada",
    english: "maid • housemaid • maidservant",
  },
  1993: { frequency: 1993, spanish: "capullo", english: "cocoon • bud" },
  1994: { frequency: 1994, spanish: "creación", english: "creation • making" },
  1995: { frequency: 1995, spanish: "camiseta", english: "shirt" },
  1996: {
    frequency: 1996,
    spanish: "aburrida",
    english: "boring • dull • tiresome • tired",
  },
  1997: {
    frequency: 1997,
    spanish: "gusano",
    english: "worm • maggot • caterpillar • earthworm • grub",
  },
  1998: { frequency: 1998, spanish: "lógica", english: "logic" },
  1999: {
    frequency: 1999,
    spanish: "callejón",
    english: "alley • alleyway • passageway",
  },
  2000: { frequency: 2000, spanish: "excepción", english: "exception" },
};

export default {
  state() {
    return {
      spanish_list: spanish_list,
    };
  },
  mutations,
  actions,
  getters,
};
