import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const korean_list = {
  1: { frequency: 1, korean: "것", english: "A thing or an object" },
  2: { frequency: 2, korean: "하다", english: "To do" },
  3: {
    frequency: 3,
    korean: "있다",
    english: "1. to be (in a place); to exist 2. to have",
  },
  4: { frequency: 4, korean: "있다", english: "To be" },
  5: {
    frequency: 5,
    korean: "되다",
    english: "to become; (of a time) to come, to arrive",
  },
  6: { frequency: 6, korean: "수", english: "way, method, means" },
  7: { frequency: 7, korean: "하다", english: "To do" },
  8: { frequency: 8, korean: "나", english: "I" },
  9: { frequency: 9, korean: "그", english: "He, That" },
  10: { frequency: 10, korean: "없다", english: "Do not exist, absent" },
  11: { frequency: 11, korean: "않다", english: "To not do" },
  12: { frequency: 12, korean: "사람", english: "Person" },
  13: { frequency: 13, korean: "우리", english: "we, our" },
  14: { frequency: 14, korean: "이", english: "A person, This" },
  15: { frequency: 15, korean: "그", english: "He, That" },
  16: { frequency: 16, korean: "아니다", english: "To not be" },
  17: {
    frequency: 17,
    korean: "보다",
    english: "To see, to watch, to look at",
  },
  18: { frequency: 18, korean: "등", english: "rank" },
  19: { frequency: 19, korean: "때", english: "occasion; time" },
  20: { frequency: 20, korean: "거", english: "thing" },
  21: { frequency: 21, korean: "보다", english: "to try out (+auxiliary)" },
  22: { frequency: 22, korean: "같다", english: "To be similar" },
  23: { frequency: 23, korean: "주다", english: "To give, to do for a person" },
  24: { frequency: 24, korean: "대하다", english: "Face, confront" },
  25: { frequency: 25, korean: "가다", english: "To go" },
  26: { frequency: 26, korean: "년", english: "Year" },
  27: { frequency: 27, korean: "한", english: "One, a single" },
  28: { frequency: 28, korean: "말", english: "words,speaking" },
  29: { frequency: 29, korean: "일", english: "Work" },
  30: { frequency: 30, korean: "이", english: "A person, This" },
  31: { frequency: 31, korean: "때문", english: "Because of" },
  32: { frequency: 32, korean: "말하다", english: "Speak" },
  33: { frequency: 33, korean: "위하다", english: "To do for the sake of" },
  34: { frequency: 34, korean: "그러나", english: "However or but still" },
  35: { frequency: 35, korean: "오다", english: "To come" },
  36: { frequency: 36, korean: "알다", english: "To know" },
  37: { frequency: 37, korean: "씨", english: "~ Mr." },
  38: { frequency: 38, korean: "그렇다", english: "That is right or yes." },
  39: { frequency: 39, korean: "크다", english: "To be big, large" },
  40: { frequency: 40, korean: "또", english: "also, too" },
  41: { frequency: 41, korean: "일", english: "day" },
  42: { frequency: 42, korean: "사회", english: "culture,society" },
  43: { frequency: 43, korean: "많다", english: "many, much" },
  44: { frequency: 44, korean: "안", english: "~ not ~ + VERB" },
  45: { frequency: 45, korean: "좋다", english: "To be good" },
  46: { frequency: 46, korean: "더", english: "More" },
  47: { frequency: 47, korean: "받다", english: "Receive" },
  48: { frequency: 48, korean: "그것", english: "That thing" },
  49: { frequency: 49, korean: "집", english: "House" },
  50: { frequency: 50, korean: "나오다", english: "Come out" },
  51: { frequency: 51, korean: "따르다", english: "follow" },
  52: { frequency: 52, korean: "그리고", english: "And or and then" },
  53: { frequency: 53, korean: "문제", english: "question,problem" },
  54: { frequency: 54, korean: "그런", english: "Such a" },
  55: { frequency: 55, korean: "살다", english: "To live" },
  56: { frequency: 56, korean: "저", english: "That" },
  57: { frequency: 57, korean: "못하다", english: "Be impossible" },
  58: { frequency: 58, korean: "생각하다", english: "To think" },
  59: { frequency: 59, korean: "모르다", english: "To not know" },
  60: { frequency: 60, korean: "속", english: "The inside" },
  61: { frequency: 61, korean: "만들다", english: "To make" },
  62: { frequency: 62, korean: "데", english: "Place, point, instance" },
  63: { frequency: 63, korean: "두", english: "two" },
  64: { frequency: 64, korean: "앞", english: "In front of, before" },
  65: { frequency: 65, korean: "경우", english: "A case or an instance" },
  66: { frequency: 66, korean: "중", english: "The center, the middle" },
  67: { frequency: 67, korean: "어떤", english: "What kind of, what sort of" },
  68: { frequency: 68, korean: "잘", english: "Well" },
  69: { frequency: 69, korean: "그녀", english: "She" },
  70: { frequency: 70, korean: "먹다", english: "Eat, chow down on" },
  71: { frequency: 71, korean: "오다", english: "To come" },
  72: {
    frequency: 72,
    korean: "자신",
    english: "one’s own self, one’s own body",
  },
  73: { frequency: 73, korean: "문화", english: "culture" },
  74: {
    frequency: 74,
    korean: "원",
    english: "A unit of south korean money, KRW",
  },
  75: { frequency: 75, korean: "생각", english: "Thought" },
  76: {
    frequency: 76,
    korean: "어떻다",
    english: "how,what.. do you think of",
  },
  77: { frequency: 77, korean: "명", english: "Person counter" },
  78: {
    frequency: 78,
    korean: "통하다",
    english: "Run, lead; flow; go through",
  },
  79: { frequency: 79, korean: "그러다", english: "and so, and then, well" },
  80: { frequency: 80, korean: "소리", english: "A sound,noise" },
  81: { frequency: 81, korean: "다시", english: "Again" },
  82: { frequency: 82, korean: "다른", english: "Different" },
  83: { frequency: 83, korean: "이런", english: "Such, like this" },
  84: { frequency: 84, korean: "여자", english: "woman,female" },
  85: { frequency: 85, korean: "개", english: "Unit or piece" },
  86: { frequency: 86, korean: "정도", english: "grade,degree" },
  87: { frequency: 87, korean: "뒤", english: "back, latter, rear" },
  88: { frequency: 88, korean: "듣다", english: "to listen, to hear" },
  89: { frequency: 89, korean: "다", english: "All, everything" },
  90: { frequency: 90, korean: "좀", english: "A little" },
  91: {
    frequency: 91,
    korean: "들다",
    english: "to enter/go into ; stay at hotel or inn",
  },
  92: { frequency: 92, korean: "싶다", english: "want,hope" },
  93: { frequency: 93, korean: "보이다", english: "see,catch sight of" },
  94: { frequency: 94, korean: "가지다", english: "To have or take or hold" },
  95: { frequency: 95, korean: "함께", english: "Together, with" },
  96: { frequency: 96, korean: "아이", english: "Child" },
  97: { frequency: 97, korean: "지나다", english: "pass,elapse,go on" },
  98: { frequency: 98, korean: "많이", english: "A lot" },
  99: { frequency: 99, korean: "시간", english: "Time" },
  100: { frequency: 100, korean: "너", english: "You" },
  101: {
    frequency: 101,
    korean: "주다",
    english: "To give, to do for a person",
  },
  102: { frequency: 102, korean: "인간", english: "A person, a human being" },
  103: { frequency: 103, korean: "사실", english: "The truth, a fact" },
  104: { frequency: 104, korean: "나다", english: "To be born" },
  105: { frequency: 105, korean: "이렇다", english: "Like this" },
  106: { frequency: 106, korean: "어머니", english: "Mom" },
  107: { frequency: 107, korean: "눈", english: "Eyes" },
  108: { frequency: 108, korean: "뭐", english: "Huh? (2) something" },
  109: { frequency: 109, korean: "점", english: "point, spot" },
  110: { frequency: 110, korean: "의하다", english: "To be due to, owing to" },
  111: { frequency: 111, korean: "시대", english: "An age,period" },
  112: { frequency: 112, korean: "다음", english: "Next" },
  113: {
    frequency: 113,
    korean: "이러하다",
    english: "Be this way, be like follows",
  },
  114: { frequency: 114, korean: "누구", english: "Who" },
  115: { frequency: 115, korean: "전", english: "Before" },
  116: { frequency: 116, korean: "곳", english: "a" },
  117: { frequency: 117, korean: "여러", english: "many,various" },
  118: { frequency: 118, korean: "안", english: "~ not ~ + VERB" },
  119: { frequency: 119, korean: "하나", english: "One" },
  120: { frequency: 120, korean: "세계", english: "World" },
  121: {
    frequency: 121,
    korean: "버리다",
    english:
      "(following a verb) the verb’s action is over, perhaps in a sad way",
  },
  122: { frequency: 122, korean: "위", english: "The upper part, above" },
  123: { frequency: 123, korean: "운동", english: "Motion, movement" },
  124: { frequency: 124, korean: "퍼센트", english: "Percent" },
  125: { frequency: 125, korean: "학교", english: "School" },
  126: {
    frequency: 126,
    korean: "자기",
    english: "Oneself, number one, numerouno, self",
  },
  127: { frequency: 127, korean: "가장", english: "Most" },
  128: { frequency: 128, korean: "대통령", english: "The president" },
  129: { frequency: 129, korean: "가지", english: "One of the kind or a sort" },
  130: {
    frequency: 130,
    korean: "들다",
    english: "to hold, to carry; to give",
  },
  131: { frequency: 131, korean: "시작하다", english: "To start,begin" },
  132: { frequency: 132, korean: "바로", english: "Right, correctly" },
  133: { frequency: 133, korean: "어느", english: "Some, a certain" },
  134: { frequency: 134, korean: "그래서", english: "And so accordingly" },
  135: { frequency: 135, korean: "무엇", english: "That thing,whatever" },
  136: { frequency: 136, korean: "정부", english: "Government" },
  137: { frequency: 137, korean: "모든", english: "Every one" },
  138: { frequency: 138, korean: "번", english: "Number, how many times" },
  139: { frequency: 139, korean: "그거", english: "That thing" },
  140: { frequency: 140, korean: "돈", english: "Money" },
  141: { frequency: 141, korean: "국가", english: "A state or a nation" },
  142: { frequency: 142, korean: "그런데", english: "But or however" },
  143: { frequency: 143, korean: "날", english: "Day" },
  144: { frequency: 144, korean: "여기", english: "A hobby" },
  145: { frequency: 145, korean: "모두", english: "Everybody" },
  146: { frequency: 146, korean: "여성", english: "Female" },
  147: { frequency: 147, korean: "동무", english: "A friend" },
  148: { frequency: 148, korean: "마음", english: "Heart" },
  149: { frequency: 149, korean: "후", english: "After" },
  150: { frequency: 150, korean: "가다", english: "To go" },
  151: { frequency: 151, korean: "놓다", english: "Put, place" },
  152: { frequency: 152, korean: "관계", english: "Connection or relation" },
  153: { frequency: 153, korean: "아버지", english: "Father" },
  154: { frequency: 154, korean: "남자", english: "Boy" },
  155: { frequency: 155, korean: "어디", english: "Where?" },
  156: { frequency: 156, korean: "몸", english: "body,physique" },
  157: { frequency: 157, korean: "얼굴", english: "Face" },
  158: { frequency: 158, korean: "들어가다", english: "to go into; to enter" },
  159: { frequency: 159, korean: "왜", english: "Why?" },
  160: { frequency: 160, korean: "나타나다", english: "Come out, appear" },
  161: { frequency: 161, korean: "말다", english: "Cease" },
  162: { frequency: 162, korean: "지역", english: "An area, region" },
  163: { frequency: 163, korean: "다르다", english: "Be different" },
  164: { frequency: 164, korean: "모습", english: "shape,body" },
  165: { frequency: 165, korean: "물", english: "Water" },
  166: { frequency: 166, korean: "만나다", english: "Meet" },
  167: { frequency: 167, korean: "내다", english: "produce something" },
  168: {
    frequency: 168,
    korean: "보이다",
    english: "to show, to cause to see",
  },
  169: { frequency: 169, korean: "쓰다", english: "To write" },
  170: { frequency: 170, korean: "이것", english: "This thing" },
  171: { frequency: 171, korean: "없이", english: "Without" },
  172: { frequency: 172, korean: "이번", english: "This time" },
  173: { frequency: 173, korean: "길", english: "Road" },
  174: { frequency: 174, korean: "생활", english: "lifestyle,livelihood" },
  175: {
    frequency: 175,
    korean: "쓰다",
    english: "to use, to take (medicine)",
  },
  176: { frequency: 176, korean: "지금", english: "Now" },
  177: { frequency: 177, korean: "뿐", english: "only,alone,merely" },
  178: {
    frequency: 178,
    korean: "사이",
    english: "space between 2 points; the relationship between 2 people",
  },
  179: { frequency: 179, korean: "방법", english: "Way, method" },
  180: { frequency: 180, korean: "새롭다", english: "To be new,novel" },
  181: { frequency: 181, korean: "우리나라", english: "Korea" },
  182: { frequency: 182, korean: "내다", english: "produce something" },
  183: { frequency: 183, korean: "앉다", english: "To sit down" },
  184: { frequency: 184, korean: "처음", english: "First" },
  185: { frequency: 185, korean: "손", english: "The hand" },
  186: { frequency: 186, korean: "몇", english: "A few" },
  187: { frequency: 187, korean: "그때", english: "Then or at that time" },
  188: {
    frequency: 188,
    korean: "과정",
    english: "Process,  course of events",
  },
  189: { frequency: 189, korean: "삶", english: "Life" },
  190: { frequency: 190, korean: "갖다", english: "to have, hold, own" },
  191: { frequency: 191, korean: "찾다", english: "seek,look for" },
  192: { frequency: 192, korean: "특히", english: "Especially" },
  193: { frequency: 193, korean: "시", english: "Time" },
  194: { frequency: 194, korean: "이상", english: "More than, above" },
  195: { frequency: 195, korean: "지금", english: "Now" },
  196: { frequency: 196, korean: "나가다", english: "To go out" },
  197: { frequency: 197, korean: "이야기", english: "Conversation, talk" },
  198: { frequency: 198, korean: "교육", english: "Education" },
  199: { frequency: 199, korean: "사다", english: "To buy, purchase" },
  200: { frequency: 200, korean: "경제", english: "Economy" },
  201: { frequency: 201, korean: "아직", english: "Still" },
  202: { frequency: 202, korean: "잡다", english: "seize,catch" },
  203: { frequency: 203, korean: "같이", english: "Together" },
  204: { frequency: 204, korean: "선생님", english: "Teacher" },
  205: { frequency: 205, korean: "예술", english: "Art, an art" },
  206: { frequency: 206, korean: "서다", english: "To stand" },
  207: { frequency: 207, korean: "못", english: "Cannot" },
  208: { frequency: 208, korean: "역사", english: "History" },
  209: { frequency: 209, korean: "읽다", english: "To read" },
  210: { frequency: 210, korean: "이제", english: "Now" },
  211: { frequency: 211, korean: "결과", english: "Result" },
  212: { frequency: 212, korean: "내용", english: "Contents" },
  213: { frequency: 213, korean: "물론", english: "Of course" },
  214: { frequency: 214, korean: "동안", english: "interval, period of time" },
  215: { frequency: 215, korean: "책", english: "A book" },
  216: { frequency: 216, korean: "일어나다", english: "To rise, get up" },
  217: { frequency: 217, korean: "당신", english: "Formal you" },
  218: { frequency: 218, korean: "시장", english: "market,fair" },
  219: { frequency: 219, korean: "넣다", english: "Put in, set in" },
  220: {
    frequency: 220,
    korean: "중요하다",
    english: "To be important,weighty",
  },
  221: { frequency: 221, korean: "무슨", english: "What, what kind of" },
  222: { frequency: 222, korean: "느끼다", english: "To feel" },
  223: { frequency: 223, korean: "어렵다", english: "To be hard, difficult" },
  224: { frequency: 224, korean: "힘", english: "Power" },
  225: { frequency: 225, korean: "너무", english: "Too much" },
  226: { frequency: 226, korean: "나라", english: "A country" },
  227: { frequency: 227, korean: "부르다", english: "To call" },
  228: { frequency: 228, korean: "의미", english: "A meaning, a sense" },
  229: { frequency: 229, korean: "자리", english: "seat,spot" },
  230: { frequency: 230, korean: "밝히다", english: "To light up" },
  231: { frequency: 231, korean: "죽다", english: "die,pass away" },
  232: { frequency: 232, korean: "이미", english: "Already" },
  233: { frequency: 233, korean: "쪽", english: "Way, direction" },
  234: { frequency: 234, korean: "정치", english: "Politics" },
  235: {
    frequency: 235,
    korean: "국민",
    english: "The people or a nationality",
  },
  236: { frequency: 236, korean: "생명", english: "Life" },
  237: { frequency: 237, korean: "얘기", english: "Story" },
  238: { frequency: 238, korean: "학생", english: "Student" },
  239: { frequency: 239, korean: "연구", english: "Research" },
  240: { frequency: 240, korean: "엄마", english: "Mamma" },
  241: { frequency: 241, korean: "이름", english: "Name" },
  242: { frequency: 242, korean: "하나", english: "One" },
  243: { frequency: 243, korean: "내리다", english: "Descend" },
  244: { frequency: 244, korean: "사건", english: "An event,incident" },
  245: { frequency: 245, korean: "및", english: "As well as" },
  246: { frequency: 246, korean: "쉽다", english: "To be easy" },
  247: {
    frequency: 247,
    korean: "짓다",
    english: "To make, build ; to form a line (a group)",
  },
  248: { frequency: 248, korean: "또한", english: "also, too, furthermore" },
  249: { frequency: 249, korean: "이유", english: "Reason" },
  250: { frequency: 250, korean: "또는", english: "if not; alternatively" },
  251: { frequency: 251, korean: "필요하다", english: "To need" },
  252: { frequency: 252, korean: "글", english: "words, a verse" },
  253: { frequency: 253, korean: "생기다", english: "arise,occur,happen" },
  254: { frequency: 254, korean: "사용하다", english: "To use" },
  255: { frequency: 255, korean: "남편", english: "Husband" },
  256: { frequency: 256, korean: "들어오다", english: "to enter, to come in" },
  257: { frequency: 257, korean: "밖", english: "The outside" },
  258: { frequency: 258, korean: "세상", english: "The world,society" },
  259: { frequency: 259, korean: "작다", english: "Small" },
  260: { frequency: 260, korean: "타다", english: "Ride (bus)" },
  261: { frequency: 261, korean: "대학", english: "University" },
  262: { frequency: 262, korean: "작품", english: "A work of art" },
  263: { frequency: 263, korean: "상황", english: "State of things" },
  264: { frequency: 264, korean: "가운데", english: "In the middle" },
  265: { frequency: 265, korean: "보내다", english: "Send" },
  266: {
    frequency: 266,
    korean: "두다",
    english: "To put, to leave behind, to preseive",
  },
  267: { frequency: 267, korean: "즉", english: "namely,that is to say" },
  268: {
    frequency: 268,
    korean: "따라서",
    english: "therefore; in accordance with",
  },
  269: { frequency: 269, korean: "상태", english: "condition,state" },
  270: { frequency: 270, korean: "이후", english: "After that" },
  271: { frequency: 271, korean: "당시", english: "At that time" },
  272: { frequency: 272, korean: "문학", english: "Literature" },
  273: { frequency: 273, korean: "더욱", english: "More and more" },
  274: { frequency: 274, korean: "아주", english: "Extremely" },
  275: { frequency: 275, korean: "지방", english: "A locality, district" },
  276: { frequency: 276, korean: "밤", english: "Night" },
  277: { frequency: 277, korean: "높다", english: "High" },
  278: { frequency: 278, korean: "최근", english: "Recently" },
  279: { frequency: 279, korean: "채", english: "As it is, no change" },
  280: { frequency: 280, korean: "현실", english: "Actuality, reality" },
  281: { frequency: 281, korean: "환경", english: "Environment" },
  282: { frequency: 282, korean: "컴퓨터", english: "Computer" },
  283: { frequency: 283, korean: "먼저", english: "First" },
  284: { frequency: 284, korean: "다니다", english: "Go to and from a aplace" },
  285: { frequency: 285, korean: "얼마나", english: "How many, how much" },
  286: { frequency: 286, korean: "자체", english: "one’s own body" },
  287: { frequency: 287, korean: "열다", english: "Open" },
  288: { frequency: 288, korean: "머리", english: "Head" },
  289: { frequency: 289, korean: "묻다", english: "to Ask" },
  290: { frequency: 290, korean: "남다", english: "Remain, be left over" },
  291: { frequency: 291, korean: "부분", english: "part,portion" },
  292: { frequency: 292, korean: "기업", english: "An enterprise or business" },
  293: { frequency: 293, korean: "거기", english: "There (location)" },
  294: { frequency: 294, korean: "변화", english: "Change, transformation" },
  295: { frequency: 295, korean: "아들", english: "son,baby" },
  296: { frequency: 296, korean: "뜻", english: "meaning; sense" },
  297: { frequency: 297, korean: "아", english: "Oh dear" },
  298: { frequency: 298, korean: "기다리다", english: "To wait" },
  299: { frequency: 299, korean: "떨어지다", english: "to fall; tumble; drop" },
  300: { frequency: 300, korean: "선거", english: "An election" },
  301: { frequency: 301, korean: "관하다", english: "Refer to or be about" },
  302: { frequency: 302, korean: "분", english: "Minutes" },
  303: {
    frequency: 303,
    korean: "그냥",
    english: "Just because or in that condition",
  },
  304: { frequency: 304, korean: "나누다", english: "To divide" },
  305: { frequency: 305, korean: "이용하다", english: "To use, make use of" },
  306: { frequency: 306, korean: "거의", english: "Almost or nearly" },
  307: { frequency: 307, korean: "곧", english: "Soon" },
  308: {
    frequency: 308,
    korean: "중심",
    english: "The nucleus, the focus, the heart",
  },
  309: { frequency: 309, korean: "활동", english: "Activity" },
  310: { frequency: 310, korean: "오늘", english: "Today" },
  311: { frequency: 311, korean: "서로", english: "mutually,one another" },
  312: { frequency: 312, korean: "관심", english: "Concern or interest" },
  313: { frequency: 313, korean: "역시", english: "As expected, likewise" },
  314: { frequency: 314, korean: "이거", english: "This thing" },
  315: {
    frequency: 315,
    korean: "애",
    english: "A baby, or a slightly derogative word for person",
  },
  316: { frequency: 316, korean: "광고", english: "Advertisement" },
  317: { frequency: 317, korean: "나다", english: "To be born" },
  318: { frequency: 318, korean: "방", english: "A room" },
  319: { frequency: 319, korean: "정신", english: "mind,spirit" },
  320: {
    frequency: 320,
    korean: "이르다",
    english: "To reach, arrive, get at",
  },
  321: { frequency: 321, korean: "땅", english: "earth, land, soil" },
  322: { frequency: 322, korean: "이루다", english: "Accomplish, complete" },
  323: { frequency: 323, korean: "아침", english: "Morning" },
  324: { frequency: 324, korean: "웃다", english: "To laugh, smile" },
  325: {
    frequency: 325,
    korean: "현상",
    english: "The present situation, state",
  },
  326: {
    frequency: 326,
    korean: "두다",
    english: "To put, to leave behind, to preseive",
  },
  327: { frequency: 327, korean: "떠나다", english: "to leave, to exit" },
  328: {
    frequency: 328,
    korean: "기술",
    english: "Art or technique or ability",
  },
  329: {
    frequency: 329,
    korean: "전체",
    english: "The whole, the entire section",
  },
  330: { frequency: 330, korean: "그래", english: "So or yes or that’s right" },
  331: { frequency: 331, korean: "얻다", english: "Get, obtain" },
  332: { frequency: 332, korean: "아름답다", english: "To be beautiful" },
  333: { frequency: 333, korean: "끝", english: "The end" },
  334: { frequency: 334, korean: "민족", english: "race,nation,people" },
  335: { frequency: 335, korean: "간", english: "The interval between" },
  336: { frequency: 336, korean: "조사", english: "investigation,inquiry" },
  337: { frequency: 337, korean: "듯", english: "To be like something" },
  338: { frequency: 338, korean: "입", english: "Mouth" },
  339: { frequency: 339, korean: "뭐", english: "Huh? (2) something" },
  340: { frequency: 340, korean: "그대로", english: "Like that" },
  341: { frequency: 341, korean: "영화", english: "A movie" },
  342: {
    frequency: 342,
    korean: "필요",
    english: "Need, requirement,necessity",
  },
  343: { frequency: 343, korean: "줄", english: "way,method" },
  344: { frequency: 344, korean: "하늘", english: "The sky" },
  345: { frequency: 345, korean: "년대", english: "Year" },
  346: { frequency: 346, korean: "과학", english: "Science" },
  347: {
    frequency: 347,
    korean: "듯하다",
    english: "to be regarded as; to appear to be",
  },
  348: { frequency: 348, korean: "자연", english: "Nature" },
  349: { frequency: 349, korean: "정말", english: "true or genuine; Really" },
  350: { frequency: 350, korean: "구조", english: "Construction or structure" },
  351: { frequency: 352, korean: "결국", english: "After all or in the end" },
  352: { frequency: 353, korean: "밥", english: "Rice, a meal" },
  353: { frequency: 354, korean: "입다", english: "To wear" },
  354: { frequency: 355, korean: "오히려", english: "Rather,preferably" },
  355: { frequency: 356, korean: "프로그램", english: "Program" },
  356: { frequency: 357, korean: "네", english: "Yes" },
  357: {
    frequency: 359,
    korean: "이루어지다",
    english: "Get accomplished, achieved",
  },
  358: { frequency: 360, korean: "남", english: "Others, other people" },
  359: { frequency: 361, korean: "하루", english: "A day" },
  360: { frequency: 362, korean: "그림", english: "A picture" },
  361: { frequency: 363, korean: "적", english: "The time,the occasion,when" },
  362: { frequency: 364, korean: "터", english: "one’s status, one’s lot" },
  363: { frequency: 365, korean: "마시다", english: "To drink" },
  364: { frequency: 366, korean: "치다", english: "to attack, assault" },
  365: { frequency: 367, korean: "혼자", english: "Alone" },
  366: { frequency: 368, korean: "나가다", english: "To go out" },
  367: { frequency: 370, korean: "이제", english: "Now" },
  368: { frequency: 371, korean: "교수", english: "Teaching or instruction" },
  369: { frequency: 372, korean: "술", english: "Alcohol" },
  370: { frequency: 373, korean: "사랑", english: "Love" },
  371: { frequency: 374, korean: "의식", english: "consciousness; awareness" },
  372: { frequency: 375, korean: "전화", english: "Telephone" },
  373: {
    frequency: 376,
    korean: "끝나다",
    english: "To draw to a close or to end",
  },
  374: {
    frequency: 377,
    korean: "돌아오다",
    english: "to return, to come back",
  },
  375: {
    frequency: 378,
    korean: "맞다",
    english: "Be right, correct; to match, be fitting for",
  },
  376: { frequency: 379, korean: "아빠", english: "Dad" },
  377: {
    frequency: 380,
    korean: "걸리다",
    english: "To be hung up or suspended",
  },
  378: { frequency: 381, korean: "지키다", english: "Protect, maintain" },
  379: { frequency: 382, korean: "한번", english: "Once" },
  380: { frequency: 383, korean: "커피", english: "Coffee" },
  381: { frequency: 384, korean: "가슴", english: "Chest" },
  382: { frequency: 386, korean: "길다", english: "To be long" },
  383: {
    frequency: 387,
    korean: "바라보다",
    english: "Look at, watch ; to look forward to, hope for",
  },
  384: {
    frequency: 388,
    korean: "알아보다",
    english: "To investigate, examine, search",
  },
  385: { frequency: 389, korean: "회사", english: "A company" },
  386: { frequency: 390, korean: "맛", english: "Flavor" },
  387: { frequency: 391, korean: "대부분", english: "Most" },
  388: { frequency: 392, korean: "산업", english: "Industry" },
  389: { frequency: 393, korean: "매우", english: "Very" },
  390: { frequency: 394, korean: "오르다", english: "Go up, climb, ascend" },
  391: { frequency: 395, korean: "음식", english: "Food" },
  392: { frequency: 396, korean: "표정", english: "Facial expression, look" },
  393: { frequency: 397, korean: "꼭", english: "For sure" },
  394: { frequency: 398, korean: "일부", english: "A part, a portion" },
  395: { frequency: 399, korean: "요즘", english: "Recently, nowadays" },
  396: { frequency: 400, korean: "계획", english: "A plan or a project" },
  397: { frequency: 401, korean: "느낌", english: "Touch, feel" },
  398: { frequency: 402, korean: "얼마", english: "How many, how much" },
  399: { frequency: 403, korean: "고개", english: "head; nape of the neck" },
  400: {
    frequency: 404,
    korean: "성격",
    english: "Personality ; character, nature",
  },
  401: { frequency: 405, korean: "계속", english: "Continuously" },
  402: { frequency: 406, korean: "세기", english: "Century" },
  403: { frequency: 407, korean: "세우다", english: "Stand up, erect" },
  404: { frequency: 408, korean: "아내", english: "Wife" },
  405: { frequency: 409, korean: "가족", english: "Family" },
  406: {
    frequency: 411,
    korean: "현재",
    english: "The present time, now, at present",
  },
  407: { frequency: 413, korean: "세", english: "Three" },
  408: { frequency: 415, korean: "놓다", english: "Put, place" },
  409: { frequency: 416, korean: "발전", english: "Development" },
  410: { frequency: 417, korean: "차", english: "A vehicle, train/auto car" },
  411: { frequency: 418, korean: "놀다", english: "Play, amuse oneself" },
  412: { frequency: 419, korean: "향하다", english: "To face, look out on" },
  413: {
    frequency: 420,
    korean: "관련",
    english: "Relation or connection or reference",
  },
  414: { frequency: 421, korean: "형태", english: "Form, shape" },
  415: { frequency: 422, korean: "각", english: "Each or every" },
  416: { frequency: 423, korean: "도시", english: "City" },
  417: { frequency: 424, korean: "작업", english: "Work" },
  418: { frequency: 425, korean: "분위기", english: "atmosphere,surroundings" },
  419: { frequency: 426, korean: "그러하다", english: "To be so or right" },
  420: {
    frequency: 427,
    korean: "자기",
    english: "Oneself, number one, numerouno, self",
  },
  421: { frequency: 429, korean: "나이", english: "Age" },
  422: {
    frequency: 430,
    korean: "우선",
    english: "First of all, before everything",
  },
  423: { frequency: 431, korean: "믿다", english: "Believe" },
  424: { frequency: 432, korean: "바꾸다", english: "change,exchange" },
  425: { frequency: 433, korean: "낳다", english: "To give birth" },
  426: { frequency: 434, korean: "바", english: "A thing, what" },
  427: { frequency: 435, korean: "정보", english: "information,intelligence" },
  428: {
    frequency: 436,
    korean: "열리다",
    english: "Open, be opened, be unlocked",
  },
  429: { frequency: 438, korean: "좋아하다", english: "To like, be fond of" },
  430: {
    frequency: 439,
    korean: "그리다",
    english: "Picture or draw a picture",
  },
  431: { frequency: 440, korean: "만큼", english: "Of that amount" },
  432: { frequency: 441, korean: "배우다", english: "To learn" },
  433: { frequency: 442, korean: "시", english: "poem, poetry" },
  434: { frequency: 443, korean: "역할", english: "A part, a role" },
  435: { frequency: 444, korean: "옆", english: "Next to" },
  436: { frequency: 445, korean: "행동", english: "Action, behavior" },
  437: { frequency: 446, korean: "어", english: "Oh, well, why" },
  438: { frequency: 447, korean: "국내", english: "Inside the country" },
  439: { frequency: 448, korean: "비하다", english: "Compare to" },
  440: { frequency: 449, korean: "기관", english: "An engine or a machine" },
  441: { frequency: 450, korean: "입장", english: "A position, situation" },
  442: { frequency: 451, korean: "만하다", english: "Be of the extent of" },
  443: { frequency: 452, korean: "예", english: "Example" },
  444: {
    frequency: 453,
    korean: "아래",
    english: "The bottom, the lower part",
  },
  445: { frequency: 454, korean: "방식", english: "A form, method, process" },
  446: { frequency: 455, korean: "영향", english: "Influence, consequences" },
  447: { frequency: 457, korean: "그럼", english: "Certainly or of course." },
  448: { frequency: 458, korean: "나서다", english: "Come out, come forth" },
  449: { frequency: 459, korean: "흐르다", english: "Flow, stream" },
  450: { frequency: 460, korean: "저", english: "That" },
  451: { frequency: 461, korean: "깊다", english: "Deep" },
  452: { frequency: 462, korean: "배", english: "A boat (boating)" },
  453: { frequency: 463, korean: "내", english: "Inside" },
  454: { frequency: 464, korean: "모양", english: "A shape, form" },
  455: { frequency: 465, korean: "산", english: "A mountain" },
  456: { frequency: 466, korean: "새", english: "New" },
  457: { frequency: 467, korean: "하지만", english: "But, nevertheless" },
  458: { frequency: 468, korean: "조건", english: "condition,stipulation" },
  459: { frequency: 469, korean: "문", english: "Door" },
  460: { frequency: 470, korean: "꽃", english: "Flower" },
  461: { frequency: 471, korean: "단계", english: "A step, phase" },
  462: { frequency: 472, korean: "올리다", english: "Raise, lift up" },
  463: { frequency: 473, korean: "그동안", english: "During that time" },
  464: { frequency: 474, korean: "교사", english: "Instructor" },
  465: { frequency: 475, korean: "갑자기", english: "Suddenly" },
  466: { frequency: 476, korean: "넘다", english: "Cross or go across" },
  467: {
    frequency: 477,
    korean: "지니다",
    english: "Carry with, hold, possess",
  },
  468: { frequency: 478, korean: "바람", english: "Wind" },
  469: { frequency: 479, korean: "잘하다", english: "To do well" },
  470: { frequency: 480, korean: "마을", english: "Town" },
  471: {
    frequency: 481,
    korean: "어리다",
    english: "To be very young, juvenile",
  },
  472: { frequency: 482, korean: "대표", english: "Representative" },
  473: { frequency: 483, korean: "가능성", english: "Possibility" },
  474: { frequency: 484, korean: "방향", english: "Direction" },
  475: { frequency: 485, korean: "대회", english: "A great meeting" },
  476: { frequency: 486, korean: "목소리", english: "Voice" },
  477: { frequency: 487, korean: "노래", english: "Song" },
  478: { frequency: 488, korean: "바다", english: "Sea" },
  479: { frequency: 489, korean: "힘들다", english: "To be hard, difficult" },
  480: { frequency: 490, korean: "공부", english: "Study" },
  481: { frequency: 491, korean: "움직이다", english: "To move, stir" },
  482: { frequency: 492, korean: "의원", english: "A member" },
  483: { frequency: 494, korean: "노력", english: "Effort" },
  484: { frequency: 495, korean: "못하다", english: "Be impossible" },
  485: {
    frequency: 496,
    korean: "전혀",
    english: "entirely,utterly,completely",
  },
  486: { frequency: 497, korean: "언니", english: "Older sister" },
  487: { frequency: 498, korean: "단체", english: "A corps, a group" },
  488: { frequency: 499, korean: "분", english: "Minutes" },
  489: { frequency: 500, korean: "알려지다", english: "To become known" },
  490: { frequency: 501, korean: "가능하다", english: "To be possible" },
  491: {
    frequency: 502,
    korean: "능력",
    english: "Ability, capability, how much and how well",
  },
  492: { frequency: 503, korean: "주장하다", english: "To assert,maintain" },
  493: { frequency: 504, korean: "자식", english: "one’s children" },
  494: {
    frequency: 505,
    korean: "돌리다",
    english: "to turn (e.g. A clock), to spin",
  },
  495: { frequency: 506, korean: "불", english: "Fire" },
  496: { frequency: 507, korean: "주민", english: "inhabitants,dwellers" },
  497: { frequency: 508, korean: "모으다", english: "gather,get together" },
  498: { frequency: 509, korean: "자료", english: "materials,data" },
  499: { frequency: 510, korean: "존재", english: "Existence" },
  500: { frequency: 512, korean: "학년", english: "A school year" },
  501: { frequency: 513, korean: "신문", english: "A newspaper" },
  502: { frequency: 514, korean: "가지다", english: "To have or take or hold" },
  503: { frequency: 515, korean: "이해하다", english: "To understand" },
  504: { frequency: 516, korean: "제품", english: "Manufactured goods" },
  505: { frequency: 517, korean: "분야", english: "Field" },
  506: { frequency: 518, korean: "선생", english: "Teacher" },
  507: { frequency: 519, korean: "돌아가다", english: "to return, to go back" },
  508: { frequency: 520, korean: "사업", english: "Business" },
  509: { frequency: 521, korean: "행위", english: "An act, deed, behavior" },
  510: { frequency: 522, korean: "수준", english: "Level" },
  511: { frequency: 523, korean: "지난해", english: "Last year" },
  512: {
    frequency: 524,
    korean: "표현",
    english: "Verbal expression, representation, manifestation",
  },
  513: { frequency: 525, korean: "기분", english: "Mood" },
  514: { frequency: 527, korean: "대", english: "generation; replacement" },
  515: { frequency: 528, korean: "젊다", english: "To be young,youthful" },
  516: {
    frequency: 529,
    korean: "동시",
    english: "the same time; concurrently ",
  },
  517: { frequency: 530, korean: "옷", english: "Clothes" },
  518: { frequency: 531, korean: "기능", english: "Function or functionality" },
  519: { frequency: 532, korean: "순간", english: "A moment,a second" },
  520: { frequency: 533, korean: "전쟁", english: "War" },
  521: { frequency: 534, korean: "전", english: "Before" },
  522: { frequency: 535, korean: "꿈", english: "A Dream" },
  523: { frequency: 536, korean: "할머니", english: "Grandmother" },
  524: { frequency: 537, korean: "회의", english: "A meeting, a conference" },
  525: { frequency: 538, korean: "방송", english: "Broadcast" },
  526: { frequency: 539, korean: "이야기하다", english: "To talk" },
  527: { frequency: 540, korean: "나무", english: "Tree" },
  528: { frequency: 541, korean: "자다", english: "To sleep" },
  529: { frequency: 543, korean: "연극", english: "drama,a play" },
  530: { frequency: 544, korean: "오늘", english: "Today" },
  531: { frequency: 545, korean: "마찬가지", english: "The same" },
  532: { frequency: 546, korean: "걷다", english: "To walk" },
  533: { frequency: 547, korean: "노동", english: "Labor, work" },
  534: { frequency: 548, korean: "이때", english: "At this time, moment" },
  535: { frequency: 549, korean: "과거", english: "The past" },
  536: { frequency: 550, korean: "가치", english: "Price" },
  537: { frequency: 551, korean: "시간", english: "Time" },
  538: {
    frequency: 552,
    korean: "집단",
    english: "A group, a collective body",
  },
  539: { frequency: 553, korean: "현대", english: "The present age, times" },
  540: { frequency: 555, korean: "살펴보다", english: "Watch closely" },
  541: {
    frequency: 556,
    korean: "장관",
    english: "A government cabinet minister",
  },
  542: { frequency: 557, korean: "차이", english: "difference,disparity" },
  543: {
    frequency: 558,
    korean: "풀다",
    english: "Untie, loosen ; to melt into",
  },
  544: { frequency: 559, korean: "시절", english: "season,time,occasion" },
  545: { frequency: 560, korean: "물건", english: "A thing" },
  546: { frequency: 561, korean: "직접", english: "Directly" },
  547: { frequency: 562, korean: "개인", english: "Private or individual" },
  548: { frequency: 563, korean: "근데", english: "But or however" },
  549: { frequency: 564, korean: "발", english: "Foot" },
  550: { frequency: 565, korean: "작가", english: "writer,author" },
  551: { frequency: 566, korean: "효과", english: "Effect, effectiveness" },
  552: { frequency: 567, korean: "불교", english: "Buddhism" },
  553: { frequency: 568, korean: "끌다", english: "Pull" },
  554: { frequency: 569, korean: "대로", english: "Like, according to" },
  555: { frequency: 572, korean: "빨리", english: "Quickly" },
  556: { frequency: 573, korean: "시작되다", english: "To begin,start" },
  557: { frequency: 575, korean: "둘", english: "two (with counters)" },
  558: { frequency: 576, korean: "말다", english: "Cease" },
  559: { frequency: 577, korean: "설명하다", english: "To explain" },
  560: { frequency: 578, korean: "우주", english: "The universe" },
  561: { frequency: 579, korean: "시기", english: "An opportunity,chance" },
  562: { frequency: 580, korean: "마치", english: "As though, as if" },
  563: { frequency: 581, korean: "살", english: "Years old" },
  564: { frequency: 582, korean: "생산", english: "1) production 2)birth" },
  565: { frequency: 583, korean: "바라다", english: "Wish, hope" },
  566: {
    frequency: 584,
    korean: "강하다",
    english: "To be strong or powerful",
  },
  567: {
    frequency: 585,
    korean: "경험",
    english: "Experience or undergo or suffer",
  },
  568: { frequency: 586, korean: "음악", english: "Music" },
  569: { frequency: 587, korean: "최고", english: "The best" },
  570: { frequency: 588, korean: "나타내다", english: "Show, display" },
  571: { frequency: 589, korean: "아프다", english: "To hurt" },
  572: {
    frequency: 590,
    korean: "적다",
    english: "To be small, few in number",
  },
  573: { frequency: 592, korean: "비", english: "Rain" },
  574: { frequency: 593, korean: "고향", english: "Hometown" },
  575: { frequency: 594, korean: "놀라다", english: "Be surprised" },
  576: {
    frequency: 595,
    korean: "다양하다",
    english: "To be various, diverse",
  },
  577: { frequency: 596, korean: "울다", english: "To cry, weep" },
  578: { frequency: 597, korean: "농민", english: "A farmer" },
  579: {
    frequency: 598,
    korean: "드러나다",
    english: "to appear, to become manifest",
  },
  580: { frequency: 599, korean: "은행", english: "A bank" },
  581: { frequency: 600, korean: "지내다", english: "To pass, spend time" },
  582: { frequency: 601, korean: "결혼", english: "Marriage" },
  583: { frequency: 602, korean: "동생", english: "younger sibling" },
  584: { frequency: 603, korean: "법", english: "A law, the law" },
  585: { frequency: 604, korean: "소설", english: "A novel, fiction story" },
  586: { frequency: 605, korean: "예", english: "Example" },
  587: { frequency: 606, korean: "오후", english: "The afternoon" },
  588: { frequency: 607, korean: "질서", english: "Order, system" },
  589: { frequency: 609, korean: "담다", english: "Put in a bottle" },
  590: { frequency: 610, korean: "모이다", english: "Meet, assemble" },
  591: { frequency: 611, korean: "시민", english: "Residents of a city" },
  592: { frequency: 612, korean: "회장", english: "Chariman, the president" },
  593: { frequency: 613, korean: "빠르다", english: "Be fast, quick" },
  594: {
    frequency: 614,
    korean: "스스로",
    english: "On its own, of its own free will",
  },
  595: { frequency: 615, korean: "아기", english: "baby,infant" },
  596: {
    frequency: 616,
    korean: "아저씨",
    english: "Sir, calling an unknown male",
  },
  597: { frequency: 617, korean: "옛날", english: "Ancient times, antiquity" },
  598: { frequency: 618, korean: "이날", english: "Today, this day" },
  599: { frequency: 619, korean: "제대로", english: "As properly is suitable" },
  600: { frequency: 620, korean: "달", english: "The moon" },
  601: { frequency: 621, korean: "던지다", english: "To throw" },
  602: { frequency: 622, korean: "참", english: "really,truly" },
  603: { frequency: 623, korean: "공간", english: "Space or room" },
  604: { frequency: 624, korean: "이곳", english: "This place, here" },
  605: { frequency: 625, korean: "딸", english: "daughter (female child)" },
  606: { frequency: 626, korean: "마지막", english: "Last, final" },
  607: {
    frequency: 627,
    korean: "벌이다",
    english: "(1) to plan to start a job/project (2) to play a table game",
  },
  608: { frequency: 628, korean: "병원", english: "Hospital" },
  609: { frequency: 630, korean: "자세", english: "Position, posture" },
  610: { frequency: 631, korean: "강조하다", english: "Place empasis upon" },
  611: { frequency: 632, korean: "경찰", english: "The police" },
  612: { frequency: 633, korean: "맡다", english: "Take charge of" },
  613: { frequency: 634, korean: "저녁", english: "Evening" },
  614: {
    frequency: 635,
    korean: "한편",
    english: "One side, one way; on the other hand, while",
  },
  615: {
    frequency: 636,
    korean: "그러면",
    english: "If so or if that is the case",
  },
  616: { frequency: 637, korean: "기자", english: "A journalist" },
  617: { frequency: 638, korean: "넓다", english: "Broad" },
  618: { frequency: 639, korean: "시험", english: "A test" },
  619: { frequency: 640, korean: "잠", english: "Sleep" },
  620: { frequency: 641, korean: "주로", english: "principally,mainly" },
  621: { frequency: 642, korean: "훨씬", english: "By far, very much so" },
  622: { frequency: 643, korean: "면", english: "Side" },
  623: { frequency: 645, korean: "통일", english: "Unification" },
  624: {
    frequency: 646,
    korean: "들어서다",
    english: "to enter, to take up a position, to occupy",
  },
  625: { frequency: 647, korean: "건강", english: "Health" },
  626: { frequency: 648, korean: "가깝다", english: "Close" },
  627: { frequency: 649, korean: "건물", english: "Building" },
  628: { frequency: 650, korean: "시설", english: "establishment,institution" },
  629: { frequency: 651, korean: "외국", english: "A foreign country" },
  630: { frequency: 652, korean: "밑", english: "The bottom" },
  631: { frequency: 653, korean: "어른", english: "A man, an adult" },
  632: { frequency: 654, korean: "주변", english: "A circumference" },
  633: { frequency: 655, korean: "대신", english: "Instead of" },
  634: { frequency: 656, korean: "원인", english: "A cause,a factor" },
  635: { frequency: 657, korean: "팔다", english: "To sell" },
  636: {
    frequency: 658,
    korean: "차례",
    english: "(1) order,precedence (2) one time, one round",
  },
  637: { frequency: 659, korean: "군", english: "An army" },
  638: {
    frequency: 660,
    korean: "열심히",
    english: "Enthusiastically,with zeal",
  },
  639: { frequency: 661, korean: "일하다", english: "To work, labor" },
  640: {
    frequency: 662,
    korean: "재산",
    english: "property,fortune, assets, possessions",
  },
  641: { frequency: 663, korean: "조금", english: "A little" },
  642: { frequency: 664, korean: "팀", english: "Team" },
  643: { frequency: 665, korean: "부모", english: "Parents" },
  644: { frequency: 666, korean: "약간", english: "a little, a bit; somewhat" },
  645: { frequency: 667, korean: "언어", english: "language,speech" },
  646: {
    frequency: 668,
    korean: "요구하다",
    english: "To demand,claim,request",
  },
  647: { frequency: 669, korean: "올라가다", english: "To go up, ascend" },
  648: { frequency: 670, korean: "첫", english: "First" },
  649: { frequency: 671, korean: "감독", english: "The director" },
  650: { frequency: 672, korean: "그날", english: "That day or that same day" },
  651: { frequency: 673, korean: "사실", english: "The truth, a fact" },
  652: { frequency: 674, korean: "자주", english: "Always, constantly" },
  653: { frequency: 675, korean: "당하다", english: "Have, encounter" },
  654: { frequency: 676, korean: "삼다", english: "Make a thing of" },
  655: { frequency: 677, korean: "약", english: "About, approximately" },
  656: { frequency: 678, korean: "기간", english: "A period or a time or" },
  657: { frequency: 679, korean: "담배", english: "Tobacco" },
  658: {
    frequency: 680,
    korean: "일으키다",
    english: "Riase, get up; start, commence",
  },
  659: { frequency: 681, korean: "일단", english: "Temporarily" },
  660: { frequency: 682, korean: "할아버지", english: "Grandfather" },
  661: {
    frequency: 683,
    korean: "조직",
    english: "Organization (composition, structure)",
  },
  662: {
    frequency: 684,
    korean: "태어나다",
    english: "To be born, see the light of day",
  },
  663: { frequency: 685, korean: "공장", english: "Construction site" },
  664: { frequency: 686, korean: "벌써", english: "Already" },
  665: {
    frequency: 687,
    korean: "즐기다",
    english: "Enjoy oneself,take pleasure,delight",
  },
  666: { frequency: 688, korean: "지", english: "since,from" },
  667: { frequency: 689, korean: "환자", english: "A patient" },
  668: { frequency: 690, korean: "변하다", english: "Change" },
  669: { frequency: 691, korean: "사고", english: "An accident" },
  670: { frequency: 692, korean: "그래도", english: "All the same" },
  671: { frequency: 693, korean: "아무리", english: "However much" },
  672: { frequency: 694, korean: "맞추다", english: "Adjust, adapt" },
  673: { frequency: 695, korean: "쌀", english: "Uncooked rice" },
  674: { frequency: 696, korean: "일반", english: "universal,common,usual" },
  675: { frequency: 697, korean: "재미있다", english: "interesting,fun" },
  676: { frequency: 698, korean: "가르치다", english: "To teach" },
  677: { frequency: 699, korean: "대화", english: "Conversation" },
  678: { frequency: 700, korean: "막다", english: "Stop, obstruct" },
  679: { frequency: 701, korean: "올해", english: "This year" },
  680: { frequency: 702, korean: "형", english: "An elder brother" },
  681: { frequency: 703, korean: "달리", english: "Differently" },
  682: {
    frequency: 704,
    korean: "버리다",
    english:
      "(following a verb) the verb’s action is over, perhaps in a sad way",
  },
  683: { frequency: 705, korean: "붙이다", english: "affix,put on" },
  684: { frequency: 706, korean: "인물", english: "A man, a person" },
  685: { frequency: 707, korean: "늘", english: "Always" },
  686: { frequency: 708, korean: "모두", english: "Everybody" },
  687: { frequency: 709, korean: "전국", english: "The whole country" },
  688: { frequency: 711, korean: "마치다", english: "Finish, complete" },
  689: { frequency: 712, korean: "전", english: "whole; entire" },
  690: { frequency: 713, korean: "다만", english: "Only, merely" },
  691: { frequency: 714, korean: "도움", english: "Help" },
  692: { frequency: 715, korean: "가정", english: "Famly" },
  693: { frequency: 716, korean: "걸다", english: "Hang up or suspend" },
  694: { frequency: 717, korean: "빠지다", english: "To fall into" },
  695: { frequency: 718, korean: "멀다", english: "To be far" },
  696: { frequency: 719, korean: "버스", english: "Bus" },
  697: { frequency: 721, korean: "오늘날", english: "These days, nowadays" },
  698: {
    frequency: 722,
    korean: "잠시",
    english: "For a little while, temporary",
  },
  699: { frequency: 723, korean: "농업", english: "Agriculture" },
  700: { frequency: 724, korean: "대다", english: "Put, place up to" },
  701: { frequency: 725, korean: "식", english: "form,style" },
  702: { frequency: 726, korean: "의견", english: "An opinion,a suggestion" },
  703: { frequency: 727, korean: "무대", english: "Stage" },
  704: { frequency: 728, korean: "사진", english: "Picture" },
  705: { frequency: 729, korean: "주장", english: "assertion,insistance" },
  706: {
    frequency: 730,
    korean: "표현하다",
    english: "To express, make known by saying",
  },
  707: { frequency: 731, korean: "인하다", english: "To be due to, caused by" },
  708: { frequency: 732, korean: "이상하다", english: "To be strange,queer" },
  709: { frequency: 733, korean: "제일", english: "The first" },
  710: { frequency: 734, korean: "붙다", english: "Stick, adhere to" },
  711: { frequency: 735, korean: "아마", english: "Probably" },
  712: { frequency: 736, korean: "얘기하다", english: "To tell a story" },
  713: { frequency: 737, korean: "잇다", english: "Join, connect" },
  714: { frequency: 738, korean: "조금", english: "A little" },
  715: { frequency: 739, korean: "경기", english: "Race or match or contest" },
  716: { frequency: 740, korean: "목적", english: "goal,purpose" },
  717: { frequency: 741, korean: "태도", english: "attitude,comportment" },
  718: { frequency: 742, korean: "남성", english: "Male" },
  719: {
    frequency: 743,
    korean: "주위",
    english: "The circumference,the girth",
  },
  720: { frequency: 744, korean: "대책", english: "Countermeasure" },
  721: {
    frequency: 745,
    korean: "그만",
    english: "That much or a little amount",
  },
  722: { frequency: 746, korean: "발생하다", english: "Originate, come from" },
  723: { frequency: 747, korean: "다리", english: "Leg" },
  724: { frequency: 748, korean: "아무", english: "anybody,everybody" },
  725: { frequency: 749, korean: "어쩌다", english: "By chance, accident" },
  726: { frequency: 750, korean: "재료", english: "stuff,material" },
  727: { frequency: 751, korean: "각각", english: "Each and every" },
  728: { frequency: 752, korean: "결코", english: "Never or by no means" },
  729: {
    frequency: 753,
    korean: "옮기다",
    english: "Move, transfer (2)(sickness) communicate, transfer to",
  },
  730: { frequency: 754, korean: "항상", english: "Always" },
  731: { frequency: 755, korean: "해", english: "A year" },
  732: {
    frequency: 756,
    korean: "잃다",
    english: "To lose, to miss, to be deprived",
  },
  733: { frequency: 757, korean: "자유", english: "Freedom" },
  734: { frequency: 758, korean: "책임", english: "Responsibility" },
  735: { frequency: 759, korean: "바뀌다", english: "Be changed" },
  736: { frequency: 760, korean: "비슷하다", english: "To resemble" },
  737: { frequency: 761, korean: "심하다", english: "To be extreme,intense" },
  738: { frequency: 762, korean: "경쟁", english: "Competition or rivalry" },
  739: { frequency: 763, korean: "달러", english: "Dollar" },
  740: { frequency: 764, korean: "사랑하다", english: "To love" },
  741: { frequency: 765, korean: "아니", english: "No!" },
  742: { frequency: 766, korean: "여름", english: "summer,summertime" },
  743: { frequency: 767, korean: "자라다", english: "To grow up" },
  744: {
    frequency: 768,
    korean: "회",
    english: "A time, an inning, a round in a game",
  },
  745: { frequency: 769, korean: "구체적", english: "Concretely" },
  746: { frequency: 770, korean: "기회", english: "An opportunity" },
  747: {
    frequency: 771,
    korean: "실시하다",
    english: "To enforce,put into effect",
  },
  748: { frequency: 773, korean: "지구", english: "The earth" },
  749: { frequency: 774, korean: "번째", english: "How many times" },
  750: { frequency: 775, korean: "소비자", english: "A consumer" },
  751: { frequency: 776, korean: "싫다", english: "To not like, to hate" },
  752: {
    frequency: 778,
    korean: "규모",
    english: "Scale or scope or structure",
  },
  753: { frequency: 779, korean: "기준", english: "A standard or basis" },
  754: { frequency: 780, korean: "말", english: "end (of a period); late" },
  755: { frequency: 781, korean: "반드시", english: "Most certainly" },
  756: { frequency: 782, korean: "셈", english: "An intention,design,purpose" },
  757: {
    frequency: 784,
    korean: "갖추다",
    english: "Make or get ready or prepare",
  },
  758: { frequency: 785, korean: "그러니까", english: "And so it is that" },
  759: { frequency: 787, korean: "받아들이다", english: "Accept" },
  760: { frequency: 788, korean: "값", english: "Price" },
  761: {
    frequency: 789,
    korean: "현장",
    english: "on site, on location, at the site in question",
  },
  762: { frequency: 790, korean: "건설", english: "Construction" },
  763: { frequency: 791, korean: "꺼내다", english: "To get out or draw out" },
  764: { frequency: 792, korean: "노동자", english: "Laborer, worker" },
  765: { frequency: 793, korean: "동네", english: "neighborhood" },
  766: { frequency: 794, korean: "언제나", english: "everytime,always" },
  767: { frequency: 795, korean: "완전히", english: "completely,perfectly" },
  768: { frequency: 796, korean: "자동차", english: "Car" },
  769: { frequency: 797, korean: "전하다", english: "To transmit,convey" },
  770: { frequency: 798, korean: "존재하다", english: "To exist" },
  771: { frequency: 799, korean: "개월", english: "Each and every month" },
  772: {
    frequency: 800,
    korean: "맞다",
    english: "to take as a courtesy, to receive a guest",
  },
  773: { frequency: 801, korean: "별로", english: "Especially, particularly" },
  774: { frequency: 802, korean: "어린이", english: "A youngster, a child" },
  775: { frequency: 803, korean: "정하다", english: "To set,determine" },
  776: { frequency: 804, korean: "한마디", english: "One word" },
  777: { frequency: 805, korean: "유지하다", english: "To preserve, maintain" },
  778: { frequency: 806, korean: "이데올로기", english: "Ideology" },
  779: { frequency: 807, korean: "공부하다", english: "To study" },
  780: { frequency: 808, korean: "대중", english: "The masses" },
  781: { frequency: 809, korean: "늘어나다", english: "Grow longer" },
  782: { frequency: 810, korean: "닦다", english: "Wipe clean, polish" },
  783: { frequency: 811, korean: "만", english: "As many as, full, complete" },
  784: { frequency: 812, korean: "말씀", english: "Speech" },
  785: { frequency: 813, korean: "괜찮다", english: "To be okay" },
  786: { frequency: 814, korean: "눈물", english: "A tear" },
  787: { frequency: 815, korean: "각종", english: "All sorts or varieties" },
  788: { frequency: 816, korean: "빛", english: "Light" },
  789: { frequency: 817, korean: "아니", english: "No!" },
  790: { frequency: 818, korean: "피하다", english: "avoid,keep away" },
  791: { frequency: 819, korean: "거치다", english: "Pass or go through" },
  792: { frequency: 820, korean: "나아가다", english: "Advance, go forward" },
  793: {
    frequency: 821,
    korean: "야",
    english:
      "(Word to get somebody’s attention) Hey!! (2) impolite speech for 이다, to be",
  },
  794: { frequency: 822, korean: "지식", english: "Knowledge, information" },
  795: {
    frequency: 823,
    korean: "현재",
    english: "The present time, now, at present",
  },
  796: {
    frequency: 824,
    korean: "여전히",
    english: "As it used to be, as before ; still, persistently, even still",
  },
  797: {
    frequency: 825,
    korean: "주인",
    english: "The head of a family,the owner",
  },
  798: { frequency: 826, korean: "발견하다", english: "To discover" },
  799: { frequency: 827, korean: "선", english: "A line, a route" },
  800: { frequency: 828, korean: "인류", english: "The human race" },
  801: {
    frequency: 829,
    korean: "특징",
    english: "A special feature, a distinguishing feature",
  },
  802: {
    frequency: 830,
    korean: "드리다",
    english: "humble form of 주다 (juda); to offer, to give, to present",
  },
  803: { frequency: 831, korean: "선수", english: "An athlete" },
  804: { frequency: 832, korean: "형식", english: "A form, formality, mold" },
  805: { frequency: 833, korean: "마련하다", english: "To plan, arrange" },
  806: { frequency: 834, korean: "반", english: "Half" },
  807: { frequency: 835, korean: "발표하다", english: "To announce" },
  808: { frequency: 836, korean: "주제", english: "Subject, theme, topic" },
  809: {
    frequency: 837,
    korean: "걸치다",
    english: "1) put a thing over or across 2) to range from A to B",
  },
  810: {
    frequency: 838,
    korean: "겪다",
    english: "Experience or undergo or suffer",
  },
  811: {
    frequency: 839,
    korean: "관점",
    english: "A point of view or a standpoint/outlook",
  },
  812: { frequency: 840, korean: "귀", english: "Ear" },
  813: { frequency: 842, korean: "기본", english: "A foundation" },
  814: { frequency: 843, korean: "미터", english: "Meter" },
  815: { frequency: 844, korean: "사라지다", english: "To disappear" },
  816: { frequency: 845, korean: "어떠하다", english: "To be how" },
  817: { frequency: 846, korean: "감정", english: "Feelings" },
  818: { frequency: 847, korean: "기억", english: "A memory" },
  819: { frequency: 848, korean: "놈", english: "A fellow" },
  820: { frequency: 849, korean: "인기", english: "Popularity" },
  821: { frequency: 850, korean: "배", english: "abdomen, belly" },
  822: { frequency: 851, korean: "아파트", english: "Apartment" },
  823: { frequency: 852, korean: "가끔", english: "Sometimes" },
  824: {
    frequency: 853,
    korean: "구성",
    english: "Organization or constitution , the framework of something",
  },
  825: { frequency: 854, korean: "술", english: "scoop; spoonful of" },
  826: { frequency: 855, korean: "실제로", english: "In reality" },
  827: { frequency: 856, korean: "짧다", english: "Short, brief" },
  828: { frequency: 857, korean: "고맙다", english: "To thank" },
  829: {
    frequency: 858,
    korean: "관리",
    english: "Management or administration",
  },
  830: { frequency: 860, korean: "그곳", english: "That place" },
  831: { frequency: 861, korean: "달다", english: "to ask to do: to demand" },
  832: { frequency: 862, korean: "보다", english: "relatively, more" },
  833: { frequency: 863, korean: "비롯하다", english: "begin,start" },
  834: { frequency: 865, korean: "과연", english: "Just as one thought" },
  835: {
    frequency: 866,
    korean: "들리다",
    english: "(passive) to be heard; (causative) to cause to hear",
  },
  836: { frequency: 867, korean: "달리다", english: "run, make do fast" },
  837: { frequency: 868, korean: "바쁘다", english: "Busy" },
  838: { frequency: 869, korean: "이전", english: "Former days/times" },
  839: {
    frequency: 870,
    korean: "인정하다",
    english: "To acknowledge,authorize",
  },
  840: { frequency: 871, korean: "자", english: "A person" },
  841: { frequency: 872, korean: "중앙", english: "The middle and the heart" },
  842: { frequency: 873, korean: "나쁘다", english: "To be bad" },
  843: {
    frequency: 874,
    korean: "불구하다",
    english: "Deformity, malformation",
  },
  844: { frequency: 876, korean: "시키다", english: "To make" },
  845: { frequency: 877, korean: "게임", english: "Game" },
  846: { frequency: 878, korean: "국제", english: "International" },
  847: { frequency: 879, korean: "그룹", english: "Group" },
  848: { frequency: 880, korean: "인생", english: "Life" },
  849: { frequency: 881, korean: "전통", english: "tradition,convention" },
  850: {
    frequency: 882,
    korean: "기르다",
    english: "Educate or train or cultivate",
  },
  851: { frequency: 884, korean: "잔", english: "A cup, wine glass" },
  852: {
    frequency: 885,
    korean: "조사하다",
    english: "To examine,investigate",
  },
  853: { frequency: 886, korean: "커다랗다", english: "To be very big/large" },
  854: { frequency: 888, korean: "있다", english: "To be" },
  855: { frequency: 890, korean: "시인", english: "A poet" },
  856: { frequency: 891, korean: "언제", english: "when,at what time" },
  857: { frequency: 892, korean: "외", english: "Except, save for" },
  858: { frequency: 893, korean: "평가", english: "Evaluation, appraisal" },
  859: { frequency: 894, korean: "내려오다", english: "Come down" },
  860: { frequency: 895, korean: "위치", english: "A position, a location" },
  861: { frequency: 896, korean: "줄이다", english: "reduce,decrease" },
  862: { frequency: 898, korean: "가격", english: "Price" },
  863: { frequency: 899, korean: "달라지다", english: "To change, vary" },
  864: { frequency: 900, korean: "비다", english: "To be empty, vacant" },
  865: { frequency: 901, korean: "삼국", english: "Three countries" },
  866: { frequency: 902, korean: "손님", english: "Customer" },
  867: { frequency: 903, korean: "원하다", english: "To want" },
  868: {
    frequency: 904,
    korean: "통신",
    english: "communications,correspondance",
  },
  869: {
    frequency: 905,
    korean: "확인하다",
    english: "To confirm,corroborate",
  },
  870: { frequency: 906, korean: "모임", english: "A group, a party" },
  871: { frequency: 907, korean: "수", english: "number" },
  872: { frequency: 908, korean: "아무", english: "anybody,everybody" },
  873: { frequency: 909, korean: "웃음", english: "Laughter, a smile" },
  874: {
    frequency: 910,
    korean: "기계",
    english: "An instrument or a machine",
  },
  875: { frequency: 911, korean: "모양", english: "A shape, form" },
  876: { frequency: 912, korean: "물질", english: "matter,material" },
  877: { frequency: 913, korean: "아나운서", english: "Announcer" },
  878: { frequency: 914, korean: "뉴스", english: "News" },
  879: {
    frequency: 915,
    korean: "살아가다",
    english: "Lead a life, get along",
  },
  880: { frequency: 916, korean: "펴다", english: "Spread out, unfold" },
  881: {
    frequency: 918,
    korean: "배",
    english: "double, twice, twofold; times, -fold",
  },
  882: { frequency: 919, korean: "수업", english: "school,teaching" },
  883: { frequency: 920, korean: "겨울", english: "Winter" },
  884: { frequency: 921, korean: "종교", english: "religion,a faith" },
  885: { frequency: 922, korean: "층", english: "floor,grade,class" },
  886: { frequency: 924, korean: "자연스럽다", english: "To be natural" },
  887: {
    frequency: 925,
    korean: "장",
    english: "One piece of sth flat (paper); sheets",
  },
  888: {
    frequency: 927,
    korean: "돌다",
    english: "to revolve, rotate, spin, turn",
  },
  889: { frequency: 928, korean: "식사", english: "A meal" },
  890: { frequency: 929, korean: "안다", english: "To hold, embrace" },
  891: { frequency: 930, korean: "이해", english: "Understanding" },
  892: { frequency: 931, korean: "잊다", english: "To forget" },
  893: { frequency: 932, korean: "제시하다", english: "To present" },
  894: {
    frequency: 933,
    korean: "반",
    english: "party; group; class at school",
  },
  895: { frequency: 934, korean: "불과하다", english: "Nothing more than" },
  896: { frequency: 936, korean: "혹은", english: "If that’s not the case" },
  897: {
    frequency: 937,
    korean: "엄청나다",
    english: "To be absurd,wild,terribly large",
  },
  898: {
    frequency: 939,
    korean: "편",
    english: "Direction, way (the wind is blowing that ~)",
  },
  899: { frequency: 940, korean: "텔레비전", english: "Television" },
  900: {
    frequency: 941,
    korean: "파악하다",
    english: "Grasp, seize, understand",
  },
  901: { frequency: 942, korean: "편", english: "volume (of set of books)" },
  902: { frequency: 943, korean: "실천", english: "Practice" },
  903: { frequency: 944, korean: "노력하다", english: "To try hard, strive" },
  904: { frequency: 945, korean: "보호", english: "protection,shelter" },
  905: { frequency: 947, korean: "씻다", english: "To wash,cleanse" },
  906: { frequency: 948, korean: "한편", english: "One side, one way" },
  907: { frequency: 949, korean: "늦다", english: "To be late (time)" },
  908: { frequency: 951, korean: "이웃", english: "The neighborhood" },
  909: { frequency: 952, korean: "편지", english: "Letter" },
  910: {
    frequency: 953,
    korean: "공동",
    english: "Association or union or collaboration",
  },
  911: { frequency: 954, korean: "까닭", english: "Reason" },
  912: { frequency: 955, korean: "방안", english: "A plan, scheme" },
  913: { frequency: 956, korean: "센티미터", english: "Centimeter" },
  914: { frequency: 957, korean: "팔", english: "An arm" },
  915: { frequency: 958, korean: "분명하다", english: "To be clear/obvious" },
  916: { frequency: 959, korean: "분석", english: "Analysis" },
  917: { frequency: 960, korean: "소녀", english: "A young girl" },
  918: { frequency: 961, korean: "지나가다", english: "To pass, elapse" },
  919: { frequency: 962, korean: "차", english: "second; next, subsequent" },
  920: { frequency: 963, korean: "상품", english: "Product" },
  921: { frequency: 965, korean: "설명", english: "Explanation" },
  922: { frequency: 966, korean: "훌륭하다", english: "To be excellent" },
  923: {
    frequency: 967,
    korean: "관계자",
    english: "The interested or affected parties",
  },
  924: { frequency: 968, korean: "새로", english: "newly,anew" },
  925: { frequency: 969, korean: "세", english: "years, years old" },
  926: {
    frequency: 970,
    korean: "이어지다",
    english: "Get joined, be connected",
  },
  927: { frequency: 971, korean: "티브이", english: "TV" },
  928: { frequency: 972, korean: "봄", english: "Spring" },
  929: { frequency: 973, korean: "종류", english: "kind,sort,species" },
  930: { frequency: 974, korean: "낮다", english: "Be low" },
  931: { frequency: 975, korean: "어깨", english: "Shoulder" },
  932: {
    frequency: 976,
    korean: "지적하다",
    english: "To indicate, point out",
  },
  933: { frequency: 977, korean: "부부", english: "Man and wife" },
  934: { frequency: 978, korean: "오래", english: "Long, for a long time" },
  935: { frequency: 979, korean: "요구", english: "A demand, claim, request" },
  936: {
    frequency: 980,
    korean: "키우다",
    english: "To raise, bring up, rear",
  },
  937: { frequency: 981, korean: "눕다", english: "Lie down" },
  938: { frequency: 982, korean: "발달하다", english: "To advance" },
  939: { frequency: 983, korean: "발전하다", english: "To develop, grow" },
  940: { frequency: 984, korean: "여행", english: "Trip" },
  941: { frequency: 985, korean: "죽음", english: "Death" },
  942: { frequency: 986, korean: "고통", english: "Suffering or agony" },
  943: {
    frequency: 987,
    korean: "등장하다",
    english: "1. to enter the stage; to come into play",
  },
  944: { frequency: 988, korean: "공", english: "Ball" },
  945: { frequency: 989, korean: "듯이", english: "nearly, as if" },
  946: {
    frequency: 990,
    korean: "어울리다",
    english: "To be becoming, suiting",
  },
  947: { frequency: 991, korean: "오월", english: "May" },
  948: { frequency: 992, korean: "쉬다", english: "to relax, take a break" },
  949: {
    frequency: 993,
    korean: "알리다",
    english: "To inform, tell a person",
  },
  950: {
    frequency: 994,
    korean: "차다",
    english: "To be full, to be filled with (PASSIVE)",
  },
  951: {
    frequency: 996,
    korean: "과",
    english: "A lesson or a section or a department",
  },
  952: { frequency: 997, korean: "멀리", english: "Far away" },
  953: { frequency: 998, korean: "빼다", english: "Pull out, extract" },
  954: { frequency: 999, korean: "예정", english: "A program, schedule" },
  955: { frequency: 1000, korean: "오빠", english: "Older brother" },
  956: { frequency: 1002, korean: "즐겁다", english: "Be pleasant,agreeable" },
  957: { frequency: 1003, korean: "한계", english: "Boundary, limits" },
  958: {
    frequency: 1004,
    korean: "흔히",
    english: "Generally, commonly ; often",
  },
  959: {
    frequency: 1005,
    korean: "바탕",
    english:
      "Natural disposition ; to go all out in a fight, 아주 크게 싸우다 ; the background",
  },
  960: { frequency: 1006, korean: "사월", english: "April" },
  961: { frequency: 1007, korean: "싸우다", english: "To fight" },
  962: { frequency: 1008, korean: "언제", english: "when,at what time" },
  963: { frequency: 1009, korean: "예쁘다", english: "Pretty, lovely" },
  964: { frequency: 1010, korean: "갈등", english: "Conflict or troubles" },
  965: { frequency: 1011, korean: "느껴지다", english: "Feel" },
  966: { frequency: 1014, korean: "의지", english: "will,volition" },
  967: { frequency: 1015, korean: "전문", english: "A specialty" },
  968: { frequency: 1016, korean: "정확하다", english: "To be exact" },
  969: {
    frequency: 1017,
    korean: "초기",
    english: "The early days, the initial period",
  },
  970: {
    frequency: 1018,
    korean: "나중",
    english: "The last, the latter part",
  },
  971: { frequency: 1019, korean: "등", english: "back, spine" },
  972: { frequency: 1020, korean: "맛있다", english: "Delicious" },
  973: { frequency: 1021, korean: "며칠", english: "How many days" },
  974: {
    frequency: 1022,
    korean: "신경",
    english: "nerves (medicine); feelings",
  },
  975: {
    frequency: 1023,
    korean: "찾아오다",
    english: "To go meet somebody, to go get sth",
  },
  976: { frequency: 1025, korean: "미", english: "beauty,grace" },
  977: { frequency: 1026, korean: "사용", english: "use,emply" },
  978: { frequency: 1027, korean: "시선", english: "one’s line of vision" },
  979: { frequency: 1028, korean: "아무런", english: "No sort of" },
  980: { frequency: 1029, korean: "언론", english: "A speech, discussion" },
  981: { frequency: 1030, korean: "투자", english: "Investment" },
  982: {
    frequency: 1032,
    korean: "지원",
    english: "helping, supporting, aiding",
  },
  983: { frequency: 1033, korean: "결정하다", english: "To decide" },
  984: {
    frequency: 1034,
    korean: "경영",
    english: "Management or administration",
  },
  985: { frequency: 1036, korean: "목표", english: "goal,object" },
  986: { frequency: 1037, korean: "성장", english: "Growth" },
  987: { frequency: 1038, korean: "숲", english: "Forest" },
  988: { frequency: 1039, korean: "없어지다", english: "To lose, get lost" },
  989: { frequency: 1040, korean: "작년", english: "Last year" },
  990: { frequency: 1041, korean: "내려가다", english: "To go down" },
  991: { frequency: 1042, korean: "떠오르다", english: "1. to rise, to arise" },
  992: { frequency: 1044, korean: "미치다", english: "To reach" },
  993: { frequency: 1045, korean: "새벽", english: "dawn,daybreak" },
  994: { frequency: 1046, korean: "쓰레기", english: "Garbage" },
  995: { frequency: 1047, korean: "얼른", english: "Fast, quickly, rapidly" },
  996: { frequency: 1048, korean: "임금", english: "Wages, pay" },
  997: { frequency: 1049, korean: "피해", english: "Damage" },
  998: { frequency: 1050, korean: "한", english: "limit; end; bound" },
  999: { frequency: 1051, korean: "무섭다", english: "fearful,dreadful" },
  1000: { frequency: 1052, korean: "직장", english: "one’s work place" },
  1001: { frequency: 1053, korean: "참다", english: "Bear, endure" },
  1002: { frequency: 1054, korean: "크기", english: "Size, dimensions" },
  1003: { frequency: 1055, korean: "고기", english: "Meat" },
  1004: { frequency: 1056, korean: "남기다", english: "To leave behind" },
  1005: { frequency: 1057, korean: "서양", english: "The Western countries" },
  1006: {
    frequency: 1058,
    korean: "주요",
    english: "The major, the chief thing",
  },
  1007: {
    frequency: 1059,
    korean: "지나치다",
    english: "Go too far, to go to extremes",
  },
  1008: { frequency: 1060, korean: "가져오다", english: "To bring or get" },
  1009: { frequency: 1061, korean: "냄새", english: "Smell" },
  1010: { frequency: 1062, korean: "부드럽다", english: "soft,tender" },
  1011: { frequency: 1063, korean: "여기다", english: "Think, consider as" },
  1012: { frequency: 1064, korean: "이", english: "A person, This" },
  1013: { frequency: 1067, korean: "공연", english: "A public performance" },
  1014: { frequency: 1068, korean: "남녀", english: "Man and woman" },
  1015: { frequency: 1069, korean: "내놓다", english: "Put out, take out" },
  1016: {
    frequency: 1070,
    korean: "떼다",
    english: "1. to detach, to remove, to take off",
  },
  1017: { frequency: 1071, korean: "만들어지다", english: "Make, create" },
  1018: { frequency: 1072, korean: "속도", english: "Speed" },
  1019: { frequency: 1073, korean: "심각하다", english: "seriousness,gravity" },
  1020: { frequency: 1074, korean: "준비", english: "Preparation" },
  1021: { frequency: 1075, korean: "계속되다", english: "To continue" },
  1022: { frequency: 1076, korean: "구월", english: "September" },
  1023: { frequency: 1077, korean: "맑다", english: "clean,pure" },
  1024: { frequency: 1078, korean: "소년", english: "A boy" },
  1025: { frequency: 1079, korean: "소식", english: "News, information" },
  1026: { frequency: 1080, korean: "유월", english: "June" },
  1027: {
    frequency: 1081,
    korean: "작용",
    english: "action, operation, effect",
  },
  1028: {
    frequency: 1082,
    korean: "허리",
    english: "The waist, the small of the back",
  },
  1029: { frequency: 1083, korean: "골", english: "Goal" },
  1030: {
    frequency: 1084,
    korean: "공업",
    english: "The industry or manufacturing industry",
  },
  1031: { frequency: 1085, korean: "그중", english: "Among the rest of them" },
  1032: { frequency: 1086, korean: "노인", english: "Old person" },
  1033: {
    frequency: 1087,
    korean: "벌다",
    english: "To earn (money) ; (2) to invite, to bring onto oneself",
  },
  1034: {
    frequency: 1088,
    korean: "살리다",
    english: "1) revive, bring around, restore to life 2) save,spare,rescue",
  },
  1035: { frequency: 1089, korean: "새", english: "bird " },
  1036: { frequency: 1090, korean: "영어", english: "The english language" },
  1037: {
    frequency: 1091,
    korean: "출신",
    english: "A native, place one is from",
  },
  1038: {
    frequency: 1092,
    korean: "결정",
    english: "A decision or determination",
  },
  1039: { frequency: 1093, korean: "경향", english: "Tendency or trend" },
  1040: { frequency: 1094, korean: "기록", english: "A record or a document" },
  1041: { frequency: 1095, korean: "나름", english: "Depending on" },
  1042: { frequency: 1096, korean: "대답하다", english: "To answer, reply" },
  1043: { frequency: 1097, korean: "반면", english: "One side" },
  1044: { frequency: 1098, korean: "썰다", english: "Chip, mince,dice" },
  1045: {
    frequency: 1099,
    korean: "움직임",
    english: "movement,motion,activity",
  },
  1046: { frequency: 1100, korean: "이미지", english: "Image" },
  1047: {
    frequency: 1101,
    korean: "터지다",
    english: "Break, be torn, get away from",
  },
  1048: {
    frequency: 1102,
    korean: "특성",
    english:
      "A special/unique characteristic (individuality making, peculiarity)",
  },
  1049: { frequency: 1103, korean: "교장", english: "Principal" },
  1050: { frequency: 1104, korean: "벗다", english: "Take off, remove" },
  1051: { frequency: 1105, korean: "업무", english: "Buisness, duty" },
  1052: { frequency: 1106, korean: "입시", english: "An entrance examination" },
  1053: { frequency: 1107, korean: "준비하다", english: "To prepare" },
  1054: {
    frequency: 1108,
    korean: "청소년",
    english: "Young boys and girls, teenagers",
  },
  1055: { frequency: 1109, korean: "돕다", english: "to help" },
  1056: { frequency: 1110, korean: "응", english: "Yes, i see!" },
  1057: { frequency: 1111, korean: "이기다", english: "To win" },
  1058: { frequency: 1112, korean: "찾아보다", english: "To go meet somebody" },
  1059: {
    frequency: 1113,
    korean: "취하다",
    english: "To adopt, assume, take",
  },
  1060: { frequency: 1114, korean: "다루다", english: "Treat, deal with" },
  1061: { frequency: 1115, korean: "달", english: "The moon" },
  1062: { frequency: 1116, korean: "사장", english: "Company boss" },
  1063: { frequency: 1117, korean: "삼월", english: "March" },
  1064: { frequency: 1120, korean: "그렇지만", english: "But or however" },
  1065: { frequency: 1122, korean: "선배", english: "one’s senior" },
  1066: { frequency: 1123, korean: "업체", english: "A business enterprise" },
  1067: { frequency: 1124, korean: "키", english: "stature,height" },
  1068: {
    frequency: 1125,
    korean: "구하다",
    english: "look for or seek or want",
  },
  1069: { frequency: 1126, korean: "국회", english: "The National Assembly" },
  1070: {
    frequency: 1127,
    korean: "그러므로",
    english: "So or hence or therefore",
  },
  1071: { frequency: 1128, korean: "포함하다", english: "To include,contain" },
  1072: { frequency: 1129, korean: "걱정", english: "Worry" },
  1073: { frequency: 1130, korean: "결혼하다", english: "Get married" },
  1074: { frequency: 1134, korean: "만약", english: "If" },
  1075: {
    frequency: 1135,
    korean: "바르다",
    english: "Be straight; be honest",
  },
  1076: { frequency: 1136, korean: "세월", english: "The times, things" },
  1077: { frequency: 1137, korean: "숨", english: "A breath" },
  1078: { frequency: 1138, korean: "행사", english: "An event, a function" },
  1079: { frequency: 1139, korean: "깨닫다", english: "to realize sth" },
  1080: { frequency: 1140, korean: "누나", english: "Older sister" },
  1081: { frequency: 1141, korean: "신", english: "God" },
  1082: { frequency: 1142, korean: "왕", english: "King" },
  1083: {
    frequency: 1143,
    korean: "점점",
    english: "More and more, by degrees",
  },
  1084: { frequency: 1144, korean: "질문", english: "A question, a query" },
  1085: {
    frequency: 1145,
    korean: "특별",
    english: "Be special, extraordinary",
  },
  1086: {
    frequency: 1146,
    korean: "판단",
    english: "A judgment, a conclusion",
  },
  1087: {
    frequency: 1147,
    korean: "해결하다",
    english: "To fix a problem, solve a question",
  },
  1088: { frequency: 1148, korean: "거리", english: "Distance" },
  1089: { frequency: 1149, korean: "계속하다", english: "To continue" },
  1090: { frequency: 1150, korean: "그치다", english: "Stop or cease or halt" },
  1091: { frequency: 1151, korean: "근처", english: "Nearby or the vicinity" },
  1092: { frequency: 1152, korean: "너무나", english: "Extremely" },
  1093: { frequency: 1153, korean: "높이다", english: "Raise, elevate" },
  1094: { frequency: 1154, korean: "부정", english: "injustice,wrongfulness" },
  1095: { frequency: 1155, korean: "사정", english: "Circumstances" },
  1096: {
    frequency: 1158,
    korean: "도대체",
    english: "In the world, on earth",
  },
  1097: {
    frequency: 1159,
    korean: "막",
    english: "rough, careless, of low quality",
  },
  1098: { frequency: 1160, korean: "부모님", english: "Parents" },
  1099: { frequency: 1161, korean: "수출", english: "Exporting sth" },
  1100: {
    frequency: 1163,
    korean: "계시다",
    english: "(someone esteemed) to stay or be located",
  },
  1101: { frequency: 1164, korean: "그", english: "He, That" },
  1102: { frequency: 1166, korean: "자르다", english: "cut,chop" },
  1103: { frequency: 1167, korean: "데리다", english: "Be accompanied by" },
  1104: { frequency: 1168, korean: "마리", english: "Number of animals" },
  1105: { frequency: 1169, korean: "무척", english: "Extremely" },
  1106: { frequency: 1170, korean: "비용", english: "Expense" },
  1107: { frequency: 1171, korean: "비행기", english: "An airplane" },
  1108: { frequency: 1172, korean: "옳다", english: "To be right, correct" },
  1109: { frequency: 1173, korean: "원래", english: "originally,primarily" },
  1110: { frequency: 1174, korean: "처리", english: "handling,treatment" },
  1111: {
    frequency: 1175,
    korean: "최초",
    english: "In the very first, in the beginning",
  },
  1112: {
    frequency: 1176,
    korean: "꼴",
    english: "Shape or form or appearance",
  },
  1113: { frequency: 1177, korean: "놀이", english: "Play, game" },
  1114: {
    frequency: 1178,
    korean: "뜨겁다",
    english: "to be hot: to be passionate",
  },
  1115: { frequency: 1179, korean: "뿌리", english: "A root" },
  1116: { frequency: 1180, korean: "수입", english: "Importing sth" },
  1117: {
    frequency: 1181,
    korean: "초",
    english: "Initial, in the beginning, first",
  },
  1118: {
    frequency: 1183,
    korean: "그리하여",
    english: "And then or and so or and now",
  },
  1119: { frequency: 1184, korean: "낮", english: "Daytime" },
  1120: { frequency: 1186, korean: "일찍", english: "Early" },
  1121: { frequency: 1187, korean: "직원", english: "Staff" },
  1122: { frequency: 1188, korean: "찍다", english: "Stamp with a dojang" },
  1123: { frequency: 1189, korean: "가볍다", english: "Light" },
  1124: { frequency: 1190, korean: "내부", english: "The inside" },
  1125: {
    frequency: 1191,
    korean: "다소",
    english: "1) Large and small, 2) more or less",
  },
  1126: {
    frequency: 1192,
    korean: "상대",
    english: "counterpart,companion,rival",
  },
  1127: { frequency: 1193, korean: "오전", english: "Morning, a.m." },
  1128: { frequency: 1194, korean: "피부", english: "Skin" },
  1129: { frequency: 1195, korean: "가게", english: "Store" },
  1130: { frequency: 1196, korean: "가득", english: "Full" },
  1131: {
    frequency: 1197,
    korean: "그저",
    english: "1) still or all the time 2) easily or with no effort",
  },
  1132: { frequency: 1198, korean: "도", english: "Degrees (temperature)" },
  1133: { frequency: 1199, korean: "벽", english: "A wall" },
  1134: { frequency: 1200, korean: "장군", english: "A general" },
  1135: { frequency: 1201, korean: "무역", english: "Trade" },
  1136: { frequency: 1202, korean: "부담", english: "A burden,responsibility" },
  1137: { frequency: 1203, korean: "약속", english: "A promise" },
  1138: {
    frequency: 1204,
    korean: "인사",
    english: "Greetings; personnel matters, human resources",
  },
  1139: {
    frequency: 1205,
    korean: "줄",
    english: "string, rope; line, row, column, queue",
  },
  1140: { frequency: 1206, korean: "쳐다보다", english: "To look up at" },
  1141: { frequency: 1207, korean: "충분히", english: "Enough, sufficiently" },
  1142: { frequency: 1208, korean: "대", english: "platform" },
  1143: { frequency: 1209, korean: "신체", english: "The body" },
  1144: { frequency: 1210, korean: "에너지", english: "Energy" },
  1145: { frequency: 1211, korean: "위원", english: "A member of a committee" },
  1146: { frequency: 1212, korean: "정리하다", english: "To adjust" },
  1147: { frequency: 1213, korean: "집안", english: "Inside the house" },
  1148: { frequency: 1214, korean: "배경", english: "Background" },
  1149: { frequency: 1215, korean: "죽이다", english: "To kill" },
  1150: { frequency: 1217, korean: "단순하다", english: "Be simple-minded" },
  1151: {
    frequency: 1218,
    korean: "반대",
    english: "Opposite and being opposed to (objecting to)",
  },
  1152: { frequency: 1219, korean: "법칙", english: "A law, a rule" },
  1153: { frequency: 1220, korean: "빠지다", english: "To fall into" },
  1154: { frequency: 1221, korean: "소금", english: "Salt" },
  1155: {
    frequency: 1222,
    korean: "오염",
    english: "Contamination, pollution",
  },
  1156: { frequency: 1224, korean: "자전거", english: "Bicycle" },
  1157: {
    frequency: 1225,
    korean: "참여하다",
    english: "To participate in, take part in",
  },
  1158: { frequency: 1226, korean: "탓", english: "fault,blame" },
  1159: {
    frequency: 1227,
    korean: "푸르다",
    english: "To be blue, to be green",
  },
  1160: { frequency: 1228, korean: "걔", english: "Child" },
  1161: {
    frequency: 1229,
    korean: "그래",
    english: "So or yes or that’s right",
  },
  1162: { frequency: 1230, korean: "목", english: "The neck" },
  1163: { frequency: 1231, korean: "발표", english: "Announcement" },
  1164: { frequency: 1232, korean: "범죄", english: "A crime" },
  1165: { frequency: 1233, korean: "위", english: "status, position" },
  1166: { frequency: 1234, korean: "흔들다", english: "Shake, sway" },
  1167: { frequency: 1235, korean: "기초", english: "The foundation" },
  1168: { frequency: 1236, korean: "논리", english: "Logic" },
  1169: { frequency: 1237, korean: "드라마", english: "drama" },
  1170: { frequency: 1238, korean: "뽑다", english: "Pull out, draw" },
  1171: {
    frequency: 1239,
    korean: "피우다",
    english: "To make, build, kindle",
  },
  1172: {
    frequency: 1240,
    korean: "감각",
    english: "Sense or sensation or feeling",
  },
  1173: { frequency: 1242, korean: "미리", english: "Beforehand" },
  1174: { frequency: 1243, korean: "부족하다", english: "Be short of, lack" },
  1175: { frequency: 1244, korean: "인사", english: "Greetings" },
  1176: { frequency: 1245, korean: "저희", english: "We" },
  1177: {
    frequency: 1246,
    korean: "진행되다",
    english: "To have progressed, be advanced",
  },
  1178: {
    frequency: 1249,
    korean: "교통",
    english: "Traffic or transportation",
  },
  1179: {
    frequency: 1250,
    korean: "기구",
    english: "institutional organization,Structure or framework",
  },
  1180: { frequency: 1252, korean: "법", english: "A law, the law" },
  1181: { frequency: 1254, korean: "오랜", english: "For a long time" },
  1182: { frequency: 1255, korean: "젊은이", english: "Young person" },
  1183: { frequency: 1256, korean: "후보", english: "candidature,candidacy" },
  1184: { frequency: 1258, korean: "거리", english: "street" },
  1185: {
    frequency: 1259,
    korean: "과제",
    english: "A subject or a thesis or homework; an exercise/a problem",
  },
  1186: { frequency: 1260, korean: "근거", english: "Basis or foundation" },
  1187: {
    frequency: 1261,
    korean: "기록하다",
    english: "To record and write down",
  },
  1188: { frequency: 1262, korean: "다가오다", english: "Approach" },
  1189: { frequency: 1263, korean: "불다", english: "Blow" },
  1190: {
    frequency: 1264,
    korean: "시각",
    english: "angle/point of view. 관점.",
  },
  1191: { frequency: 1265, korean: "이끌다", english: "To guide,lead" },
  1192: { frequency: 1266, korean: "종합", english: "Synthesis" },
  1193: { frequency: 1267, korean: "조선글", english: "Korean writing system" },
  1194: { frequency: 1268, korean: "가을", english: "Autumn or fall" },
  1195: {
    frequency: 1269,
    korean: "개발하다",
    english: "Develop or exploit or open up to development",
  },
  1196: { frequency: 1270, korean: "내일", english: "Tomorrow" },
  1197: { frequency: 1271, korean: "떨다", english: "1. to tremble" },
  1198: { frequency: 1272, korean: "매일", english: "Every day" },
  1199: { frequency: 1273, korean: "손가락", english: "a finger" },
  1200: { frequency: 1274, korean: "수단", english: "means,way" },
  1201: { frequency: 1277, korean: "자", english: "A person" },
  1202: { frequency: 1278, korean: "자유롭다", english: "To be free" },
  1203: { frequency: 1279, korean: "적극적", english: "Positively" },
  1204: { frequency: 1280, korean: "판매", english: "Sale, selling" },
  1205: { frequency: 1281, korean: "형성", english: "Formation" },
  1206: {
    frequency: 1282,
    korean: "기울이다",
    english: "Tip or slant or incline",
  },
  1207: { frequency: 1283, korean: "길이", english: "Length or extent" },
  1208: { frequency: 1284, korean: "장면", english: "A scene, situation" },
  1209: { frequency: 1285, korean: "점차", english: "gradually,in steps" },
  1210: { frequency: 1286, korean: "톤", english: "A ton" },
  1211: {
    frequency: 1288,
    korean: "관련되다",
    english: "Be connected or related to",
  },
  1212: { frequency: 1289, korean: "급", english: "Class or grade or rank" },
  1213: {
    frequency: 1290,
    korean: "나머지",
    english:
      "The rest, the remainder ; as a result of the excess of, as a result",
  },
  1214: { frequency: 1291, korean: "날씨", english: "Weather" },
  1215: { frequency: 1293, korean: "더불다", english: "Join in, do together" },
  1216: { frequency: 1294, korean: "동물", english: "1. animal" },
  1217: { frequency: 1295, korean: "의사", english: "A doctor, a physician" },
  1218: { frequency: 1296, korean: "개방", english: "Open or throw open" },
  1219: { frequency: 1297, korean: "건강하다", english: "To be healthy" },
  1220: { frequency: 1298, korean: "미래", english: "Future" },
  1221: {
    frequency: 1299,
    korean: "앞서",
    english: "Before, previously 2) in anticipation,beforehand",
  },
  1222: {
    frequency: 1300,
    korean: "여러분",
    english: "You (plural, to address a group at speech time)",
  },
  1223: { frequency: 1301, korean: "왜냐하면", english: "Because" },
  1224: { frequency: 1302, korean: "인구", english: "Population" },
  1225: {
    frequency: 1303,
    korean: "기대하다",
    english: "To expect or look forward to",
  },
  1226: { frequency: 1304, korean: "네", english: "Yes" },
  1227: { frequency: 1305, korean: "도착하다", english: "To arrive" },
  1228: { frequency: 1306, korean: "병", english: "Sickness" },
  1229: { frequency: 1307, korean: "소프트웨어", english: "Software" },
  1230: {
    frequency: 1308,
    korean: "흘리다",
    english: "spill,shed (tears,blood)",
  },
  1231: { frequency: 1309, korean: "반응", english: "Reaction" },
  1232: {
    frequency: 1310,
    korean: "주인공",
    english: "The head of a family, the hero/heroine",
  },
  1233: {
    frequency: 1311,
    korean: "당연하다",
    english: "Be natural, be no wonder",
  },
  1234: { frequency: 1312, korean: "따뜻하다", english: "1. (to be) warm" },
  1235: {
    frequency: 1313,
    korean: "따로",
    english: "1. differently; separately",
  },
  1236: { frequency: 1314, korean: "비판", english: "criticism,a comment" },
  1237: { frequency: 1315, korean: "빌리다", english: "To lend" },
  1238: { frequency: 1316, korean: "세대", english: "Generation" },
  1239: { frequency: 1317, korean: "축구", english: "Football" },
  1240: {
    frequency: 1318,
    korean: "형님",
    english: "The respectful, proper way a man refers to an older friend",
  },
  1241: {
    frequency: 1319,
    korean: "놓이다",
    english: "Be put on sth else (2) to become peaceful",
  },
  1242: {
    frequency: 1320,
    korean: "당장",
    english: "On the spot, immediately",
  },
  1243: { frequency: 1321, korean: "무렵", english: "The time when" },
  1244: { frequency: 1322, korean: "밝다", english: "To be promising" },
  1245: { frequency: 1323, korean: "사물", english: "objects,things" },
  1246: { frequency: 1324, korean: "일반적", english: "Universal, general" },
  1247: { frequency: 1325, korean: "장소", english: "Place" },
  1248: { frequency: 1326, korean: "곱다", english: "Beautiful" },
  1249: { frequency: 1327, korean: "바닥", english: "Floor" },
  1250: { frequency: 1329, korean: "새끼", english: "Baby animal" },
  1251: {
    frequency: 1330,
    korean: "생각되다",
    english: "To come to think sth",
  },
  1252: { frequency: 1331, korean: "서비스", english: "Service" },
  1253: { frequency: 1332, korean: "선택하다", english: "To choose" },
  1254: { frequency: 1333, korean: "심다", english: "plant,sow (a tree)" },
  1255: { frequency: 1334, korean: "적다", english: "write, write down" },
  1256: { frequency: 1335, korean: "코", english: "The nose" },
  1257: { frequency: 1336, korean: "간단하다", english: "To be simple" },
  1258: {
    frequency: 1337,
    korean: "고등학교",
    english: "A senior high school",
  },
  1259: {
    frequency: 1338,
    korean: "공개",
    english: "opening to the public, making public",
  },
  1260: { frequency: 1339, korean: "교실", english: "Classroom" },
  1261: {
    frequency: 1340,
    korean: "스스로",
    english: "On its own, of its own free will",
  },
  1262: { frequency: 1341, korean: "견디다", english: "Endure or bear" },
  1263: {
    frequency: 1342,
    korean: "기사",
    english: "An article or an account",
  },
  1264: { frequency: 1344, korean: "막히다", english: "Be stopped up" },
  1265: { frequency: 1345, korean: "매체", english: "A medium,medias" },
  1266: { frequency: 1346, korean: "별", english: "A star" },
  1267: { frequency: 1347, korean: "복잡하다", english: "Be complicated" },
  1268: { frequency: 1348, korean: "뿌리다", english: "sprinkle,scatter" },
  1269: { frequency: 1349, korean: "영역", english: "Territory" },
  1270: { frequency: 1350, korean: "체험", english: "An experience" },
  1271: {
    frequency: 1351,
    korean: "구속",
    english: "To arrest or put in detention",
  },
  1272: {
    frequency: 1353,
    korean: "때로",
    english: "1. on occasion; occasionally",
  },
  1273: {
    frequency: 1354,
    korean: "어쩌면",
    english: "(1) it is possible that 아마도 2) how on earth, how the hell",
  },
  1274: {
    frequency: 1355,
    korean: "극복하다",
    english: "To overcome or surmount",
  },
  1275: { frequency: 1356, korean: "불법", english: "Illegal" },
  1276: { frequency: 1357, korean: "비밀", english: "A secret" },
  1277: { frequency: 1358, korean: "색", english: "Color" },
  1278: {
    frequency: 1359,
    korean: "쓰이다",
    english: "To be used, to be utilized/employed",
  },
  1279: {
    frequency: 1360,
    korean: "일정하다",
    english:
      "To be regular, established, uniform ; changeless, constant, certain, definite ; invariable (mathematics)",
  },
  1280: {
    frequency: 1361,
    korean: "다지다",
    english: "Make sure, to harden oneself, firm up one\\`s will",
  },
  1281: {
    frequency: 1362,
    korean: "밝혀지다",
    english: "1. To be discovered, revealed.",
  },
  1282: { frequency: 1363, korean: "아까", english: "A little while ago" },
  1283: {
    frequency: 1364,
    korean: "알맞다",
    english: "To fit, be fitting, suitable",
  },
  1284: {
    frequency: 1365,
    korean: "이념",
    english: "An idea, a doctrine, ideology",
  },
  1285: { frequency: 1366, korean: "희다", english: "White, fair" },
  1286: {
    frequency: 1367,
    korean: "가리키다",
    english: "Point to or indicate",
  },
  1287: { frequency: 1368, korean: "모시다", english: "Attend on" },
  1288: { frequency: 1369, korean: "발달", english: "Development, growth" },
  1289: { frequency: 1370, korean: "수많다", english: "Many and many" },
  1290: { frequency: 1371, korean: "잘못", english: "wrong, mistake" },
  1291: {
    frequency: 1372,
    korean: "치르다",
    english: "Pay off, pay one’s bill",
  },
  1292: { frequency: 1373, korean: "평화", english: "Peace" },
  1293: {
    frequency: 1374,
    korean: "공사",
    english: "Construction work or engineering work",
  },
  1294: { frequency: 1375, korean: "돌", english: "stone, pebble" },
  1295: {
    frequency: 1376,
    korean: "똑같다",
    english: "1. just the same, exactly equal, identical",
  },
  1296: { frequency: 1377, korean: "박사", english: "Doctor" },
  1297: {
    frequency: 1378,
    korean: "성",
    english: "a sex (male or female), a sexual gender",
  },
  1298: { frequency: 1379, korean: "전문가", english: "A specialist" },
  1299: { frequency: 1380, korean: "단지", english: "Simple, merely" },
  1300: { frequency: 1381, korean: "말씀하다", english: "Speak" },
  1301: { frequency: 1382, korean: "무용", english: "dancing,a dance" },
  1302: { frequency: 1383, korean: "불리다", english: "To be called" },
  1303: { frequency: 1384, korean: "싸움", english: "A fight" },
  1304: { frequency: 1385, korean: "자꾸", english: "Constantly, repeatedly" },
  1305: { frequency: 1386, korean: "차리다", english: "Make ready,prepare" },
  1306: {
    frequency: 1387,
    korean: "해외",
    english: "Abroad, foreign countries",
  },
  1307: { frequency: 1388, korean: "그리", english: "So or to that extent" },
  1308: {
    frequency: 1389,
    korean: "뜨다",
    english: "to float, to stay afloat (on water)",
  },
  1309: { frequency: 1390, korean: "문화재", english: "Cultural assets" },
  1310: { frequency: 1391, korean: "미소", english: "Smile" },
  1311: { frequency: 1392, korean: "보통", english: "The normal" },
  1312: { frequency: 1393, korean: "식당", english: "Restaurant" },
  1313: { frequency: 1394, korean: "의미하다", english: "To mean, signify" },
  1314: {
    frequency: 1395,
    korean: "이래",
    english: "Since, after that, hereafter",
  },
  1315: { frequency: 1396, korean: "체육", english: "Physical education" },
  1316: { frequency: 1397, korean: "구성되다", english: "To be organized" },
  1317: {
    frequency: 1398,
    korean: "독특하다",
    english: "Unique, characteristic, distinctive, peculiar, specific",
  },
  1318: { frequency: 1399, korean: "땀", english: "1. sweat" },
  1319: { frequency: 1400, korean: "사례", english: "thanks,gratitude" },
  1320: {
    frequency: 1401,
    korean: "소개하다",
    english: "To introduce,present",
  },
  1321: { frequency: 1402, korean: "잘되다", english: "To turn out well" },
  1322: {
    frequency: 1403,
    korean: "추진하다",
    english: "To propel, drive forward",
  },
  1323: { frequency: 1404, korean: "칠월", english: "Month of July" },
  1324: { frequency: 1405, korean: "틀", english: "A frame, framework; mold" },
  1325: { frequency: 1406, korean: "평균", english: "Average" },
  1326: { frequency: 1408, korean: "훈련", english: "Training" },
  1327: {
    frequency: 1409,
    korean: "흐름",
    english: "Flowing, a stream/current",
  },
  1328: { frequency: 1410, korean: "십이월", english: "December" },
  1329: { frequency: 1411, korean: "쌓이다", english: "Be piled/heaped up" },
  1330: { frequency: 1412, korean: "이익", english: "Profit" },
  1331: { frequency: 1413, korean: "쥐다", english: "To hold,seize" },
  1332: { frequency: 1414, korean: "컵", english: "Cup" },
  1333: { frequency: 1415, korean: "게다가", english: "In addition" },
  1334: {
    frequency: 1416,
    korean: "끓이다",
    english: "Boil or heat or make hot",
  },
  1335: {
    frequency: 1417,
    korean: "논문",
    english:
      "Dissertations , an academic paper (these are 2 separate meanings)",
  },
  1336: { frequency: 1418, korean: "멈추다", english: "Stop" },
  1337: {
    frequency: 1419,
    korean: "사용되다",
    english: "Be used, be employed",
  },
  1338: { frequency: 1420, korean: "오랫동안", english: "For a long time" },
  1339: {
    frequency: 1421,
    korean: "위기",
    english: "A crisis,an emergency situation",
  },
  1340: { frequency: 1422, korean: "정당", english: "A political party" },
  1341: { frequency: 1423, korean: "종이", english: "Paper" },
  1342: {
    frequency: 1424,
    korean: "찾아가다",
    english: "To go to a place to meet somebody",
  },
  1343: { frequency: 1425, korean: "폭력", english: "voilent,brute force" },
  1344: {
    frequency: 1426,
    korean: "혹시",
    english: "by any chance, accidentally, maybe",
  },
  1345: { frequency: 1427, korean: "늘다", english: "Increase, gain" },
  1346: { frequency: 1428, korean: "양", english: "quantity,volume" },
  1347: {
    frequency: 1430,
    korean: "절차",
    english: "process,formalities; 수속",
  },
  1348: { frequency: 1431, korean: "진짜", english: "Real" },
  1349: {
    frequency: 1432,
    korean: "계시다",
    english: "(someone esteemed) to stay or be located",
  },
  1350: { frequency: 1433, korean: "공기", english: "Air" },
  1351: { frequency: 1434, korean: "닿다", english: "To reach, get to" },
  1352: { frequency: 1435, korean: "물론", english: "Of course" },
  1353: {
    frequency: 1436,
    korean: "속하다",
    english: "Belong to, be affiliated with",
  },
  1354: { frequency: 1437, korean: "올림픽", english: "Olympic" },
  1355: { frequency: 1438, korean: "이외", english: "Except, apart from" },
  1356: { frequency: 1439, korean: "재미", english: "amusement,enjoyment" },
  1357: { frequency: 1440, korean: "제공하다", english: "offer,proffer" },
  1358: { frequency: 1441, korean: "증가하다", english: "increase,rise,grow" },
  1359: { frequency: 1442, korean: "기대", english: "Expectation" },
  1360: { frequency: 1443, korean: "떡", english: "rice cake; tteok" },
  1361: { frequency: 1444, korean: "식물", english: "plant" },
  1362: { frequency: 1445, korean: "옛", english: "Old, ancient" },
  1363: { frequency: 1446, korean: "외치다", english: "To shout, scream" },
  1364: { frequency: 1447, korean: "적어도", english: "At the least" },
  1365: { frequency: 1449, korean: "편하다", english: "Untroubled from cares" },
  1366: {
    frequency: 1450,
    korean: "평",
    english: "a Pyeong, a square area about 2 square meters",
  },
  1367: { frequency: 1451, korean: "권리", english: "A right or a claim to" },
  1368: { frequency: 1452, korean: "끝내다", english: "To end" },
  1369: { frequency: 1453, korean: "대답", english: "An answer" },
  1370: { frequency: 1454, korean: "시작", english: "The beginning" },
  1371: { frequency: 1455, korean: "어려움", english: "difficulty, hardship" },
  1372: { frequency: 1456, korean: "일주일", english: "One whole day" },
  1373: { frequency: 1457, korean: "자원", english: "Resources" },
  1374: { frequency: 1458, korean: "춤", english: "Dance" },
  1375: {
    frequency: 1459,
    korean: "넘기다",
    english: "Pass over, send across",
  },
  1376: {
    frequency: 1460,
    korean: "드리다",
    english: "humble form of 주다 (juda); to offer, to give, to present",
  },
  1377: { frequency: 1461, korean: "물체", english: "A material body" },
  1378: { frequency: 1462, korean: "분명히", english: "Obviously" },
  1379: { frequency: 1463, korean: "시위", english: "A demonstration" },
  1380: { frequency: 1464, korean: "아무것", english: "Anything" },
  1381: { frequency: 1465, korean: "온", english: "All, whole, entire" },
  1382: { frequency: 1466, korean: "젖다", english: "To be wet, soaked" },
  1383: { frequency: 1467, korean: "제외하다", english: "To exclude" },
  1384: {
    frequency: 1468,
    korean: "최대",
    english: "The greatest, the largest",
  },
  1385: {
    frequency: 1469,
    korean: "평소",
    english: "Ordinary times, ordinarily",
  },
  1386: {
    frequency: 1470,
    korean: "견해",
    english: "An opinion or view. Same as 의견",
  },
  1387: { frequency: 1471, korean: "깨끗하다", english: "To be clean or pure" },
  1388: { frequency: 1472, korean: "농사", english: "Farming" },
  1389: { frequency: 1473, korean: "더구나", english: "also; furthermore" },
  1390: { frequency: 1474, korean: "안정", english: "Stability" },
  1391: { frequency: 1475, korean: "어둠", english: "Darkness" },
  1392: {
    frequency: 1476,
    korean: "어둡다",
    english: "To be dark, dim, gloomy",
  },
  1393: { frequency: 1477, korean: "어쨌든", english: "In any case, anyhow" },
  1394: { frequency: 1478, korean: "주택", english: "A house" },
  1395: {
    frequency: 1480,
    korean: "고장",
    english: "A district or producing area",
  },
  1396: {
    frequency: 1481,
    korean: "관련하다",
    english: "To be related to or connected to",
  },
  1397: { frequency: 1482, korean: "눈길", english: "Line of vision" },
  1398: { frequency: 1483, korean: "물어보다", english: "Ask" },
  1399: { frequency: 1484, korean: "미안하다", english: "To be sorry" },
  1400: { frequency: 1485, korean: "밀다", english: "push, shove" },
  1401: { frequency: 1486, korean: "스트레스", english: "Stress" },
  1402: {
    frequency: 1487,
    korean: "음",
    english: "Sound you make to express doubt, worry",
  },
  1403: {
    frequency: 1488,
    korean: "인사",
    english: "figure, distinguished person",
  },
  1404: {
    frequency: 1489,
    korean: "주어지다",
    english: "To offer, proffer (conditions)",
  },
  1405: { frequency: 1490, korean: "고려하다", english: "Regard or respect" },
  1406: { frequency: 1491, korean: "과일", english: "A fruit" },
  1407: { frequency: 1492, korean: "널리", english: "Widely" },
  1408: { frequency: 1493, korean: "농촌", english: "A farm village" },
  1409: { frequency: 1494, korean: "올라오다", english: "Come up" },
  1410: {
    frequency: 1495,
    korean: "챙기다",
    english:
      "gather, collect, assemble your stuff — to amass (money, a collection)",
  },
  1411: { frequency: 1496, korean: "고르다", english: "Choose or select" },
  1412: {
    frequency: 1497,
    korean: "바르다",
    english: "to hang paper; to stick paper, cloth or the like",
  },
  1413: {
    frequency: 1498,
    korean: "벌어지다",
    english: "to occur or take place.",
  },
  1414: {
    frequency: 1499,
    korean: "소재",
    english: "material, subject matter",
  },
  1415: { frequency: 1500, korean: "전망", english: "A view, prospect" },
  1416: {
    frequency: 1501,
    korean: "포기하다",
    english: "To give up, to abandon",
  },
  1417: {
    frequency: 1502,
    korean: "형성되다",
    english: "To be formed, made up",
  },
  1418: { frequency: 1503, korean: "고치다", english: "To fix, amend, change" },
  1419: {
    frequency: 1504,
    korean: "그림자",
    english: "A shadow or a silhouette or an image",
  },
  1420: { frequency: 1505, korean: "눈", english: "snow" },
  1421: {
    frequency: 1506,
    korean: "다하다",
    english: "Finish, go through, be exhausted, run out of",
  },
  1422: { frequency: 1507, korean: "마침내", english: "Finally, in the end" },
  1423: { frequency: 1508, korean: "비교하다", english: "To compare" },
  1424: { frequency: 1509, korean: "시월", english: "October" },
  1425: { frequency: 1510, korean: "커지다", english: "To become bigger" },
  1426: { frequency: 1511, korean: "한쪽", english: "On one side" },
  1427: {
    frequency: 1512,
    korean: "검사",
    english: "Investigation or inspection or examination or audit",
  },
  1428: { frequency: 1513, korean: "결론", english: "A conclusion" },
  1429: {
    frequency: 1515,
    korean: "들이다",
    english: "1. To let in someone or something.",
  },
  1430: { frequency: 1516, korean: "맡기다", english: "Entrust sb with sth" },
  1431: { frequency: 1517, korean: "박물관", english: "Museum" },
  1432: { frequency: 1519, korean: "소문", english: "A rumor" },
  1433: {
    frequency: 1520,
    korean: "싣다",
    english: "To load, take (passengers)",
  },
  1434: { frequency: 1521, korean: "쌓다", english: "To pile up" },
  1435: { frequency: 1522, korean: "어서", english: "Quickly 2) kindly" },
  1436: { frequency: 1523, korean: "자녀", english: "one’s children" },
  1437: { frequency: 1524, korean: "제목", english: "A subject, theme" },
  1438: { frequency: 1525, korean: "짓", english: "Something you do" },
  1439: {
    frequency: 1526,
    korean: "판결",
    english: "A judgment (from a judge)",
  },
  1440: { frequency: 1527, korean: "팔월", english: "August" },
  1441: { frequency: 1528, korean: "하얗다", english: "White" },
  1442: { frequency: 1529, korean: "희망", english: "Hope, wish" },
  1443: { frequency: 1530, korean: "가방", english: "Bag" },
  1444: { frequency: 1531, korean: "군대", english: "Troops or an army" },
  1445: {
    frequency: 1532,
    korean: "그만큼",
    english: "That much or to that extent",
  },
  1446: { frequency: 1533, korean: "무어", english: "What? 무엇" },
  1447: { frequency: 1534, korean: "비로소", english: "For the first time" },
  1448: { frequency: 1536, korean: "상대방", english: "Conversation partner" },
  1449: { frequency: 1537, korean: "서구", english: "Western Europe" },
  1450: { frequency: 1538, korean: "소유", english: "Possession" },
  1451: { frequency: 1539, korean: "시골", english: "Countryside" },
  1452: { frequency: 1540, korean: "실수", english: "A mistake" },
  1453: { frequency: 1541, korean: "잘못되다", english: "To turn out wrong" },
  1454: {
    frequency: 1542,
    korean: "치료",
    english: "Medical treatment, medical care",
  },
  1455: { frequency: 1543, korean: "폭", english: "Width; range" },
  1456: {
    frequency: 1544,
    korean: "호",
    english: "A pen name, a title; a number, an issue",
  },
  1457: { frequency: 1545, korean: "내밀다", english: "To Push out" },
  1458: { frequency: 1546, korean: "맞다", english: "be beaten, be hit" },
  1459: { frequency: 1547, korean: "부문", english: "A section, department" },
  1460: { frequency: 1548, korean: "시리즈", english: "Series" },
  1461: { frequency: 1549, korean: "임신", english: "Pregnant" },
  1462: {
    frequency: 1550,
    korean: "잡히다",
    english: "To be caught ; to extinguish a fire",
  },
  1463: { frequency: 1551, korean: "해", english: "A year" },
  1464: {
    frequency: 1552,
    korean: "규정",
    english: "Provision or stipulation",
  },
  1465: { frequency: 1553, korean: "그램", english: "Gram" },
  1466: { frequency: 1554, korean: "밭", english: "A field, farm" },
  1467: { frequency: 1555, korean: "분석하다", english: "To analyze" },
  1468: {
    frequency: 1556,
    korean: "식구",
    english:
      "Members of a family, your immediate family members (those you live with, preferably)",
  },
  1469: {
    frequency: 1557,
    korean: "아예",
    english: "From the very first, from the very beginning",
  },
  1470: {
    frequency: 1558,
    korean: "어찌",
    english: "How, in what way, by what means",
  },
  1471: {
    frequency: 1559,
    korean: "울리다",
    english: "To ring (a bell) ; to create an echo effect",
  },
  1472: { frequency: 1560, korean: "작용하다", english: "To apply , use" },
  1473: { frequency: 1561, korean: "확실하다", english: "To be sure, certain" },
  1474: {
    frequency: 1562,
    korean: "개선",
    english: "Renovation or improvement or betterment",
  },
  1475: { frequency: 1563, korean: "그릇", english: "A vessel or receptacle" },
  1476: { frequency: 1564, korean: "글자", english: "A letter or a character" },
  1477: {
    frequency: 1565,
    korean: "바람직하다",
    english: "Be desirable, advisable, to be appropriate, good",
  },
  1478: {
    frequency: 1566,
    korean: "연구하다",
    english: "To research, investigate",
  },
  1479: { frequency: 1567, korean: "착하다", english: "To be nice" },
  1480: { frequency: 1569, korean: "개", english: "dog" },
  1481: { frequency: 1571, korean: "라디오", english: "radio" },
  1482: {
    frequency: 1572,
    korean: "마련",
    english: "planning, making arrangements ; provisions, preparations",
  },
  1483: { frequency: 1574, korean: "부동산", english: "Real estate" },
  1484: { frequency: 1575, korean: "신화", english: "A myth" },
  1485: { frequency: 1576, korean: "양", english: "Miss" },
  1486: { frequency: 1577, korean: "점", english: "a point or spot" },
  1487: { frequency: 1578, korean: "직업", english: "An occupation, calling" },
  1488: { frequency: 1579, korean: "거두다", english: "Collect or gather up" },
  1489: { frequency: 1581, korean: "방학", english: "School holidays" },
  1490: { frequency: 1582, korean: "범위", english: "Range, area" },
  1491: { frequency: 1583, korean: "조상", english: "Ancestors" },
  1492: { frequency: 1584, korean: "철학", english: "Philosophy" },
  1493: { frequency: 1585, korean: "검다", english: "Black or dark" },
  1494: { frequency: 1586, korean: "곁", english: "Neighborhood or vicinity" },
  1495: {
    frequency: 1587,
    korean: "근본적",
    english: "foundational, fundamental",
  },
  1496: { frequency: 1588, korean: "너희", english: "You guys" },
  1497: { frequency: 1589, korean: "대형", english: "Large, full-sized" },
  1498: { frequency: 1590, korean: "따다", english: "to pick, to pluck" },
  1499: { frequency: 1591, korean: "문제점", english: "The point at issue" },
  1500: { frequency: 1592, korean: "본격적", english: "Exemplary" },
  1501: { frequency: 1593, korean: "불가능하다", english: "Impossible" },
  1502: { frequency: 1594, korean: "인제", english: "Now" },
  1503: { frequency: 1595, korean: "충격", english: "An impact, a shock" },
  1504: {
    frequency: 1596,
    korean: "퍼지다",
    english: "Spread out, get broader",
  },
  1505: {
    frequency: 1597,
    korean: "금방",
    english: "Just now or a moment ago",
  },
  1506: { frequency: 1598, korean: "남쪽", english: "South side" },
  1507: { frequency: 1599, korean: "누르다", english: "Press or push down" },
  1508: { frequency: 1600, korean: "미술", english: "art, the fine arts" },
  1509: { frequency: 1601, korean: "백성", english: "The common people" },
  1510: { frequency: 1602, korean: "상당히", english: "fairly, considerably" },
  1511: { frequency: 1603, korean: "색깔", english: "Color" },
  1512: { frequency: 1604, korean: "요리", english: "Cooking" },
  1513: { frequency: 1605, korean: "유명하다", english: "To be famous" },
  1514: { frequency: 1606, korean: "자네", english: "You" },
  1515: {
    frequency: 1607,
    korean: "기13",
    english: "1) energy 2) breath or wind",
  },
  1516: { frequency: 1608, korean: "꽤", english: "Fairly or considerably" },
  1517: { frequency: 1610, korean: "서로", english: "mutually,one another" },
  1518: {
    frequency: 1611,
    korean: "외국인",
    english: "A foreigner, a foreign national",
  },
  1519: {
    frequency: 1612,
    korean: "한참",
    english: "For some time, for a while",
  },
  1520: { frequency: 1613, korean: "군사", english: "Military affairs" },
  1521: { frequency: 1614, korean: "끊다", english: "Cut off or sever" },
  1522: { frequency: 1615, korean: "넘어가다", english: "Cross or go over" },
  1523: {
    frequency: 1616,
    korean: "담기다",
    english: "Be put food in a bottle or for pickling",
  },
  1524: { frequency: 1617, korean: "마당", english: "Garden" },
  1525: { frequency: 1619, korean: "부인", english: "A woman" },
  1526: { frequency: 1620, korean: "서두르다", english: "To hurry up" },
  1527: {
    frequency: 1621,
    korean: "지적",
    english: "Pointing out, indication",
  },
  1528: { frequency: 1622, korean: "짝", english: "One pair, one couple" },
  1529: { frequency: 1623, korean: "참으로", english: "really, indeed" },
  1530: {
    frequency: 1624,
    korean: "충분하다",
    english: "To be sufficient, ample",
  },
  1531: { frequency: 1625, korean: "기쁘다", english: "To be happy" },
  1532: { frequency: 1626, korean: "뛰다", english: "to run" },
  1533: { frequency: 1627, korean: "숙제", english: "Homework" },
  1534: { frequency: 1628, korean: "앞두다", english: "Have a distance ahead" },
  1535: { frequency: 1629, korean: "예산", english: "An estimate, budget" },
  1536: { frequency: 1630, korean: "온갖", english: "All kinds, all sorts" },
  1537: { frequency: 1631, korean: "우려", english: "worry, anxiety, fear" },
  1538: { frequency: 1632, korean: "우산", english: "An umbrella" },
  1539: { frequency: 1633, korean: "기쁨", english: "Joy or delight" },
  1540: { frequency: 1634, korean: "깊이", english: "Deepness" },
  1541: { frequency: 1635, korean: "꾸미다", english: "Decorate" },
  1542: { frequency: 1636, korean: "늘리다", english: "Increase, multiply" },
  1543: { frequency: 1637, korean: "무릎", english: "A knee" },
  1544: { frequency: 1638, korean: "발견되다", english: "Be discovered" },
  1545: {
    frequency: 1639,
    korean: "보호하다",
    english: "protect, shelter, guard",
  },
  1546: { frequency: 1641, korean: "시스템", english: "System" },
  1547: { frequency: 1642, korean: "이용", english: "usage, to use" },
  1548: { frequency: 1643, korean: "지난달", english: "Last month" },
  1549: { frequency: 1644, korean: "지르다", english: "Holler, yell, scream" },
  1550: { frequency: 1645, korean: "참여", english: "Participation" },
  1551: {
    frequency: 1647,
    korean: "걸음",
    english: "Walking or stepping or a step",
  },
  1552: {
    frequency: 1648,
    korean: "겨우",
    english: "Barely or narrowly. 간신히",
  },
  1553: { frequency: 1649, korean: "마르다", english: "Become dry" },
  1554: { frequency: 1650, korean: "비교적", english: "Comparatively" },
  1555: {
    frequency: 1651,
    korean: "애쓰다",
    english: "Make efforts/pains to do sth",
  },
  1556: {
    frequency: 1652,
    korean: "올바르다",
    english: "Be honest, straightforward",
  },
  1557: { frequency: 1653, korean: "책상", english: "On the desk" },
  1558: { frequency: 1654, korean: "춥다", english: "To be cold" },
  1559: { frequency: 1655, korean: "흔하다", english: "To be everywhere" },
  1560: { frequency: 1657, korean: "높아지다", english: "Become high" },
  1561: { frequency: 1658, korean: "늙다", english: "Grow old" },
  1562: { frequency: 1659, korean: "단위", english: "A unit, a monad" },
  1563: {
    frequency: 1660,
    korean: "둘째",
    english: "second, next after the first; the ordinal number for two",
  },
  1564: {
    frequency: 1661,
    korean: "뛰어나다",
    english: "to excel; to be outstanding",
  },
  1565: {
    frequency: 1662,
    korean: "무겁다",
    english: "Heavy ; a stuffy atmosphere",
  },
  1566: { frequency: 1663, korean: "바람", english: "Wind" },
  1567: { frequency: 1664, korean: "상상", english: "Imagination" },
  1568: { frequency: 1665, korean: "소득", english: "An income, earnings" },
  1569: {
    frequency: 1666,
    korean: "수도",
    english: "the capital city, e.g. Seoul, of a country",
  },
  1570: {
    frequency: 1667,
    korean: "역",
    english: "theatric or cinematic role",
  },
  1571: {
    frequency: 1668,
    korean: "인식하다",
    english: "recognition, cognizance",
  },
  1572: { frequency: 1669, korean: "자", english: "letter, character" },
  1573: { frequency: 1670, korean: "침대", english: "A bed" },
  1574: { frequency: 1672, korean: "권", english: "A volume or a book" },
  1575: { frequency: 1673, korean: "뜨다", english: "to open (ones eyes)" },
  1576: { frequency: 1674, korean: "맺다", english: "form, tie" },
  1577: { frequency: 1675, korean: "수요", english: "demand, requirement" },
  1578: { frequency: 1676, korean: "스타", english: "Star" },
  1579: {
    frequency: 1677,
    korean: "시계",
    english: "clock, timekeeper, timepiece",
  },
  1580: { frequency: 1679, korean: "입술", english: "The lips" },
  1581: { frequency: 1680, korean: "잎", english: "A leaf" },
  1582: { frequency: 1681, korean: "중간", english: "In the middle" },
  1583: { frequency: 1682, korean: "지도자", english: "Leader" },
  1584: { frequency: 1683, korean: "천천히", english: "Slowly" },
  1585: {
    frequency: 1684,
    korean: "구성하다",
    english: "To organize or makeup",
  },
  1586: {
    frequency: 1685,
    korean: "대체로",
    english: "Generally, on the whole, for the most part",
  },
  1587: {
    frequency: 1686,
    korean: "때리다",
    english: "to hit, to strike, to slap",
  },
  1588: { frequency: 1687, korean: "몹시", english: "Extremely" },
  1589: { frequency: 1688, korean: "문득", english: "Suddenly" },
  1590: { frequency: 1689, korean: "스포츠", english: "Sports" },
  1591: {
    frequency: 1690,
    korean: "위원장",
    english: "A chairman of a committee",
  },
  1592: { frequency: 1692, korean: "저기", english: "That place, there" },
  1593: {
    frequency: 1693,
    korean: "특별하다",
    english: "To be special, extraordinary",
  },
  1594: { frequency: 1694, korean: "효과적", english: "Effective" },
  1595: { frequency: 1695, korean: "가까이", english: "Close" },
  1596: { frequency: 1696, korean: "낫다", english: "to be Superior, better" },
  1597: { frequency: 1697, korean: "넘어서다", english: "Pass over" },
  1598: { frequency: 1698, korean: "볶다", english: "fry, roast" },
  1599: { frequency: 1699, korean: "생산하다", english: "To produce, make" },
  1600: {
    frequency: 1700,
    korean: "언젠가",
    english: "One of these days, at some time in the future",
  },
  1601: { frequency: 1701, korean: "예술가", english: "An artist" },
  1602: { frequency: 1702, korean: "의도", english: "An intention, an aim" },
  1603: { frequency: 1703, korean: "저지르다", english: "do,commit, spoil" },
  1604: { frequency: 1704, korean: "줄어들다", english: "Dwindle away" },
  1605: { frequency: 1706, korean: "가만히", english: "Quietly or softly" },
  1606: {
    frequency: 1707,
    korean: "건",
    english: "A matter or an object or a case",
  },
  1607: {
    frequency: 1708,
    korean: "교회",
    english: "Preach or moral instruction",
  },
  1608: {
    frequency: 1709,
    korean: "대개",
    english: "Mostly, for the most part",
  },
  1609: {
    frequency: 1711,
    korean: "외부",
    english: "The exterior, the outside",
  },
  1610: { frequency: 1712, korean: "한두", english: "One or two" },
  1611: {
    frequency: 1713,
    korean: "한때",
    english: "For a while, temporarily",
  },
  1612: { frequency: 1714, korean: "화", english: "Anger (get angry)" },
  1613: { frequency: 1715, korean: "흙", english: "earth, the soil" },
  1614: { frequency: 1716, korean: "가난하다", english: "Poor" },
  1615: { frequency: 1717, korean: "고객", english: "Customer" },
  1616: { frequency: 1718, korean: "과학자", english: "Scientist" },
  1617: { frequency: 1719, korean: "관광", english: "Sightseeing or tourism" },
  1618: { frequency: 1720, korean: "살아오다", english: "To come out alive" },
  1619: {
    frequency: 1721,
    korean: "상대적",
    english: "relatively, correlatively",
  },
  1620: { frequency: 1722, korean: "수술", english: "Surgery" },
  1621: { frequency: 1723, korean: "식품", english: "Groceries" },
  1622: { frequency: 1724, korean: "연기", english: "performance, acting" },
  1623: { frequency: 1725, korean: "일월", english: "January" },
  1624: { frequency: 1726, korean: "조", english: "article, clause" },
  1625: { frequency: 1728, korean: "첫째", english: "The first" },
  1626: { frequency: 1730, korean: "회원", english: "A member of a society" },
  1627: { frequency: 1732, korean: "도서관", english: "Library" },
  1628: { frequency: 1733, korean: "들려오다", english: "To echo (sound)" },
  1629: {
    frequency: 1735,
    korean: "조금씩",
    english: "A little at a time, little by little",
  },
  1630: { frequency: 1736, korean: "조미료", english: "seasoning, condiment" },
  1631: { frequency: 1737, korean: "풀리다", english: "To come loose" },
  1632: {
    frequency: 1738,
    korean: "강력하다",
    english: "To be strong or powerful",
  },
  1633: { frequency: 1739, korean: "들여다보다", english: "To look into" },
  1634: { frequency: 1740, korean: "마늘", english: "Garlic" },
  1635: { frequency: 1741, korean: "선물", english: "A present,gift" },
  1636: { frequency: 1742, korean: "습관", english: "Habit" },
  1637: {
    frequency: 1743,
    korean: "아주머니",
    english: "An aunt, a respectable word for a married, older woman",
  },
  1638: { frequency: 1744, korean: "위험", english: "Danger" },
  1639: { frequency: 1745, korean: "지하", english: "Underground" },
  1640: {
    frequency: 1746,
    korean: "활용하다",
    english: "Utilize, make practical use of",
  },
  1641: {
    frequency: 1747,
    korean: "가꾸다",
    english: "1) grow or cultivatea 2) dress oneself up",
  },
  1642: { frequency: 1748, korean: "고민", english: "Worry or anguish" },
  1643: {
    frequency: 1749,
    korean: "떠올리다",
    english: "To make someone think of something; to bring to mind.",
  },
  1644: { frequency: 1750, korean: "맨", english: "bare,naked" },
  1645: { frequency: 1751, korean: "법률", english: "A law, statute" },
  1646: { frequency: 1752, korean: "상처", english: "Wound" },
  1647: { frequency: 1754, korean: "좁다", english: "Narrow" },
  1648: { frequency: 1755, korean: "지하철", english: "Subway" },
  1649: { frequency: 1756, korean: "집다", english: "Pick up" },
  1650: {
    frequency: 1757,
    korean: "현",
    english: "The present, the existence, actuality",
  },
  1651: { frequency: 1758, korean: "화면", english: "Screen" },
  1652: {
    frequency: 1759,
    korean: "군",
    english:
      "Mr.; attached to names or surnames of young men to show a certain level of respect.",
  },
  1653: { frequency: 1760, korean: "대표적", english: "Representative" },
  1654: { frequency: 1761, korean: "만일", english: "If, suppose" },
  1655: { frequency: 1762, korean: "사회적", english: "Socially" },
  1656: { frequency: 1763, korean: "생겨나다", english: "To begin, germinate" },
  1657: { frequency: 1764, korean: "이어", english: "vulgarity, slang" },
  1658: { frequency: 1765, korean: "주부", english: "A wife" },
  1659: { frequency: 1766, korean: "진리", english: "A truth, a fact" },
  1660: { frequency: 1767, korean: "태양", english: "The sun" },
  1661: { frequency: 1768, korean: "틀림없다", english: "No mistaking it" },
  1662: { frequency: 1769, korean: "프로", english: "Professional" },
  1663: { frequency: 1770, korean: "피다", english: "To bloom ( a flower ~)" },
  1664: { frequency: 1772, korean: "공급", english: "Supply or provision" },
  1665: { frequency: 1773, korean: "도로", english: "A road, way" },
  1666: {
    frequency: 1774,
    korean: "동료",
    english: "associate, colleague, coworker, comrade, brother",
  },
  1667: { frequency: 1775, korean: "잘못", english: "wrong, mistake" },
  1668: { frequency: 1776, korean: "지다", english: "Carry (on the back)" },
  1669: {
    frequency: 1777,
    korean: "채우다",
    english: "To fill up, complete, fulfill",
  },
  1670: { frequency: 1778, korean: "균형", english: "Balance or equilibrium" },
  1671: { frequency: 1779, korean: "기본적", english: "Fundamental or basic" },
  1672: { frequency: 1780, korean: "부족", english: "A tribe" },
  1673: { frequency: 1781, korean: "사무실", english: "Office" },
  1674: { frequency: 1783, korean: "이월", english: "February" },
  1675: { frequency: 1784, korean: "일요일", english: "Sunday" },
  1676: { frequency: 1785, korean: "접근", english: "Proximity" },
  1677: {
    frequency: 1786,
    korean: "지켜보다",
    english: "Watch, stare at ( wait on some situation)",
  },
  1678: {
    frequency: 1788,
    korean: "개성",
    english: "Individuality or personality",
  },
  1679: { frequency: 1790, korean: "달리다", english: "to hang, weigh" },
  1680: { frequency: 1791, korean: "더하다", english: "Get worse" },
  1681: { frequency: 1792, korean: "띄다", english: "To be visible or seen" },
  1682: { frequency: 1793, korean: "무너지다", english: "Collapse" },
  1683: { frequency: 1794, korean: "보통", english: "The normal" },
  1684: { frequency: 1795, korean: "쓰다", english: "to open (ones eyes)" },
  1685: { frequency: 1797, korean: "이러다", english: "do/say think this way" },
  1686: {
    frequency: 1798,
    korean: "일어서다",
    english: "To rise to one’s feet, stand up",
  },
  1687: { frequency: 1799, korean: "죄", english: "A crime, a sin" },
  1688: { frequency: 1800, korean: "참", english: "really,truly" },
  1689: {
    frequency: 1801,
    korean: "총장",
    english: "The president, the chancellor (university’s)",
  },
  1690: { frequency: 1802, korean: "핵심", english: "The kernel, the core" },
  1691: { frequency: 1803, korean: "후반", english: "The latter/second half" },
  1692: { frequency: 1804, korean: "단순히", english: "Simply" },
  1693: { frequency: 1805, korean: "달려가다", english: "Run, rush, dash" },
  1694: { frequency: 1806, korean: "방문", english: "A visit" },
  1695: { frequency: 1807, korean: "불만", english: "Dissatisfaction" },
  1696: { frequency: 1808, korean: "불편하다", english: "To be unpleasant" },
  1697: { frequency: 1809, korean: "실제", english: "The truth, a fact" },
  1698: { frequency: 1810, korean: "종", english: "A sort, kind, species" },
  1699: { frequency: 1811, korean: "피", english: "Blood (is flowing)" },
  1700: { frequency: 1812, korean: "강", english: "River" },
  1701: { frequency: 1814, korean: "관객", english: "Spectator" },
  1702: {
    frequency: 1815,
    korean: "동작",
    english: "movement (of the body); action",
  },
  1703: { frequency: 1816, korean: "뜻하다", english: "to mean; to signify" },
  1704: { frequency: 1817, korean: "막", english: "Carelessly, at random" },
  1705: { frequency: 1818, korean: "밀리미터", english: "Millimeter" },
  1706: { frequency: 1819, korean: "비싸다", english: "To be expensive" },
  1707: { frequency: 1820, korean: "숫자", english: "A figure, number" },
  1708: { frequency: 1821, korean: "열", english: "Heat" },
  1709: { frequency: 1822, korean: "왼쪽", english: "The left side" },
  1710: { frequency: 1823, korean: "중세", english: "The middle ages" },
  1711: { frequency: 1824, korean: "택시", english: "Taxi" },
  1712: { frequency: 1825, korean: "통합", english: "Unity, unification" },
  1713: { frequency: 1827, korean: "계산", english: "Calculation" },
  1714: { frequency: 1828, korean: "꼬리", english: "Tail" },
  1715: { frequency: 1829, korean: "놀랍다", english: "To be surprising" },
  1716: {
    frequency: 1831,
    korean: "양식",
    english: "A mode, a style, an order, a modality",
  },
  1717: { frequency: 1832, korean: "예전", english: "Old days, former days" },
  1718: { frequency: 1833, korean: "저", english: "That" },
  1719: { frequency: 1834, korean: "전기", english: "Electricity" },
  1720: { frequency: 1835, korean: "주식", english: "Stock" },
  1721: {
    frequency: 1837,
    korean: "틀리다",
    english: "To be turned, warped 2) go wrong, be mistaken",
  },
  1722: {
    frequency: 1839,
    korean: "끊임없이",
    english: "Constantly or unceasingly",
  },
  1723: { frequency: 1840, korean: "모델", english: "Model" },
  1724: { frequency: 1841, korean: "붓다", english: "to pour (water, liquid)" },
  1725: { frequency: 1842, korean: "상식", english: "Common sense" },
  1726: { frequency: 1843, korean: "상표", english: "trademark, brand" },
  1727: { frequency: 1844, korean: "시원하다", english: "Be cool, refreshing" },
  1728: { frequency: 1845, korean: "아니하다", english: "To be/do not" },
  1729: { frequency: 1846, korean: "어디", english: "Where?" },
  1730: {
    frequency: 1847,
    korean: "의식하다",
    english: "Be conscious of, be aware of",
  },
  1731: { frequency: 1848, korean: "고모", english: "A paternal aunt" },
  1732: {
    frequency: 1849,
    korean: "궁금하다",
    english: "To wonder or be curious about",
  },
  1733: { frequency: 1850, korean: "둘러싸다", english: "To surround." },
  1734: {
    frequency: 1851,
    korean: "딱",
    english: "tightly; in a perfectly fitting manner ",
  },
  1735: { frequency: 1852, korean: "뛰다", english: "to pulsate, palpitate" },
  1736: { frequency: 1854, korean: "민주화", english: "Democratization" },
  1737: { frequency: 1855, korean: "보도", english: "news, report" },
  1738: { frequency: 1856, korean: "살피다", english: "Take a good look at" },
  1739: { frequency: 1859, korean: "않다", english: "To not do" },
  1740: { frequency: 1860, korean: "약하다", english: "To be weak" },
  1741: { frequency: 1861, korean: "잘못하다", english: "To make a mistake" },
  1742: { frequency: 1862, korean: "잡지", english: "A magazine" },
  1743: {
    frequency: 1864,
    korean: "거부하다",
    english: "Deny or reject or disapprove",
  },
  1744: { frequency: 1865, korean: "공무원", english: "Public servant" },
  1745: { frequency: 1866, korean: "그만두다", english: "Stop or discontinue" },
  1746: { frequency: 1867, korean: "댁", english: "You and your family" },
  1747: { frequency: 1868, korean: "반갑다", english: "Be happy" },
  1748: {
    frequency: 1869,
    korean: "부족",
    english: "insufficiency; inadequacy",
  },
  1749: { frequency: 1870, korean: "실시", english: "execution, enforcement" },
  1750: { frequency: 1871, korean: "운명", english: "fate, fortune" },
  1751: { frequency: 1873, korean: "재정", english: "finances, economy" },
  1752: { frequency: 1874, korean: "차라리", english: "rather, preferably" },
  1753: { frequency: 1875, korean: "학자", english: "Scholar" },
  1754: {
    frequency: 1876,
    korean: "다녀오다",
    english: "Drop in for a short visit",
  },
  1755: { frequency: 1877, korean: "달다", english: "to be sweet, delicious" },
  1756: { frequency: 1878, korean: "대규모", english: "2. to be delicious" },
  1757: { frequency: 1879, korean: "동", english: "the same" },
  1758: { frequency: 1880, korean: "민간", english: "civil, private" },
  1759: { frequency: 1881, korean: "법원", english: "Court of justice" },
  1760: { frequency: 1882, korean: "비디오", english: "Video" },
  1761: { frequency: 1883, korean: "사실상", english: "Actually, in truth" },
  1762: { frequency: 1884, korean: "아끼다", english: "grudge, spare" },
  1763: { frequency: 1885, korean: "이쪽", english: "This side, our way" },
  1764: { frequency: 1888, korean: "지대", english: "Zone, belt, district" },
  1765: {
    frequency: 1890,
    korean: "판단하다",
    english: "To make a conclusion",
  },
  1766: { frequency: 1891, korean: "행복하다", english: "To be happy" },
  1767: { frequency: 1892, korean: "굽다", english: "To roast or bake" },
  1768: { frequency: 1893, korean: "기름", english: "Oil or fat" },
  1769: {
    frequency: 1894,
    korean: "실천하다",
    english: "To practice, put in theory",
  },
  1770: { frequency: 1895, korean: "쏟아지다", english: "To pour out" },
  1771: { frequency: 1896, korean: "연습", english: "practice, exercise" },
  1772: { frequency: 1897, korean: "오른쪽", english: "The right side" },
  1773: { frequency: 1898, korean: "용어", english: "Technical terminology" },
  1774: {
    frequency: 1899,
    korean: "익히다",
    english: "to become proficient in something, Habituate oneself to",
  },
  1775: { frequency: 1901, korean: "지도", english: "Map, atlas" },
  1776: { frequency: 1902, korean: "지위", english: "Position, status" },
  1777: {
    frequency: 1903,
    korean: "풍부하다",
    english: "To be abundant, copious, plentiful",
  },
  1778: { frequency: 1904, korean: "화장실", english: "Bathroom" },
  1779: {
    frequency: 1905,
    korean: "기억하다",
    english: "To recall or remember",
  },
  1780: { frequency: 1907, korean: "식량", english: "food, provisions" },
  1781: { frequency: 1908, korean: "실험", english: "An experiment, test" },
  1782: { frequency: 1909, korean: "용기", english: "Bravery" },
  1783: { frequency: 1912, korean: "토론", english: "A debate, discussion" },
  1784: { frequency: 1914, korean: "핵", english: "A nucleus, core" },
  1785: {
    frequency: 1915,
    korean: "고급",
    english: "High rank or first class",
  },
  1786: {
    frequency: 1916,
    korean: "고생",
    english: "A hard life or privation",
  },
  1787: {
    frequency: 1918,
    korean: "미치다",
    english: "(informal, derogatory) to go crazy, to become insane",
  },
  1788: { frequency: 1919, korean: "밟다", english: "Step on" },
  1789: { frequency: 1920, korean: "상당하다", english: "Be proper, suitable" },
  1790: { frequency: 1921, korean: "섞다", english: "blend, mix" },
  1791: { frequency: 1922, korean: "수석", english: "The top seat" },
  1792: {
    frequency: 1925,
    korean: "없애다",
    english: "Remove, get rid of, do away with",
  },
  1793: {
    frequency: 1928,
    korean: "이뤄지다",
    english: "To make something for a specific purpose",
  },
  1794: { frequency: 1930, korean: "적절하다", english: "Appropriateness" },
  1795: { frequency: 1931, korean: "정상", english: "The top, summit" },
  1796: { frequency: 1932, korean: "제사", english: "A holiday ceremony" },
  1797: { frequency: 1933, korean: "주말", english: "Weekend" },
  1798: { frequency: 1934, korean: "지혜", english: "Wisdom, wits" },
  1799: { frequency: 1935, korean: "참새", english: "A sparrow" },
  1800: { frequency: 1936, korean: "화장품", english: "Makup, cosmetics" },
  1801: { frequency: 1937, korean: "굵다", english: "Be thick or fat" },
  1802: { frequency: 1939, korean: "깨끗이", english: "Clean or neatly" },
  1803: { frequency: 1940, korean: "낡다", english: "Clear" },
  1804: { frequency: 1941, korean: "내년", english: "Next year" },
  1805: { frequency: 1942, korean: "농산물", english: "Agricultural products" },
  1806: { frequency: 1943, korean: "눈앞", english: "Before one’s eyes" },
  1807: { frequency: 1944, korean: "대학생", english: "University student" },
  1808: { frequency: 1945, korean: "방문하다", english: "To visit" },
  1809: { frequency: 1946, korean: "붉다", english: "Be red, scalet" },
  1810: { frequency: 1947, korean: "사고", english: "thought; reflection" },
  1811: { frequency: 1948, korean: "순서", english: "system, order, sequence" },
  1812: {
    frequency: 1949,
    korean: "아무래도",
    english: "Never on any account, no matter what",
  },
  1813: { frequency: 1950, korean: "연구소", english: "A research institute" },
  1814: { frequency: 1951, korean: "올", english: "This year" },
  1815: {
    frequency: 1952,
    korean: "위대하다",
    english: "To be great, mighty, grand",
  },
  1816: { frequency: 1953, korean: "이사", english: "House-moving" },
  1817: { frequency: 1954, korean: "지배하다", english: "Control, direct" },
  1818: { frequency: 1955, korean: "틈", english: "A crack, gap, opening" },
  1819: {
    frequency: 1956,
    korean: "가령",
    english: "1) For example 2) supposing or admitting",
  },
  1820: {
    frequency: 1958,
    korean: "거대하다",
    english: "Huge or gigantic or enormous",
  },
  1821: { frequency: 1959, korean: "닫다", english: "To close" },
  1822: { frequency: 1960, korean: "드물다", english: "to be rare" },
  1823: { frequency: 1961, korean: "들르다", english: "(transitive) to hold" },
  1824: {
    frequency: 1962,
    korean: "매달리다",
    english:
      "Be suspended, to be hanging down (from) ; to stick to a job, to persevere",
  },
  1825: { frequency: 1963, korean: "생일", english: "A birthday" },
  1826: { frequency: 1964, korean: "섬", english: "An island" },
  1827: { frequency: 1965, korean: "이하", english: "Less than, below" },
  1828: {
    frequency: 1966,
    korean: "참석하다",
    english: "To attend, take part in",
  },
  1829: { frequency: 1967, korean: "토대", english: "A foundation, a base" },
  1830: { frequency: 1968, korean: "해결", english: "Solution, settlement" },
  1831: { frequency: 1969, korean: "행복", english: "Happiness" },
  1832: {
    frequency: 1970,
    korean: "걸어가다",
    english: "To go on foot or walk",
  },
  1833: { frequency: 1971, korean: "근로자", english: "Laborer" },
  1834: {
    frequency: 1972,
    korean: "글쎄",
    english: "Now or well or let me see",
  },
  1835: { frequency: 1973, korean: "목숨", english: "Life" },
  1836: { frequency: 1974, korean: "백화점", english: "Department store" },
  1837: { frequency: 1975, korean: "변화하다", english: "To change, vary" },
  1838: { frequency: 1976, korean: "병", english: "bottle; jar" },
  1839: {
    frequency: 1977,
    korean: "빠져나가다",
    english: "Get/go out of here",
  },
  1840: { frequency: 1978, korean: "안녕하다", english: "To say hello" },
  1841: {
    frequency: 1979,
    korean: "여론",
    english: "Public opinion, public sentiment towards sth",
  },
  1842: { frequency: 1980, korean: "의복", english: "Clothes, garments" },
  1843: { frequency: 1981, korean: "체조", english: "Gymnastics" },
  1844: { frequency: 1982, korean: "출발하다", english: "To depart, leave" },
  1845: {
    frequency: 1984,
    korean: "현실적",
    english: "Down to earth, Realistic",
  },
  1846: { frequency: 1985, korean: "화제", english: "Subject of conversation" },
  1847: { frequency: 1986, korean: "결정되다", english: "To become decided" },
  1848: { frequency: 1987, korean: "고양이", english: "Kitten" },
  1849: { frequency: 1988, korean: "공격", english: "Attack" },
  1850: { frequency: 1990, korean: "물가", english: "Prices of products" },
  1851: { frequency: 1991, korean: "민주주의", english: "Democracy" },
  1852: { frequency: 1992, korean: "불안", english: "uneasiness, anxiety" },
  1853: {
    frequency: 1993,
    korean: "소중하다",
    english: "To be important, valuable",
  },
  1854: { frequency: 1994, korean: "여유", english: "reserve, surplus" },
  1855: { frequency: 1995, korean: "의문", english: "A question, a doubt" },
  1856: { frequency: 1996, korean: "중학교", english: "Middle school" },
  1857: { frequency: 1997, korean: "킬로미터", english: "Kilometer" },
  1858: { frequency: 1998, korean: "파도", english: "Waves, billows" },
  1859: { frequency: 1999, korean: "흰색", english: "gray, ash color" },
  1860: { frequency: 2000, korean: "가수", english: "Singer" },
  1861: { frequency: 2001, korean: "단", english: "Only one, alone" },
  1862: { frequency: 2004, korean: "방송국", english: "Broadcasting station" },
  1863: { frequency: 2005, korean: "빛나다", english: "shine, glitter" },
  1864: {
    frequency: 2006,
    korean: "숨다",
    english: "To be in hiding, to hide oneself",
  },
  1865: {
    frequency: 2007,
    korean: "실리다",
    english: "To be printed,recorded; to be loaded onto a truck (luggage)",
  },
  1866: { frequency: 2008, korean: "압력", english: "pressure, stress" },
  1867: { frequency: 2009, korean: "예금", english: "Deposit money" },
  1868: {
    frequency: 2010,
    korean: "예상되다",
    english: "To expect, anticipate",
  },
  1869: { frequency: 2012, korean: "입학", english: "Admission into school" },
  1870: {
    frequency: 2014,
    korean: "증권",
    english: "securities, document,deed",
  },
  1871: { frequency: 2015, korean: "직후", english: "Immediately after" },
  1872: { frequency: 2016, korean: "차량", english: "vehicles, cars" },
  1873: { frequency: 2017, korean: "출산", english: "Child birth" },
  1874: { frequency: 2024, korean: "물다", english: "bite, put in the mouth" },
  1875: { frequency: 2025, korean: "선진국", english: "A developed country" },
  1876: { frequency: 2027, korean: "약", english: "drug; medicine" },
  1877: { frequency: 2028, korean: "어느새", english: "In no time, quickly" },
  1878: { frequency: 2030, korean: "재판", english: "A trial" },
  1879: {
    frequency: 2031,
    korean: "저쪽",
    english: "Over there, in that direction",
  },
  1880: { frequency: 2032, korean: "제자", english: "A disciple" },
  1881: { frequency: 2033, korean: "창문", english: "A window" },
  1882: { frequency: 2034, korean: "초", english: "unit of time, second" },
  1883: { frequency: 2035, korean: "치즈", english: "Cheese" },
  1884: { frequency: 2036, korean: "회복", english: "Recovery, recuperation" },
  1885: { frequency: 2037, korean: "구역", english: "A zone or district" },
  1886: {
    frequency: 2038,
    korean: "대응",
    english: "Confrontation, opposition",
  },
  1887: { frequency: 2039, korean: "반대하다", english: "Be opposed to" },
  1888: { frequency: 2040, korean: "발휘하다", english: "Display, exhibit" },
  1889: { frequency: 2041, korean: "소비", english: "Consumption" },
  1890: { frequency: 2042, korean: "심장", english: "The heart" },
  1891: { frequency: 2043, korean: "아이고", english: "Oh my god!" },
  1892: { frequency: 2047, korean: "조용히", english: "Quietly" },
  1893: {
    frequency: 2048,
    korean: "중소기업",
    english: "Small and medium enterprises",
  },
  1894: { frequency: 2049, korean: "직접적", english: "Directly, immediately" },
  1895: { frequency: 2050, korean: "진실", english: "Truth, sincerity" },
  1896: { frequency: 2051, korean: "필자", english: "The writer, author" },
  1897: {
    frequency: 2052,
    korean: "협력",
    english: "Cooperation, cooperate with",
  },
  1898: { frequency: 2053, korean: "가스", english: "Gas" },
  1899: {
    frequency: 2054,
    korean: "계층",
    english: "A class or social stratum",
  },
  1900: { frequency: 2055, korean: "구멍", english: "A hole" },
  1901: { frequency: 2057, korean: "담당", english: "Charge, undertaking" },
  1902: { frequency: 2059, korean: "만화", english: "Cartoons" },
  1903: { frequency: 2060, korean: "먹이다", english: "support, feed" },
  1904: {
    frequency: 2061,
    korean: "무시하다",
    english: "disregard, ignore ; to be defiant and fail to observe",
  },
  1905: { frequency: 2062, korean: "보도하다", english: "To report, inform" },
  1906: {
    frequency: 2063,
    korean: "살짝",
    english: "(1) softly, lightly, just a little (2) furtively, stealthily",
  },
  1907: { frequency: 2064, korean: "생각나다", english: "To remember" },
  1908: { frequency: 2065, korean: "우유", english: "Milk" },
  1909: { frequency: 2066, korean: "인상", english: "rise, raise, increase" },
  1910: { frequency: 2067, korean: "차갑다", english: "Cold" },
  1911: {
    frequency: 2068,
    korean: "철저하다",
    english: "thoroughness, cleanliness",
  },
  1912: {
    frequency: 2069,
    korean: "태아",
    english: "A fetus, an unborn child",
  },
  1913: {
    frequency: 2070,
    korean: "관찰하다",
    english: "Observe or view or watch",
  },
  1914: {
    frequency: 2071,
    korean: "괜히",
    english: "In vain or useless or fruitless, for a stupid reason",
  },
  1915: {
    frequency: 2072,
    korean: "끼다",
    english: "To put on or wear, to wear (a ring)",
  },
  1916: { frequency: 2073, korean: "날개", english: "The wings" },
  1917: { frequency: 2074, korean: "녀석", english: "Fellow" },
  1918: {
    frequency: 2075,
    korean: "눈빛",
    english: "The glitter of one’s eyes",
  },
  1919: {
    frequency: 2076,
    korean: "단지",
    english: "(apartment/housing/work) complex, development",
  },
  1920: {
    frequency: 2077,
    korean: "두르다",
    english: "to surround, to wrap, to wear",
  },
  1921: { frequency: 2078, korean: "드디어", english: "finally, at last" },
  1922: { frequency: 2079, korean: "물속", english: "Inside the water" },
  1923: { frequency: 2080, korean: "민주", english: "Democracy" },
  1924: { frequency: 2082, korean: "성공하다", english: "To succeed" },
  1925: { frequency: 2083, korean: "소나무", english: "A pine tree" },
  1926: { frequency: 2084, korean: "여기저기", english: "Here and there" },
  1927: { frequency: 2085, korean: "여인", english: "beauty, belle" },
  1928: { frequency: 2086, korean: "운영하다", english: "manage, administer" },
  1929: {
    frequency: 2089,
    korean: "평가하다",
    english: "To evaluate, estimate, judge",
  },
  1930: { frequency: 2090, korean: "표", english: "A marker, indication" },
  1931: { frequency: 2091, korean: "필요성", english: "Necessity" },
  1932: { frequency: 2092, korean: "감추다", english: "Hide or conceal" },
  1933: { frequency: 2094, korean: "머무르다", english: "Stay overnight" },
  1934: {
    frequency: 2095,
    korean: "모",
    english: "A certain person, mr. Jones",
  },
  1935: { frequency: 2096, korean: "부위", english: "A region, part" },
  1936: { frequency: 2097, korean: "비우다", english: "empty, vacate" },
  1937: {
    frequency: 2098,
    korean: "설치하다",
    english: "To found, establish, create",
  },
  1938: { frequency: 2099, korean: "십일월", english: "November" },
  1939: { frequency: 2100, korean: "싸다", english: "Wrap in, bundle" },
  1940: { frequency: 2101, korean: "아울러", english: "And, both together" },
  1941: { frequency: 2103, korean: "지시", english: "Directions, orders" },
  1942: {
    frequency: 2106,
    korean: "형성하다",
    english: "To form, make, take shape",
  },
  1943: { frequency: 2107, korean: "형제", english: "Brothers and sisters" },
  1944: {
    frequency: 2108,
    korean: "화려하다",
    english: "Splendor, magnificence of a display, a look",
  },
  1945: {
    frequency: 2109,
    korean: "거울",
    english: "1) mirror 2) a pattern or model or exemplar",
  },
  1946: { frequency: 2110, korean: "덧붙이다", english: "Add a thing, append" },
  1947: { frequency: 2111, korean: "딴", english: "another, other; different" },
  1948: { frequency: 2112, korean: "몇몇", english: "Several" },
  1949: { frequency: 2113, korean: "무기", english: "Weapon" },
  1950: { frequency: 2114, korean: "세계적", english: "International" },
  1951: { frequency: 2115, korean: "안전", english: "Safety" },
  1952: { frequency: 2116, korean: "양파", english: "An onion" },
  1953: {
    frequency: 2117,
    korean: "이상",
    english: "strange, abnormal, odd, weird, bizarre",
  },
  1954: {
    frequency: 2118,
    korean: "일종",
    english: "A kind, a sort, a species",
  },
  1955: {
    frequency: 2119,
    korean: "처지",
    english: "A situation,circumstances",
  },
  1956: {
    frequency: 2120,
    korean: "촬영",
    english: "Photographing, taking pictures/films",
  },
  1957: { frequency: 2121, korean: "타다", english: "(intransitive) to burn" },
  1958: { frequency: 2122, korean: "틀다", english: "To twist, wind" },
  1959: {
    frequency: 2123,
    korean: "형편",
    english: "The situation, the state of things (esp. financial)",
  },
  1960: { frequency: 2124, korean: "가지", english: "eggplant/aubergine" },
  1961: { frequency: 2125, korean: "감다", english: "Close" },
  1962: { frequency: 2126, korean: "고추", english: "Red pepper" },
  1963: { frequency: 2127, korean: "규칙", english: "Rules or regulations" },
  1964: { frequency: 2128, korean: "본질", english: "True nature,essence" },
  1965: { frequency: 2129, korean: "비치다", english: "Shine" },
  1966: { frequency: 2130, korean: "빵", english: "Bread" },
  1967: {
    frequency: 2131,
    korean: "서서히",
    english: "Slowly, little by little",
  },
  1968: { frequency: 2132, korean: "스승", english: "A teacher,master" },
  1969: { frequency: 2133, korean: "신분", english: "Social position" },
  1970: { frequency: 2134, korean: "실시되다", english: "To be enforced" },
  1971: { frequency: 2135, korean: "아마도", english: "Probably" },
  1972: { frequency: 2136, korean: "안방", english: "The inner room" },
  1973: {
    frequency: 2137,
    korean: "앓다",
    english: "To be ill, sick with sth",
  },
  1974: { frequency: 2138, korean: "어제", english: "Yesterday" },
  1975: { frequency: 2140, korean: "오직", english: "Only, merely, solely" },
  1976: { frequency: 2141, korean: "위험하다", english: "To be dangerous" },
  1977: { frequency: 2143, korean: "자신", english: "confidence" },
  1978: { frequency: 2144, korean: "잠시", english: "a moment, a short time" },
  1979: { frequency: 2145, korean: "졸업하다", english: "To graduate" },
  1980: { frequency: 2146, korean: "증거", english: "evidence,proof" },
  1981: { frequency: 2147, korean: "초점", english: "A focus, a focal point" },
  1982: { frequency: 2148, korean: "포함되다", english: "To be included in" },
  1983: { frequency: 2150, korean: "호랑이", english: "A tiger" },
  1984: { frequency: 2151, korean: "강화하다", english: "Strengthen" },
  1985: { frequency: 2152, korean: "공포", english: "Fear or dread" },
  1986: {
    frequency: 2153,
    korean: "권위",
    english: "1) Authority or power 2) dignity or prestige",
  },
  1987: { frequency: 2154, korean: "덜", english: "Less, incompletely" },
  1988: { frequency: 2155, korean: "둥글다", english: "to be round, circular" },
  1989: {
    frequency: 2157,
    korean: "미루다",
    english: "Postpone ; (2) to guess, infer and make an educated guess",
  },
  1990: { frequency: 2158, korean: "본래", english: "originally,primarily" },
  1991: { frequency: 2159, korean: "부엌", english: "Kitchen" },
  1992: { frequency: 2160, korean: "세금", english: "Tax" },
  1993: { frequency: 2162, korean: "실정", english: "The actual situation" },
  1994: { frequency: 2163, korean: "영양", english: "Nutrition" },
  1995: { frequency: 2164, korean: "육체", english: "The flesh, the body" },
  1996: { frequency: 2165, korean: "입구", english: "Entrance" },
  1997: {
    frequency: 2166,
    korean: "잔뜩",
    english: "extremely, to the utmost",
  },
  1998: { frequency: 2167, korean: "적극", english: "The positive" },
  1999: { frequency: 2168, korean: "최소한", english: "At least" },
  2000: {
    frequency: 2169,
    korean: "펼쳐지다",
    english: "Stretch, extend away",
  },
};

export default {
  state() {
    return {
      korean_list: korean_list,
    };
  },
  mutations,
  actions,
  getters,
};
